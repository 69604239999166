import ActionSet from './ActionSet'
import { SnackBarActions } from './'
import { TimesheetEntryAPI, TimesheetAPI } from '../api'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'

import { ProjectTaskAPI } from '../api'
import { RequestService } from '../services'

// timesheet CRUD api calls

export class TimesheetEntryActionSet extends ActionSet {

  static initialState = {
    timesheet: {attributes: {status: 'approved'}},
    timesheetEntries: [],
    requestingTimesheetEntries: false,
    form: {
      entries: [],
      submissionErrors: [],
      errors: [],
    },
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]


  static statusForTimesheet(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((userId, startDate, endDate) => {
      return dispatch =>
        dispatch({
          type: this.STATUS_FOR_TIMESHEET,
          api: TimesheetAPI.status(userId, startDate, endDate)
        })
    })

    reducer({
      [this.STATUS_FOR_TIMESHEET_SUCCESS] : (state, { result: { body: { data: timesheet } } } ) => {
        return {...state, timesheet}
      }
    })
  }

  static searchTimesheetEntries(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((query, order) => {
      return dispatch =>
        dispatch({
          type: this.SEARCH_TIMESHEET_ENTRIES,
          api: TimesheetEntryAPI.search({...query, order})
        })
    })

    reducer({
      [this.SEARCH_TIMESHEET_ENTRIES_REQUEST] : (state) => {
        return {...state, requestingTimesheetEntries: true}
      },
      [this.SEARCH_TIMESHEET_ENTRIES_SUCCESS] : (state, { result: { body: { data: timesheetEntries } } } ) => {
        return {...state, timesheetEntries, requestingTimesheetEntries: false}
      },
      [this.SEARCH_TIMESHEET_ENTRIES_FAILURE] : (state) => {
        return {...state, requestingTimesheetEntries: false}
      }
    })
  }

  static setPendingTimesheetEntriesLoad(creator, reducer, constants) {
    this.SET_LOAD_TIMESHEET_ENTRIES_PENDING = 'SET_LOAD_TIMESHEET_ENTRIES_PENDING'

    creator((pendingTimesheetEntriesLoad) => async dispatch => {
      dispatch({ type: this.SET_LOAD_TIMESHEET_ENTRIES_PENDING, payload: { pendingTimesheetEntriesLoad } })
    })

    reducer({
      [this.SET_LOAD_TIMESHEET_ENTRIES_PENDING] : (state, { pendingTimesheetEntriesLoad }) => {
        return {...state, pendingTimesheetEntriesLoad }
      }
    })
  }

  static loadRecentTimesheetEntries(creator, reducer, constants) {
    constants.defineRequestConstants()

    creator(() => async (dispatch, getState) => {
      const { timesheetEntry: { requestingRecentEntries } } = getState()
      if (!requestingRecentEntries) {
        dispatch({ type: this.LOAD_RECENT_TIMESHEET_ENTRIES_REQUEST })

        try {
          const { body: { data: projectTasks }} = await ProjectTaskAPI.autocomplete({ recent_tasks: true })(RequestService)
          dispatch({ type: this.LOAD_RECENT_TIMESHEET_ENTRIES_SUCCESS, payload: { recentTimesheetEntries: projectTasks } })
        } catch {
          dispatch({ type: this.LOAD_RECENT_TIMESHEET_ENTRIES_FAILURE })
        }
      }
    })
    reducer({
      [this.LOAD_RECENT_TIMESHEET_ENTRIES_REQUEST] : (state) => {
        return {...state, requestingRecentEntries: true}
      },
      [this.LOAD_RECENT_TIMESHEET_ENTRIES_SUCCESS] : (state, { recentTimesheetEntries }) => {
        return {...state, requestingRecentEntries: false, recentTimesheetEntries}
      },
      [this.LOAD_RECENT_TIMESHEET_ENTRIES_FAILURE] : (state) => {
        return {...state, requestingRecentEntries: false}
      },
    })
  }

  static createTimesheetEntries(creator, reducer) {
    creator((entries, showAlert=true) => async dispatch => {
      const createRequests = entries.map(this.createTimesheetEntry).map(dispatch)
      await Promise.all(createRequests)
      if (showAlert) {
        dispatch(SnackBarActions.alert('Timesheet saved'))
      }
    })
    reducer({})
  }

  static createTimesheetEntry(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({ attributes, index }) => async dispatch => {
      try {
        const { body: { data }} = await dispatch({
          type: this.CREATE_TIMESHEET_ENTRY,
          api: TimesheetEntryAPI.create(attributes)
        })
        if (typeof index !== undefined) {
          await dispatch(this.setEntryForm(data, index))
        } else {
          await dispatch(SnackBarActions.alert('Timesheet saved'))
        }
      }
      catch ({ body: { errors }}) {
        dispatch(this.setSubmissionErrorsForm(errors))
      }
    })

    reducer({
      [this.CREATE_TIMESHEET_ENTRY_SUCCESS]: (state, {result: {body: {data: entry}}}) => ({
        ...state,
        timesheetEntries: state.timesheetEntries.concat(entry)
      })
    })
  }

  static copyTimesheetEntries(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({fromDate,toDate,onComplete}) => async dispatch => {
      try {
        await dispatch({
          type: this.COPY_TIMESHEET_ENTRIES,
          api: TimesheetEntryAPI.copy({fromDate,toDate})
        })
        await dispatch(SnackBarActions.alert('Timesheet entries copied'))
        onComplete && onComplete()
      }
      catch (error) {
        console.log({ action: 'copyTimesheetEntries', error })
        await dispatch(SnackBarActions.alert('Copy failed'))
      }
    })

    reducer({
      [this.COPY_TIMESHEET_ENTRIES_SUCCESS]: (state, response) => ({
        ...state
      })
    })
  }

  static updateTimesheetEntries(creator, reducer) {
    creator((entries, showAlert=true) => async dispatch => {
      const updateRequests = entries.map(this.updateTimesheetEntry).map(dispatch)
      await Promise.all(updateRequests)
      if (showAlert) {
        dispatch(SnackBarActions.alert('Timesheet saved'))
      }
    })
    reducer({})
  }

  static updateTimesheetEntry(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({ id, attributes, index }) => async dispatch => {
      try {
        const { body: { data }} = await dispatch({
          type: this.UPDATE_TIMESHEET_ENTRY,
          api: TimesheetEntryAPI.update(id, attributes)
        })
        if (typeof index !== undefined) {
          await dispatch(this.setEntryForm(data, index))
        } else {
          await dispatch(SnackBarActions.alert('Timesheet saved'))
        }
      }
      catch ({ body: { errors }}) {
        dispatch(this.setSubmissionErrorsForm(errors))
      }
    })

    reducer({
      [this.UPDATE_TIMESHEET_ENTRY_SUCCESS]: (state, {result: {body: {data: model}}}) => {
        const timesheetEntries = state.timesheetEntries.slice()
        const index = timesheetEntries.findIndex(e => e.id === model.id)

        if (index > -1) {
          timesheetEntries.splice(index, 1, model)
        }

        return {
          ...state,
          timesheetEntries
        }
      }
    })
  }

  static deleteTimesheetEntry(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(id => ({
      type: this.DELETE_TIMESHEET_ENTRY,
      api: TimesheetEntryAPI.delete(id)
    }))

    reducer({
      [this.DELETE_TIMESHEET_ENTRY_SUCCESS] :  (state, {result: {body: {data: model}}}) => ({
        ...state,
        timesheetEntries: state.timesheetEntries.filter(e => e.id !== model.id)
      })
    })
  }

  static invoiceTimesheetEntries(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((ids, invoiceNo) => {
      return async dispatch => {
        await dispatch({
          type: this.INVOICE_TIMESHEET_ENTRIES,
          api: TimesheetEntryAPI.invoice({ids, invoiceNo})
        })
        await dispatch(SnackBarActions.alert('Invoice Number updated'))
      }
    })

    reducer({
      [this.INVOICE_TIMESHEET_ENTRIES_REQUEST] : (state) => {
        return {...state, requestingTimesheetEntries: true}
      },
      [this.INVOICE_TIMESHEET_ENTRIES_SUCCESS] : (state, { result: { body: { data: timesheetEntries } } } ) => {
        let entries = [...state.timesheetEntries]
        timesheetEntries.forEach(a => {
          const index = entries.findIndex(b => a.id+'' === b.id+'')
          if (index > -1) {
            entries[index] = a
          } else {
            entries.push(a)
          }
        })

        return {...state, timesheetEntries: entries, requestingTimesheetEntries: false}
      },
      [this.INVOICE_TIMESHEET_ENTRIES_FAILURE] : (state) => {
        return {...state, requestingTimesheetEntries: false}
      }
    })
  }

  // timesheet entry form

  static setForm(creator, reducer){

    creator(entries => ({
      type: this.SET_FORM,
      payload: entries
    }))

    reducer({
      [this.SET_FORM]:  (state, entries) => ({
        ...state,
        form: {
          errors: [],
          submissionErrors: [],
          entries
        }
      })
    })
  }

  static changeForm(creator, reducer){

    creator((fieldName, index, value) => {
      return {
        type: this.CHANGE_FORM,
        payload: {fieldName, index, value},
      }
    })

    reducer({
      [this.CHANGE_FORM] :  (state, {fieldName, index, value}) => {
        let entries = [...state.form.entries]
        entries[index] = {...entries[index], attributes: {...entries[index].attributes, [fieldName]: value }}
        // clear errors for field
        let errors = [...state.form.errors]
        if (errors[index]) {
          errors[index] = {...errors[index], [fieldName]: undefined}
        }

        return {...state, form: {...state.form, entries, errors}}
      }
    })
  }

  static deleteEntryForm(creator, reducer){
    creator(index => ({
      type: this.DELETE_ENTRY_FORM,
      payload: index,
    }))

    reducer({
      [this.DELETE_ENTRY_FORM]: (state, index) => ({
        ...state,
        form: {
          ...state.form,
          entries: state.form.entries.filter((e, i) => i !== index)
        }
      })
    })
  }

  static addEntryForm(creator, reducer){

    creator(entry => {
      return {
        type: this.ADD_ENTRY_FORM,
        payload: entry,
      }
    })

    reducer({
      [this.ADD_ENTRY_FORM] :  (state, entry) => {
        return {...state, form: {...state.form, entries: [...state.form.entries, entry]}}
      }
    })
  }

  static setEntryForm(creator, reducer){

    creator((entry, index) => {
      return {
        type: this.SET_ENTRY_FORM,
        payload: {entry, index},
      }
    })

    reducer({
      [this.SET_ENTRY_FORM] :  (state, {entry, index}) => {
        let entries = [...state.form.entries]

        entries[index] = entry
        return {...state, form: {...state.form, entries}}
      }
    })
  }

  static setErrorsForm(creator, reducer){

    creator(errors => {
      return {
        type: this.SET_ERRORS_FORM,
        payload: errors,
      }
    })

    reducer({
      [this.SET_ERRORS_FORM] :  (state, errors) => {
        return {...state, form: {...state.form, errors}}
      }
    })
  }


  static setSubmissionErrorsForm(creator, reducer){

    creator(submissionErrors => {
      return {
        type: this.SET_SUBMISSION_ERRORS_FORM,
        payload: submissionErrors,
      }
    })

    reducer({
      [this.SET_SUBMISSION_ERRORS_FORM] :  (state, submissionErrors) => {
        return {...state, form: {...state.form, submissionErrors}}
      }
    })
  }

}

export default new TimesheetEntryActionSet()
