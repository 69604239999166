import ActionSet from './ActionSet'
import { CustomerAPI } from '../api'
import { SnackBarActions } from '.'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'

export class CustomerActionSet extends ActionSet{

  static initialState = {
    customers: [],
    requestingCustomers: false
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static loadCustomers(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => {
      return dispatch =>
        dispatch({
          type: this.LOAD_CUSTOMERS,
          api: CustomerAPI.index()
        })
    })

    reducer({
      [this.LOAD_CUSTOMERS_REQUEST]: (state) => {
        return {...state, requestingCustomers: true}
      },
      [this.LOAD_CUSTOMERS_FAILURE]: (state) => {
        return {...state, requestingCustomers: false}
      },
      [this.LOAD_CUSTOMERS_SUCCESS]: (state, { result: { body: { data: customers } } } ) => {
        return {...state, customers, requestingCustomers: false }
      },
    })
  }

  static createCustomer(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(attributes => {
      return async dispatch => { 
        await dispatch({
          type: this.CREATE_CUSTOMER,
          api: CustomerAPI.create(attributes)
        })
        await dispatch(SnackBarActions.alert('Customer created')) 
      }
    })

    reducer({
      [this.CREATE_CUSTOMER]: (state) => {
        return state
      }
    })
  }

  static updateCustomer(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((id, attributes) => {
      return async dispatch => { 
        await dispatch({
          type: this.UPDATE_CUSTOMER,
          api: CustomerAPI.update(id, attributes)
        })
        await dispatch(SnackBarActions.alert('Customer updated')) 
      }
    })

    reducer({
      [this.UPDATE_CUSTOMER]: (state) => {
        return state
      }
    })
  }

  static deleteCustomer(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((id) => {
      return async dispatch => {
        try {
          await dispatch({
            type: this.DELETE_CUSTOMER,
            api: CustomerAPI.delete(id)
          })
          await dispatch(this.loadCustomers())
        }catch(err){
          const { body: { errors } } = err
          dispatch(SnackBarActions.alert(errors.map(({detail}) => detail).join(', ')))
        }
      }
    })

    reducer({
      [this.DELETE_CUSTOMER] : (state) => {
        return state
      }
    })
  }

  static searchCustomers(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((query, order) => {
      return {
        type: this.SEARCH_CUSTOMERS,
        api: CustomerAPI.search({...query, order})
      }
    })

    reducer({
      [this.SEARCH_CUSTOMERS_REQUEST]: (state) => {
        return {...state, requestingCustomers: true}
      },
      [this.SEARCH_CUSTOMERS_FAILURE]: (state) => {
        return {...state, requestingCustomers: false}
      },
      [this.SEARCH_CUSTOMERS_SUCCESS]: (state, {result: {body: {data: customers}}}) => {
        return {...state, customers, requestingCustomers: false}
      }
    })
  }

}

export default new CustomerActionSet()