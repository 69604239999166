import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Timesheets } from '.'
import { UserRestrictedView } from '../'
import { connect } from "react-redux"

export class TimesheetsWrapper extends Component{

  static propTypes = {
    user: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
  }

  get loaded(){
    return this.props.user.id
  }

  render = () =>
    <div>
      { this.loaded && <UserRestrictedView component={Timesheets} {...this.props}/>}
    </div>
}

export default connect(state => { return { ...state.session} })(TimesheetsWrapper)