import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import { DragSource } from 'react-dnd';
import DragHandle from 'material-ui/svg-icons/editor/drag-handle';

export class CardListItem extends Component{

  static propTypes = {
    contents: PropTypes.string,
    connectDragSource: PropTypes.func,
    draggable: PropTypes.bool,
    item: PropTypes.object
  }


  static collect = (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    }
  }

  connectDragSource = (dragSource) => {
    return this.props.draggable ?
      this.props.connectDragSource(dragSource) :
      dragSource
  }

  render = () =>
    this.connectDragSource(
      <div style={{display: 'flex'}}>
        {
          this.props.draggable ?
            <span style={{display: 'inline-block', margin: '-3px 10px 10px 10px'}} onClick={this.startDrag} title='Drag to reorder'>
              <DragHandle style={{color: 'silver', cursor: 'move'}}/>
            </span> : false
        }
        <span style={{flexGrow: '1', flexShrink: '1'}} dangerouslySetInnerHTML={{__html: this.props.contents }}/>
        <span style={{marginTop: 'auto', marginBottom: 'auto'}}>
          <ChevronRight/>
        </span>
      </div>
    )
}

export default DragSource('CardListItem', { beginDrag(props){ return props } }, CardListItem.collect)(CardListItem);
