import ActionSet from './ActionSet'
import { ProjectAPI, ServiceAPI } from '../api'
import { SnackBarActions } from '.'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'

export class ProjectActionSet extends ActionSet{

  static initialState = {
    projects: [],
    project: null,
    external: {},
    requestingProjects: false,
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static loadProjects(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => {
      return dispatch =>
        dispatch({
          type: this.LOAD_PROJECTS,
          api: ProjectAPI.index()
        })
    })

    reducer({
      [this.LOAD_PROJECTS_REQUEST]: (state) => {
        return {...state, requestingProjects: true}
      },
      [this.LOAD_PROJECTS_FAILURE]: (state) => {
        return {...state, requestingProjects: false}
      },
      [this.LOAD_PROJECTS_SUCCESS]: (state, { result: { body: { data: projects } } } ) => {
        return {...state, projects, requestingProjects: false }
      },
    })
  }

  static loadProject(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(id => {
      return dispatch =>
        dispatch({
          type: this.LOAD_PROJECT,
          api: ProjectAPI.show(id)
        })
    })

    reducer({
      [this.LOAD_PROJECT_REQUEST]: (state) => {
        return {...state, requestingProjects: true}
      },
      [this.LOAD_PROJECT_FAILURE]: (state) => {
        return {...state, requestingProjects: false}
      },
      [this.LOAD_PROJECT_SUCCESS]: (state, { result: { body: { data: project } } } ) => {
        return {...state, project, requestingProjects: false }
      },
    })
  }

  static createProject(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(attributes => {
      return async dispatch => {
        await dispatch({
          type: this.CREATE_PROJECT,
          api: ProjectAPI.create(attributes)
        })
        await dispatch(SnackBarActions.alert('Project created'))
      }
    })

    reducer({
      [this.CREATE_PROJECT]: (state) => {
        return state
      }
    })
  }

  static updateProject(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((id, attributes) => {
      return async dispatch => {
        await dispatch({
          type: this.UPDATE_PROJECT,
          api: ProjectAPI.update(id, attributes)
        })
        await dispatch(SnackBarActions.alert('Project updated'))
      }
    })

    reducer({
      [this.UPDATE_PROJECT]: (state) => {
        return state
      }
    })
  }

  static searchProjects(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(query => {
      return dispatch =>
        dispatch({
          type: this.SEARCH_PROJECTS,
          api: ProjectAPI.search(query)
        })
    })

    reducer({
      [this.SEARCH_PROJECTS_REQUEST]: (state) => {
        return {...state, requestingProjects: true}
      },
      [this.SEARCH_PROJECTS_FAILURE]: (state) => {
        return {...state, requestingProjects: false}
      },
      [this.SEARCH_PROJECTS_SUCCESS]: (state, { result: { body: { data: projects } } } ) => {
        return {...state, projects, requestingProjects: false }
      },
    })
  }

  static loadExternalProjects(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(services => ({
      type: this.LOAD_EXTERNAL_PROJECTS,
      api: ServiceAPI.projects(...services)
    }))

    reducer({
      [this.LOAD_EXTERNAL_PROJECTS_REQUEST]: (state) => ({
        ...state,
        requestingProjects: true
      }),
      [this.LOAD_EXTERNAL_PROJECTS_FAILURE]: (state) => ({
        ...state,
        requestingProjects: false
      }),
      [this.LOAD_EXTERNAL_PROJECTS_SUCCESS]: (state, { result: { body: { projects: externalServices }}}) => ({
        ...state,
        requestingProjects: false,
        external: Object.keys(externalServices).reduce((accum, serviceName) => ({
          ...accum,
          [serviceName]: {
            projects: externalServices[serviceName]
          }
        }), {}),
      }),
    })
  }
}

export default new ProjectActionSet()
