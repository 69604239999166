import React, { Component } from 'react'
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { primary1Color, primary2Color, accent1Color } from '../styles/theme'
import { connect } from 'react-redux'


export class VitalstatistixThemeProvider extends Component{

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    fontFamily: PropTypes.string,
    primaryColor: PropTypes.string
  }

  get muiTheme(){
    return getMuiTheme({
      fontFamily: this.props.fontFamily || 'Roboto, sans-serif',
      palette: {
        primary1Color: this.props.primaryColor || primary1Color,
        primary2Color: primary2Color,
        accent1Color: accent1Color,

      },
      appBar: {
        height: 50,
      },
    })
  }


  render = () =>
    <MuiThemeProvider muiTheme={this.muiTheme}>
      {this.props.children}
    </MuiThemeProvider>

}

class ThemedComponent extends Component{

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    component: PropTypes.func,
    fontFamily: PropTypes.string,
    primaryColor: PropTypes.string,
    user: PropTypes.object
  }

  render = () => {
    const { component: Component, user, children, ...props } = this.props
    const { fontFamily, primaryColor } = user.attributes
    return (
      <Component fontFamily={fontFamily} primaryColor={primaryColor} {...props}>
        {children}
      </Component>
    )
  }
}

const ConnectedThemedComponent = connect(({session : { user }}) => {
  return {user}
})(ThemedComponent)

export const Themed = component => {
  const WrappedThemed = props => <ConnectedThemedComponent component={component} {...props}>{props.children}</ConnectedThemedComponent>
  WrappedThemed.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  }
  return WrappedThemed
}

export default connect(({session: { user: { attributes } }}) => attributes)(VitalstatistixThemeProvider)