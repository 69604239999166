import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import MenuItem from 'material-ui/MenuItem'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'


export class MenuLink extends Component{

  static propTypes = {
    onMenuClick: PropTypes.func,
    name: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
  }

  renderIcon = () => this.props.icon && <FontAwesome title='icon' name={this.props.icon} fixedWidth style={{paddingTop: 4}} />

  render = () =>
    <MenuItem
      containerElement={<Link to={this.props.link} />}
      onClick={this.props.onMenuClick}
      leftIcon={this.renderIcon()}
      disabled={this.props.disabled}
    >
      {this.props.name}
    </MenuItem>

}

export default connect(state => state)(MenuLink)
