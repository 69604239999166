import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import { HorizontalCardLayout } from '../../components/cardLayout'

export class Home extends Component{

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
  }

  render = () =>
    <HorizontalCardLayout>
      { this.props.children }
    </HorizontalCardLayout>
}

export default Home