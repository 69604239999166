import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { dateKey } from '../../../utils'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { TimesheetCalendarToolbar, TimesheetEntryForm } from '.'
import { TimesheetStatus } from '../../../constants'
import { TimesheetEntryActions } from '../../../actionsets'

export class TimesheetCalendarView extends Component {

  static propTypes = {
    status: PropTypes.string.isRequired,
    timesheetEntries: PropTypes.object.isRequired,
    dates: PropTypes.array.isRequired,
    selectedDate: PropTypes.object.isRequired,
    onDateChange: PropTypes.func.isRequired,
    requestingTimesheetEntries: PropTypes.bool.isRequired,
    highlightedDate: PropTypes.object
  }

  constructor(props){
    super(props)
    TimesheetEntryActions.bindActions(this)
  }

  totalTimeFor = entries => Object.keys(entries).reduce((time, key) => time + entries[key].reduce((time, entry) => time + entry.attributes.time, 0), 0)

  copyTimesheetEntries = async (fromDate, toDate) => {
    this.actions.copyTimesheetEntries({fromDate, toDate, onComplete: () => {
      this.props.onDateChange(moment(toDate, 'YYYY-MM-DD'), { reloadRequired: true })
    }})
  }

  render = () => {

    const { dates, missingTimeByDate, selectedDate, highlightedDate, timesheetEntries, onDateChange, requestingTimesheetEntries, status } = this.props
    const entries = timesheetEntries[dateKey(selectedDate)] || []

    return (

      <div>
        <TimesheetCalendarToolbar
          dates={ dates }
          missingTimeByDate={ missingTimeByDate }
          selectedDate={ selectedDate }
          highlightedDate={ highlightedDate }
          onChange={ onDateChange }
          navLeftBy={ {offset: 3, factor: 'days'} }
          navRightBy={ {offset: 1, factor: 'days'} }
          copyTimesheetEntries={this.copyTimesheetEntries}
        />

        <div style={{marginTop: 20, marginBottom: 20}}>
          <TimesheetEntryForm
            disabled={ status !== TimesheetStatus.unapproved }
            timesheetEntries={ entries }
            selectedDate={ selectedDate }
            requestingTimesheetEntries={ requestingTimesheetEntries }
            weekTime={ this.totalTimeFor(_.omit(timesheetEntries, [dateKey(selectedDate)])) }
          />
        </div>
      </div>

    )
  }

}

export const mapStateToProps = ({ session, timesheetEntry: { requestingTimesheetEntries: loading = false } = {} }, props) => {
  let entriesByDate = {}
  props.timesheetEntries.forEach(entry => {
    let key = dateKey(moment(entry.attributes.date))
    if (!entriesByDate[key]) entriesByDate[key] = []
    entriesByDate[key].push(entry)
  })

  const workDays = session?.user?.attributes?.workDays || 62
  const minimumTime = (session?.user?.attributes?.dailyHours || 8) - 1
  let missingTimeByDate = loading ? {} : props.dates.reduce(((memo, date) => {
    const key = dateKey(moment(date))
    const time = entriesByDate[key]?.map(x => x?.attributes?.time || 0).reduce((a,b) => (a+b), 0) || 0
    const isPast = moment(date) < moment().startOf('day')
    const isWorkDay = (workDays & Math.pow(2,date.day())) > 0 ? true : false
    const missingTime = (isWorkDay && isPast) ? time < minimumTime : false
    return { ...memo, [key]: missingTime }
  }), {})

  return {...props, timesheetEntries: entriesByDate, missingTimeByDate}
}

export default connect(mapStateToProps)(TimesheetCalendarView)
