class UserAPI{
  static create = attributes => authenticated => authenticated.post('api/users', {
    data: {
      type: 'users',
      attributes
    }
  })

  static update = (id, attributes) => authenticated => authenticated.put(`api/users/${id}`, {
    data: {
      type: 'users',
      attributes
    }
  })

  static search = (attributes) => authenticated => authenticated.post('api/users/search', {
    data: {
      type: 'users',
      attributes
    }
  })

  static index = () => authenticated => authenticated.get('api/users')
  static show = id => authenticated => authenticated.get(`api/users/${id}`)

  static resendInvitation = id => authenticated => authenticated.post(`api/users/${id}/resend_invitation`)
}

export default UserAPI
