import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import { Card } from 'material-ui/Card'
import Responsive from '../Responsive'
import { Themed } from '../../containers/VitalstatistixThemeProvider'
import { AccordionTab } from './'

const styles = {
  mobile: {
    wrapperStyle: {
      background: '#fafafa',
      borderRadius: 15,
      overflow: 'hidden',
      boxShadow: '0px 10px 44px -2px rgba(0,0,0,0.40)',
      margin: '5px 10px',
      flex: '0 0 calc(100vw - 20px)'
    },
  },
  desktop: {
    wrapperStyle: {
      display: 'flex',           /* establish flex container */
      justifyContent: 'center',  /* center items vertically, in this case */
      alignItems: 'flex-start',  /* center items horizontally, in this case */
      flexWrap: 'wrap',
      background: '#fafafa',
      marginLeft: '10px',
      marginRight: '10px',
      height: '100%',
      maxWidth: '350px',
      minWidth: '350px',
      borderRadius: 15,
      overflow: 'hidden',
      boxShadow: '0px 10px 44px -2px rgba(0,0,0,0.40)',
      flex: 1
    }
  },
  containerStyle: {
    display: 'flex',               /* establish flex container */
    flexDirection: 'column',       /* make main axis vertical */
    alignContent: 'space-between',
    background: '#fafafa',
    height: '100%',
    width: '100%',
  }
}

export class AccordionCard extends Component {

  static propTypes = {
    tabs: PropTypes.array,
    dimensions: PropTypes.object,
    activeSection: PropTypes.number,
    onTabClick: PropTypes.func
  }

  static defaultProps = {
    dimensions: {},
    tabs: [],
    onTabClick: () => {}
  }

  get wrapperStyle(){
    if (this.props.dimensions.narrow)
      return styles.mobile.wrapperStyle
    else
      return styles.desktop.wrapperStyle
  }

  renderTab = (item, index) =>{
    return <AccordionTab key={index} data={item} active={this.props.activeSection === index} onTabClick={() => this.props.onTabClick(item, index)}/>
  }

  render = () =>
    <Card className='general-card' style={{...this.wrapperStyle, ...this.props.wrapperStyle}} containerStyle={styles.containerStyle}>
      { this.props.tabs.map(this.renderTab) }
    </Card>
}

export default Themed(Responsive(AccordionCard))