import React, {  } from 'react'
import PropTypes from 'prop-types';
import { ListItem } from 'material-ui/List'
import Avatar from 'material-ui/Avatar'
import LinkIcon from './LinkIcon'

export default function MenuItem ({ title, source, logoSrc, url, onSelect }) {
  const link = url ? LinkIcon({ source, url }) : null
  const logo = logoSrc ? <Avatar src={logoSrc} /> : null

  return (
    <ListItem
      primaryText={title}
      leftAvatar={logo}
      onClick={onSelect}
      rightIconButton={link}
    />
  )
}

MenuItem.propTypes = {
  logoSrc: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
}
