import React, { Component } from 'react'
import PropTypes from 'prop-types';

export class Pagination extends Component{

  static propTypes = {
    totalPages:     PropTypes.number.isRequired,
    page:           PropTypes.number.isRequired,
    onPageSelected: PropTypes.func.isRequired,
    style:          PropTypes.object.isRequired,
    linkStyle:      PropTypes.object.isRequired,
    showNext:       PropTypes.bool,
    showPrev:       PropTypes.bool,
    showFirst:      PropTypes.bool,
    showLast:       PropTypes.bool,
    lookAround:     PropTypes.number,
  }

  static defaultProps = {
    showNext:   true,
    showPrev:   true,
    showFirst:  true,
    showLast:   true,
    lookAround: 2,
    style: {padding: '5px 15px'},
    linkStyle: {
      display: 'block',
      float: 'left',
      margin: 5,
      padding: 5,
      cursor: 'pointer'
    }
  }

  createButton = (text, index, disabled) => (
    disabled ?
      <span key={`${text}-${index}`} style={{...this.props.linkStyle, borderRadius: 3, padding: '5px 10px', background: '#fcfcfc', border: '1px solid silver'}}>{text} </span> :
      <span key={`${text}-${index}`} style={this.props.linkStyle} onClick={() => this.props.onPageSelected(index)}>{text}</span>
    )

  renderButtons = () => {
    if(this.props.totalPages <= 1) return false
    const buttons = []

    if(this.props.showFirst && this.props.totalPages > 1) buttons.push(this.createButton('First', 1))
    if(this.props.showPrev && this.props.page > 1)        buttons.push(this.createButton('<', this.props.page - 1))

    for(let index = this.props.page - this.props.lookAround; index <= this.props.page + this.props.lookAround; index++){
      if(index < 1 || index > this.props.totalPages) continue
      if(index === this.props.page){
        buttons.push(this.createButton(index, index, true))
      }else{
        buttons.push(this.createButton(index, index))
      }
    }
    if(this.props.showNext && this.props.page < this.props.totalPages) buttons.push(this.createButton('>', this.props.page + 1))
    if(this.props.showFirst && this.props.totalPages > 1)              buttons.push(this.createButton('Last', this.props.totalPages))

    return buttons
  }

  render = () =>
    <div className='pagination' style={{...Pagination.defaultProps.style, ...this.props.style}}>
      {this.renderButtons()} <br style={{clear: 'both'}}/>
    </div>
}

export default Pagination