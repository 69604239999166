import React from 'react'
import { Route as BasicRoute } from 'react-router-dom'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import {roleCheck} from "../utils";

class Route extends React.Component {
  state = {
    shouldRender: false
  }

  componentDidUpdate = () => {
    if(!this.state.shouldRender){
      this.testPermissions()
    }
  }

  testPermissions(){
    if (!this.requiredRole) {
      this.setState({shouldRender: true})
      return
    }

    if(!this.userLoaded){
      return // Do nothing until we know which user we are dealing with
    }
    else if(this.authorized) {
      if(this.mounted) this.setState({shouldRender: true})
    }
    else{
      this.props.dispatch(push('/'))
    }
  }

  componentDidMount = () => {
    this.mounted = true
    this.testPermissions()
  }

  componentWillUnmount = () => {
    this.mounted = false
  }

  get authorized(){
    return !this.requiredRole || roleCheck(this.role, this.requiredRole)
  }

  get requiredRole(){
    return this.props.restrictRole
  }

  get role(){
    return this.props.user.attributes.role
  }

  get userLoaded(){
    return this.props.user.id
  }

  render = () => {
    if (!this.state.shouldRender) {
      return false
    }
    return <BasicRoute {...this.props}/>
  }
}


export default connect(({session}) => session)(Route)