import React from 'react'
import { Provider, ReactReduxContext } from 'react-redux'
import store from '../store'
import { sendDevObjectsToTopLevel } from './DevTools'
import { AppSnackBar } from '../components'
import { VitalstatistixThemeProvider } from './VitalstatistixThemeProvider'
import { ConnectedRouter } from 'connected-react-router'
import { history } from "../utils"
import App from "./App";
import {AuthenticationWrapper} from "../containers";

const isProduction = () => process.env.NODE_ENV === 'production'

export default function AppContainer(){

  if( !isProduction()){
    sendDevObjectsToTopLevel()
  }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <VitalstatistixThemeProvider>
          <div id='main'>
            <AppSnackBar />
            <AuthenticationWrapper>
              <App />
            </AuthenticationWrapper>
          </div>
        </VitalstatistixThemeProvider>
      </ConnectedRouter>
    </Provider>
  )
}
