import React, {  } from 'react'
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField'
import { connect } from 'react-redux'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { push } from 'connected-react-router'
import Checkbox from 'material-ui/Checkbox'
import DatePicker from 'material-ui/DatePicker'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import CRUDForm from '../../containers/CRUDForm'
import { ProjectTaskActions } from '../../actionsets'
import { ModelStatus } from '../../constants'
import { roleCheck } from '../../utils'
import { ProjectTaskAPI } from '../../api'
import { RequestService } from '../../services'


export class ProjectTaskForm extends CRUDForm{

  static propTypes = {
    dispatch: PropTypes.func,
    onSubmitted: PropTypes.func,
    onCancel: PropTypes.func,
    projectId: PropTypes.any.isRequired,
  }

  static styles = {
    fields: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column',
    },
    leftField: {
      flex: 1,
      marginRight: 10,
    },
    rightField: {
      flex: 1,
      marginLeft: 10,
    },
    actions: {
      float: 'right'
    },
  }

  static defaultProps = {
    projects: []
  }

  constructor(props){
    super(props)
    this.state = {
      errors: {},
      submissionErrors: [],
      projectId: this.props.projectId || '',
      name: '',
      code: '',
      source: '',
      description: '',
      url: '',
      billable: false,
      startDate: null,
      endDate: null,
      projectStatus: '',
      customerStatus: '',
      status: ModelStatus.default,
    }
    ProjectTaskActions.bindActions(this)
  }

  loadCreateItem = () => {
    if(this.createMode && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
    }
  }

  loadEditItem = async () => {
    if(this.userId !== this.editId && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
      return
    }
    try{

      const { body: { data: { attributes: { name, code, source, url, description, projectId, billable, startDate, endDate, status, projectStatus, customerStatus }}}} = await ProjectTaskAPI.show(this.editId)(RequestService)
      this.setState({
        name, code, source, url, description, projectId:  String(projectId), billable, startDate, endDate, status, projectStatus, customerStatus
      })
    }catch(err){
      this.setState({ submissionErrors: [{ detail: `Couldn't find project task with id ${this.editId}`, source: { pointer:'' }}] })
    }
  }

  submit = async () => {
    if(!this.validate())
      return

    const { name, code, source, url, description, projectId, billable, startDate, endDate, status } = {...this.state }
    const params = { name, code, source, url, description, projectId, billable, startDate, endDate, status }

    let action
    if(this.editMode){
      action = this.actions.updateProjectTask(this.editId, params)
    }else{
      action = this.actions.createProjectTask(params)
    }

    try{
      await action
      this.props.onSubmitted && this.props.onSubmitted()
    } catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['name', 'projectId', 'startDate', 'status']
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  get editId(){
    return this.props.editId
  }

  get userId(){
    return this.props.user.id
  }

  get derivedStatusError() {
    if (this.state.projectStatus === ModelStatus.archived) return 'The project has been archived'
    if (this.state.customerStatus === ModelStatus.archived) return 'The project customer has been archived'
    return ''
  }

  render = () =>
    <div>
      <div style={ProjectTaskForm.styles.fields}>
        {this.renderSubmissionErrors()}

        <div style={{display: 'flex'}}>
          <TextField
            style={ProjectTaskForm.styles.leftField}
            floatingLabelText="Name"
            hintText="Name"
            value={this.state.name}
            onChange={this.onFormValueChange('name', this.nthValue(1))}
            errorText={this.state.errors.name}
          />

          <TextField
            style={ProjectTaskForm.styles.rightField}
            floatingLabelText="Description"
            hintText="Description"
            value={this.state.description}
            onChange={this.onFormValueChange('description', this.nthValue(1))}
            errorText={this.state.errors.description}
          />
        </div>

        <div style={{display: 'flex'}}>
          <DatePicker
            style={ProjectTaskForm.styles.leftField}
            floatingLabelText="Start Date"
            hintText="Start Date"
            value={this.state.startDate && new Date(this.state.startDate)}
            errorText={this.state.errors.startDate}
            onChange={this.onFormDateChange('startDate', this.nthValue(1))}
            autoOk
          />

          <DatePicker
            style={ProjectTaskForm.styles.rightField}
            floatingLabelText="End Date"
            hintText="End Date"
            value={this.state.endDate && new Date(this.state.endDate)}
            errorText={this.state.errors.endDate}
            onChange={this.onFormDateChange('endDate', this.nthValue(1))}
            autoOk
          />
        </div>

        <div style={{display: 'flex'}}>
          <TextField
            style={ProjectTaskForm.styles.leftField}
            floatingLabelText="URL"
            hintText="URL"
            value={this.state.url}
            onChange={this.onFormValueChange('url', this.nthValue(1))}
            errorText={this.state.errors.url}
          />

          <SelectField
            style={ProjectTaskForm.styles.rightField}
            floatingLabelText="Status"
            value={this.state.status}
            onChange={this.onFormValueChange('status', this.nthValue(2))}
            errorText={this.state.errors.status || this.derivedStatusError}
            disabled={!!this.derivedStatusError}
          >
            <MenuItem value='active' primaryText='Active' />
            <MenuItem value='archived' primaryText='Archived' />
          </SelectField>
        </div>
        <br/>
        <Checkbox
          label="Billable"
          checked={this.state.billable || false}
          onCheck={() => { this.setState({billable: !this.state.billable})}}
        />
      </div>
      <div style={ProjectTaskForm.styles.actions}>
        <FlatButton label="Cancel" style={{marginRight: 10}} onClick={this.props.onCancel} />
        <RaisedButton label='Save' primary={true} onClick={this.submit} />
      </div>
    </div>
}

export default connect(({session}) => session)(ProjectTaskForm)
