class ProjectTaskAPI{

  static create = attributes => authenticated => authenticated.post('api/project_tasks', {
    data: {
      type: 'project_tasks',
      attributes
    }
  })

  static show = id => authenticated => authenticated.get(`api/project_tasks/${id}`)

  static update = (id, attributes) => authenticated => authenticated.put(`api/project_tasks/${id}`, {
    data: {
      type: 'project_tasks',
      attributes
    }
  })

  static index = () => authenticated => authenticated.get('api/project_tasks')

  static search = (attributes) => authenticated => authenticated.post('api/project_tasks/search', {
    data: {
      type: 'project_tasks',
      attributes
    }
  })

  static autocomplete = attributes => authenticated => authenticated.post('api/project_tasks/autocomplete', {
    data: {
      type: 'project_tasks',
      attributes
    }
  })

}

export default ProjectTaskAPI
