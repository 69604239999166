import _ from 'lodash'
import moment from 'moment'
import {createMemoryHistory, createBrowserHistory} from 'history'

export function parseAttributeErrorsArray(errors){
  const errorMap = {}
  errors.forEach(({detail, source: { pointer }}) => {
    const attributes = pointer.split(/\//)
    const attribute  = attributes[attributes.length - 1]
    errorMap[titleize(attribute)] = errorMap[titleize(attribute)] || []
    errorMap[titleize(attribute)].push(detail)
  })
  return errorMap
}

export function generateAttributesErrorsString(errors){
  return Object.entries(parseAttributeErrorsArray(errors)).map(([key, values]) => `${key}: ${values.join(', ')}`).join('. ')
}

export function createConstants(...constants) {
  return constants.reduce((acc, constant) => {
    acc[constant] = constant;
    return acc;
  }, {});
}

export function createActionNameList(namespace){
  const actionTypes = ['', 'REQUEST', 'SUCCESS', 'FAILURE'];
  return (...constants) => {
    return constants.reduce((acc, constant) => {
      const constantText = `${namespace}.${constant}`
      actionTypes.forEach((type) => {
        acc[[constant, type].filter(x => x).join('_').toUpperCase()] = [constantText, type].filter(x => x).join('.')
      })
      return acc;
    }, {});
  }
}

export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];
    return reducer ? reducer(state, action.payload): state;
  };
}

export function compact(array){
  return array.filter(x => x)
}

export function flatten(nested){
  return nested.reduce(function(prev, inner) {
    inner = Array.isArray(inner) ? inner : [inner]
    const  multiLevel = (inner).some(Array.isArray);
    return prev.concat(multiLevel ? flatten(inner) : inner);
  },[]);
}

export function autobind(object, context){
  context = context || object
  Object.keys(object).forEach(function(key) {
    let service = object[key];
    if (typeof(service) === 'function'){
      context[key] = service.bind(context)
    }
  });
  return context;
}

export function titleize(string){
  return string.split(/(?=[A-Z])|_/).map(word => { return word.charAt(0).toUpperCase() + word.slice(1)}).join(' ')
}

export function pluralize(string, n, includeN=false){
  return `${includeN ? n + ' ' : ''}${string}${n !== 1 ? 's' : ''}`
}

export function parameterize(string, separator){
  return string.toLowerCase().replace(' ', separator || '_')
}

export function roleCheck(role, requiredRole){
  switch(requiredRole) {
  case 'user':
    return ['user', 'admin'].includes(role)
  case 'admin':
    return ['admin'].includes(role)
  default:
    return false
  }
}

export function isManagerOrAdmin(user){
  return !!user.attributes.manager || roleCheck(user.attributes.role, 'admin')
}

export const CustomPropTypes = {
  arrayOfElementType(elementType){
    const validator = (isRequired, props, propName, component) => {
      if(!elementType)
        return 'Cannot have an element type of null or undefined'

      let propsArray = props[propName]
      if(!Array.isArray(propsArray)){
        propsArray = propsArray ? [propsArray] : []
      }
      propsArray = flatten(propsArray)
      if(isRequired && propsArray.length === 0){
        return new Error(`Element of type ${elementType.name} is required in component ${component}`)
      }
      for(let index = 0; index < propsArray.length; index++){
        const prop = propsArray[index]
        if(!prop.type || prop.type !== elementType){
          return new Error(`Element ${prop} ${prop.type} is not of type ${elementType.name} in component ${component}`)
        }
      }
      return null
    }
    const notRequired = validator.bind(null, false)
    notRequired.isRequired = validator.bind(null, true)
    return notRequired
  }
}

export function debounce(fnc, wait, context){
  let timer, promise, resolve
  return function () {
    promise = promise || new Promise(r => resolve = r)
    clearTimeout(timer)
    timer = setTimeout(() => {
      resolve(fnc.bind(context)(...arguments))
      promise = null
    }, wait || 250)
    return promise
  }
}

export function sortByPosition(items){
  return items.sort((a, b) => {
    let aPosition = a.attributes.position
    let bPosition = b.attributes.position
    if(aPosition < bPosition) return -1
    if(bPosition < aPosition) return 1
    return 0
  })
}

export function isSameModel(m1, m2) {
  return _.isEqualWith(m1, m2, (att1, att2) => {
    if (typeof att1 !== 'object' && typeof att2 !== 'object') {
      if (typeof(att1) === 'number') {
        att1 += '' // conversts a number to a string for comparison
      }
      if (typeof(att2) === 'number') {
        att2 += '' // conversts a number to a string for comparison
      }
      return att1 === att2
    }
    // if nothing returned, comparison is handled by the lodash method instead
  })
}

export function isDirty(model, store) {
  return !model.id || !isSameModel(model, store.find(m => m.id === model.id))
}

export function datesInWeekOf(date) {
  return Array(7).fill(null).map((day, index) => moment(date).isoWeekday(index+1).startOf('day'))
}

export function dateKey(date) {
  return date.format('D-M-YY')
}

export function formatDate(m) {
  return m.format('YYYY-MM-DD')
}

export function chopString(s, max) {
  return s.length > max ? s.slice(0, max).concat('...') : s
}

export function assignIndexOf(collection) {
  return item => collection.includes(item) ? ({
    index: collection.indexOf(item),
    ...item
  }) : item
}

export function partition(predicateFn) {
  return collection => collection.reduce((accum, item) => {
    predicateFn(item) ? accum[0].push(item) : accum[1].push(item)
    return accum
  }, [[],[]])
}

export function pipe(...fns){
  return args => {
    return fns.reduce((accum, fn) => fn(accum), args)
  }
}

export function downloadContent(content, filename, type='application/text') {
  let blob = new Blob([content], {type})
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
}

export function roundMsToQuarterHours(ms) {
  return Math.round((ms / 3600000) / 0.25) * 0.25
}

export function elapsedTime(ms) {
  return moment.utc(ms).format('HH:mm:ss')
}

export function getMsDiffFromNow(newTime) {
  return moment().diff(newTime)
}

export function elementBottomOffset(element) {
  return Window.innerHeight - element.getBoundingClientRect().top
}

export function autoCompleteOrientation(maxOffset) {
  if (elementBottomOffset(document.activeElement) < maxOffset) {
    return {
      targetOrigin: {vertical: 'bottom', horizontal: 'left'},
      anchorOrigin: {vertical: 'top', horizontal: 'left'}
    }
  } else {
    return {}
  }
}

export function randomColor(){
  return {
    r: Math.floor(Math.random() * 255),
    g: Math.floor(Math.random() * 255),
    b: Math.floor(Math.random() * 255),
    a: 1
  }
}

export function translateRGBAColor(color){
  return color && `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`
}

export const history = process.env.NODE_ENV === 'test' ? createMemoryHistory() : createBrowserHistory()

