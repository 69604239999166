class ServiceAPI {

  static projects = (...services) => authenticated => authenticated.post('api/services/projects', { services })

  static syncService = service => authenticated => authenticated.post('api/services/sync', { service })

  static importService = data => authenticated => authenticated.post('api/services/import', data)

}

export default ServiceAPI
