import { RequestService } from '../services'
import { push } from 'connected-react-router'


/**
 * Decorate the request service HTTP methods to intercept requests and dispatch a redirect to the sign in page
 * if we encounter a 401 status code
 */
const authenticateRequestService = service => {
  const requestMethods = Object.getOwnPropertyNames(service).filter(key => {
    try{
      return typeof(service[key]) === 'function'
    }catch(err){
      return false
    }
  })
  /**
   * This is a relatively expensive process performed for every API request.
   * If this causes performance issues in the future we might want to revisit this.
   */
  return dispatch => {
    const authenticatedService = {}
    requestMethods.forEach(method =>
      authenticatedService[method] = async (...args) => {
        try{
          return await service[method].apply(service, args)
        }
        catch(error){
          if(error.res && error.res.status && error.res.status === 401){
            dispatch(push('/sessions/sign_in'))
          }
          throw error
        }
      })
    return authenticatedService
  }
}



const AuthenticatedRequestService = authenticateRequestService(RequestService)

/**
 * API Middleware
 * Responsible for providing authenticated and unauthenticated variants of the RequestService
 * to API actions
 */
export default function reduxAPIMiddleware() {
  return (next) => (action) => {

    const { api, type, ...rest } = action
    /**
     * We only know how to handle actions with the 'api' key set
     */
    if (!api) return next(action)

    /**
     * Inject the request service, and the authenticated request service into the API
     * method
     */
    return next({
      type,
      promise: api(AuthenticatedRequestService(next), RequestService),
      ...rest
    })
  }
}
