import ActionSet from './ActionSet'
import SessionActions from './SessionActionSet'
import SnackBarActions from './SnackBarActionSet'
import UserActions from './UserActionSet'
import CustomerActions from './CustomerActionSet'
import ProjectActions from './ProjectActionSet'
import ProjectTaskActions from './ProjectTaskActionSet'
import ProjectUserActions from './ProjectUserActionSet'
import TimesheetActions from './TimesheetActionSet'
import TimesheetEntryActions from './TimesheetEntryActionSet'
import OAuthActions from './OAuthActionSet'
import TimerActions from './TimerActionSet'

export const reducers = {
  session: SessionActions.reducers,
  snackbar: SnackBarActions.reducers,
  user: UserActions.reducers,
  customers: CustomerActions.reducers,
  projects: ProjectActions.reducers,
  projectTasks: ProjectTaskActions.reducers,
  projectUsers: ProjectUserActions.reducers,
  timesheet: TimesheetActions.reducers,
  timesheetEntry: TimesheetEntryActions.reducers,
  oauth: OAuthActions.reducers,
  timer: TimerActions.reducers,
}

export { ActionSet, SessionActions, SnackBarActions, UserActions, CustomerActions, ProjectActions, ProjectTaskActions, ProjectUserActions, TimesheetActions, TimesheetEntryActions, OAuthActions, TimerActions }
