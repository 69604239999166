import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { ListItem } from 'material-ui/List'


const CardListDragTarget = {
  drop(props, monitor, dropZone) {
    dropZone.dropped(monitor.getItem())
  }
};

export class CardListItemDropZone extends Component{

  static propTypes = {
    isOver: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func,
    onReorder: PropTypes.func,
    insertAt: PropTypes.number
  }

  static collect = (connect, monitor) => {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver()
    }
  }

  dropped = ({item}) => {
    this.props.onReorder(this.props.insertAt, item)
  }

  connectDropTarget = cmp => this.props.connectDropTarget(cmp)

  renderHoveredBody = () =>
    <div style={{
      height: 15,
      padding: 15,
      textAlign: 'center',
      border: '1px dashed grey',
      color: '#dadada'
    }}/>


  renderEmptyBody = () => <div style={{height: 5}}></div>

  get hoverStyle(){
    return { margin: '-5px 0', padding: 10, zIndex: 10, position: 'relative'  }
  }

  get emptyStyle(){
    return { height: 10, margin: '-5px 0', padding: 0, zIndex: 10, position: 'relative' }
  }

  render = () => this.connectDropTarget(
    <div style={ this.props.isOver  ? this.hoverStyle : this.emptyStyle}>
      <ListItem style={{margin: 0, padding: 0}} disabled={true}>
        {this.props.isOver ? this.renderHoveredBody() : this.renderEmptyBody()}
      </ListItem>
    </div>
  )

}

export default DropTarget('CardListItem', CardListDragTarget, CardListItemDropZone.collect)(CardListItemDropZone);
