import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import { AccordionCard } from '../../components/cardLayout'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { HorizontalCardLayout } from '../../components/cardLayout'

export class Main extends Component{

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    dispatch: PropTypes.func,
    routes: PropTypes.array
  }

  get path() {
    return this.props.routes && this.props.routes[3] && this.props.routes[3].path
  }

  get accordionTabs() {
    return [].filter(tab => tab.items.length !== 0)
  }

  get activeSection() {
    return Math.max(this.accordionTabs.findIndex(({path}) => path === this.path), 0)
  }

  onTabClick = (item) => {
    this.props.dispatch(push(`/home/${item.path}`))
  }

  // AccordionCard needs to wait till everything is loaded before rendering
  render = () =>
    <HorizontalCardLayout>
      <AccordionCard tabs={this.accordionTabs} activeSection={this.activeSection} path={this.path} onTabClick={this.onTabClick} />
      { this.props.children }
    </HorizontalCardLayout>
}

export default connect(({session}) => session)(Main)
