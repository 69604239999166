import React, { Component } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'
import {Toolbar, ToolbarGroup,ToolbarTitle} from 'material-ui/Toolbar'
import {Card } from 'material-ui/Card'
import Paper from 'material-ui/Paper'
import { connect } from 'react-redux'
import { TimesheetActions, ProjectActions, CustomerActions, UserActions, SnackBarActions } from '../../actionsets'
import { TimesheetSummary, TimesheetWeeklySummary, TimesheetTaskSummary, TimesheetFilter } from '.'
import { ModelStatus } from '../../constants'
import { RequestService } from '../../services'
import { TimesheetEntryAPI } from '../../api'
import { downloadContent } from '../../utils'

export class Timesheets extends Component{

  static propTypes = {
    dispatch: PropTypes.func,
    mode: PropTypes.string,
    timesheets: PropTypes.array.isRequired,
    requestingTimesheets: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
  }

  static styles = {

  }

  constructor(props){
    super(props)

    TimesheetActions.bindActions(this)
    ProjectActions.bindActions(this)
    CustomerActions.bindActions(this)
    UserActions.bindActions(this)
    SnackBarActions.bindActions(this)

  }

  componentWillMount = () => {
    this.actions.searchUsers({status: ModelStatus.active})
    this.actions.searchProjects({status: ModelStatus.active})
    this.actions.searchCustomers({status: ModelStatus.active})
  }

  loadTimesheets = filterParams => {
    this.actions.searchTimesheets(filterParams)
  }

  downloadTimesheetEntriesCSV = async filterParams => {
    try {
      this.actions.alert('Downloading as CSV..')
      let csv = await TimesheetEntryAPI.csv(filterParams)(RequestService)
      let filename = `timesheet_entries_${moment(filterParams.startDate).format('MMMM_DD')}_${moment(filterParams.endDate).format('MMMM_DD_YYYY')}.csv`
      downloadContent(csv.text, filename)
    }

    catch(e) {
      this.actions.alert('The file could not be downloaded')
    }
  }

  onApprove = timesheet => {
    const { startDate, endDate, user } = timesheet.attributes
    this.actions.approveTimesheet(user.id, startDate, endDate)
  }

  onUnapprove = timesheet => {
    const { startDate, endDate, user } = timesheet.attributes
    this.actions.unapproveTimesheet(user.id, startDate, endDate)
  }

  get mode() {
    return this.props.mode
  }

  renderTimesheetSummary = (timesheet, approver) => {
    if (timesheet?.attributes?.summary?.groupBy === 'week') {
      return <TimesheetWeeklySummary key={timesheet.id} timesheet={timesheet} />
    } else if (timesheet?.attributes?.summary?.groupBy === 'task') {
      return <TimesheetTaskSummary key={timesheet.id} timesheet={timesheet} />
    } else {
      return <TimesheetSummary
        key={timesheet.id}
        timesheet={timesheet}
        approver={approver}
        projects={this.props.projects}
        disableActions={this.mode !== 'approval' || !timesheet.attributes.summary.canApprove}
        onApprove={() => this.onApprove(timesheet)}
        onUnapprove={() => this.onUnapprove(timesheet)}
      />
    }
  }

  render = () => {
    const { timesheets, user, users, projects, customers } = this.props
    const totals = timesheets.reduce((totals, {attributes: {summary: { internalTime, externalTime }}}) => ({
      internalTime: internalTime + totals.internalTime,
      externalTime: externalTime + totals.externalTime
    }), { internalTime: 0, externalTime: 0 })

    return (
      <Paper style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Toolbar style={{minHeight: 50}}>
          <ToolbarGroup>
            <ToolbarTitle text={this.mode === 'approval' ? 'Timesheet Approval' : 'Timesheets'} />
          </ToolbarGroup>
        </Toolbar>
        <Card style={{padding: 10}}>

          <TimesheetFilter
            onChange={this.loadTimesheets}
            onDownload={this.downloadTimesheetEntriesCSV}
            users={users}
            projects={projects}
            customers={customers}
            mode={this.mode}
          />

          <div style={{margin: '1em 0', color: '#666'}}>
            <span><b>Internal Hours: </b>{totals.internalTime}</span>
            <span style={{marginLeft: '1em'}}><b>External Hours: </b>{totals.externalTime}</span>
          </div>

          {timesheets.map(ts => ts.attributes.summary.items.length > 0 && this.renderTimesheetSummary(ts, user))}

        </Card>
      </Paper>
    )
  }
}

export default connect(({session, timesheet, user, projects, customers}) => { return {...timesheet, ...user, ...projects, ...customers, ...session}})(Timesheets)

