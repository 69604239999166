import React, {  } from 'react'
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton'
import ContentLink from 'material-ui/svg-icons/content/link'

export default function LinkIcon ({ source, url }) {
  return (
    <IconButton touch
      tooltip={`View in ${source}`}
      tooltipPosition='top-left'
      style={LinkIcon.styles.linkIcon}
      href={url}
      target='_blank'
    >
      <ContentLink hoverColor='grey' />
    </IconButton>
  )
}

LinkIcon.styles = {
  margin: '0px 12px'
}

LinkIcon.propTypes = {
  source: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
