import React, { Component } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

export default class CRUDForm extends Component{

  static propTypes = {
    match: PropTypes.object,
  }

  static errorStyle = {
    color: '#e23838',
    background: 'pink',
    padding: '15px 5px',
    borderLeft: '2px solid red'
  }

  get editMode(){
    return !!this.editId
  }

  get createMode(){
    return !this.editMode
  }

  get editId(){
    return this.props.match.params.editId
  }

  renderSubmissionErrors = () => {
    if(!this.state.submissionErrors.length)
      return

    return this.state.submissionErrors.map(({ detail,  source: { pointer }}, index) => {
      return (
        <p key={index} style={CRUDForm.errorStyle}>
          {`${pointer}`.replace(/.*\//, '')} {detail}
        </p>
      )
    })
  }

  componentDidMount = async () => {
    if(this.editMode){
      return this.loadEditItem && this.loadEditItem()
    }else{
      return this.loadCreateItem && this.loadCreateItem()
    }
  }

  onFormValueChange = (valueName, valueExtractor) => {
    return (...args) => {
      const value = valueExtractor(...args)
      this.setState({ [valueName]: value})
    }
  }

  onFormDateChange = (valueName, valueExtractor) => {
    return (...args) => {
      const value = moment(valueExtractor(...args))
      this.setState({ [valueName]: value.format('LL')})
    }
  }

  nthValue = (n) => {
    return (...args) => {
      return args[n]
    }
  }

  requireFields = (requiredFields, errors={}) => {
    requiredFields.forEach(key => {
      if(!(this.state[key] || this[key]))
        errors[key] = 'This field is required'
    })
    return errors
  }

  render = () => false
}
