
class LocalStore {

  static LOCAL_STORE_KEY = 'vitalstatistix.store'

  static store

  static clear() {
    LocalStore.store = {}
    window.localStorage.removeItem(LocalStore.LOCAL_STORE_KEY)
  }

  constructor(name){
    this.name = name
    if(!LocalStore.store){
      try{
        LocalStore.store = JSON.parse(window.localStorage.getItem(LocalStore.LOCAL_STORE_KEY)) || {}
      }
      catch(err){
        LocalStore.store = {}
      }
    }
  }

  remove(key) {
    delete LocalStore.store[`${this.name}.${key}`]
    this.save()
  }

  set(key, value) {
    LocalStore.store[`${this.name}.${key}`] = value
    this.save()
  }

  get(key) {
    return LocalStore.store[`${this.name}.${key}`]
  }

  save() {
    window.localStorage.setItem(LocalStore.LOCAL_STORE_KEY, JSON.stringify(LocalStore.store))
  }

}

export default LocalStore