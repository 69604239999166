import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ListView, ListViewColumn } from '../../components'
import RaisedButton from 'material-ui/RaisedButton'
import { ItemRenderers, ItemSorters } from '../../components/List'
import { UserActions } from '../../actionsets'
import {Toolbar, ToolbarGroup,ToolbarTitle} from 'material-ui/Toolbar'
import {Card } from 'material-ui/Card'
import Paper from 'material-ui/Paper'
import { FilterTypes } from '../../constants'
import { FilterBar } from '../FilterBar'
import { UserForm } from '.'
import { connect } from "react-redux"
import Dialog from "../Dialog";

export class Users extends Component{

  static propTypes = {
    dispatch: PropTypes.func,
    users: PropTypes.array.isRequired
  }

  constructor(props){
    super(props)
    this.state = {
      selected: null,
      filterState: FilterTypes.ACTIVE
    }
    UserActions.bindActions(this)
  }

  componentWillMount = () => {
    this.loadUsers()
  }

  loadUsers = (query=this.filterToStatus(this.state.filterState)) => {
    this.actions.searchUsers(query)
  }

  userSelected = (selected) => {
    this.setState({selected})
  }

  onFormSubmitted = success => {
    if (success) this.loadUsers()
    this.setState({selected: null})
  }

  filterToStatus = filter => filter !== FilterTypes.ALL ? {status: filter.toLowerCase()} : {}

  onFilterChange = (filter) => {
    this.setState({filterState: filter})
    this.loadUsers(this.filterToStatus(filter))
  }

  render = () =>
    <Paper style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Toolbar style={{minHeight: 50}}>
        <ToolbarGroup>
          <ToolbarTitle text="Users" />
        </ToolbarGroup>
      </Toolbar>
      <Card style={{padding: 10}}>
        <RaisedButton style={{float: 'right', marginLeft: 10}} label='New' primary={true} onClick={() => this.setState({selected: {}})} />
        <FilterBar filter={ this.state.filterState } onFilterChange={ this.onFilterChange } />

          <Dialog
            title={this.state.selected && this.state.selected.attributes ? 'Update User' : 'New User'}
            modal={true}
            open={!!this.state.selected}
            autoScrollBodyContent={true}
          >
            <UserForm
              {...this.props}
              editId={this.state.selected && this.state.selected.id}
              onSubmitted={() => this.onFormSubmitted(true)}
              onCancel={() => this.onFormSubmitted(false)}
            />
          </Dialog>


          <ListView
            pagination={{pageSize: 10}}
            items={this.props.users}
            sortable={true}
            defaultSortBy='name'
            defaultSortOrder='asc'
            filtering={true}
            onRowClick={this.userSelected}
          >

          <ListViewColumn name='Name' attribute='name' itemSorter={ItemSorters.stringPlainItemSorter}/>
          <ListViewColumn name='Email' attribute='email' itemSorter={ItemSorters.stringPlainItemSorter}/>
          <ListViewColumn name='Role' attribute='role' itemRenderer={ItemRenderers.titleizedItemRenderer} />
          <ListViewColumn name='Status' attribute='status' itemRenderer={ItemRenderers.titleizedItemRenderer} />
        </ListView>
      </Card>
    </Paper>
}

export default connect(({user}) => user)(Users)