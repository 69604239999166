import React, {  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import moment from 'moment'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import MenuItem from 'material-ui/MenuItem'
import IconButton from 'material-ui/IconButton'
import Close from 'material-ui/svg-icons/navigation/close'
import CRUDForm from '../../../containers/CRUDForm'
import { TimesheetEntryActions, ProjectTaskActions } from '../../../actionsets'
import { ProjectTaskAutoComplete } from '../'
import { chopString, formatDate, roundMsToQuarterHours, elapsedTime } from '../../../utils'
import { TimerStore } from '../../../services'
import { ModelStatus } from '../../../constants'


const FORM_KEY = 'form'
const AUTOSAVE = 5000 // save to local storage after AUTOSAVE ms

export class TimerForm extends CRUDForm{

  static propTypes = {
    time: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  static styles = {
    fields: {
      flex: 1,
      display: 'flex',
      height: 110,
    },

    actions: {
      flex: 0,
      minWidth: 110,
      marginLeft: 20,
    },

    timeField: {
      flex: 0,
      minWidth: 65,
      marginRight: 10,
      paddingTop: 10,
    },

    taskField: {
      flex: 1,
      marginRight: 10,
      width: 0,
      paddingTop: 10,
    },

    commentField: {
      flex: 1,
      width: 0,
      paddingTop: 10,
    },

    menuItem: {
      fontSize: 14,
    },
  }

  static initialState = {
    errors: {},
    submissionErrors: [],
    projectTaskId: '',
    comment: '',
  }

  constructor(props){
    super(props)
    TimesheetEntryActions.bindActions(this)
    ProjectTaskActions.bindActions(this)
    this.state = TimerStore.get(FORM_KEY) || TimerForm.initialState
  }

  componentWillMount = () => {
    this.actions.searchTimerProjectTasks({userId: this.props.user.id, status: ModelStatus.active}, {createdAt: 'DESC', projectId: 'DESC'})
  }

  componentDidMount = () => {} // disable default CRUDForm behavior

  get newTimesheetEntry(){
    const { comment, projectTaskId } = this.state
    const { time, user: {id} } = this.props
    return {id: null, attributes: {userId: id, date: formatDate(moment()), time: roundMsToQuarterHours(time), projectTaskId, comment}}
  }

  resetForm = () => {
    this.autoSave(TimerForm.initialState)
    this.setState(TimerForm.initialState)
  }

  submit = async () => {
    if (!this.validate()) return

    try {
      await this.actions.createTimesheetEntry(this.newTimesheetEntry)
      this.resetForm()
      this.props.onSubmitted && this.props.onSubmitted()
    } catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['comment', 'projectTaskId']
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  taskOptions = () => this.props.projectTasks.map(task => ({
    id: task.id,
    text: `[${task.attributes.projectCode}] ${task.attributes.name}`,
    display: <MenuItem
              primaryText={chopString(task.attributes.name, 40)}
              secondaryText={task.attributes.projectCode}
              style={TimerForm.styles.menuItem}
            />
  }))

  onFieldChange = (fieldName, value) => {
    this.setState({[fieldName]: value})
    this.autoSave()
  }

  autoSave = (params=null) => {
    const save = state => {
      clearTimeout(this.timer)
      this.timer = null
      TimerStore.set(FORM_KEY, state)
    }

    if (params) {
      save(params)
    } else {
      if (!this.timer) {
        this.timer = setTimeout(() => save(this.state), AUTOSAVE)
      }
    }
  }

  render = () =>
    <div style={TimerForm.styles.fields}>

      <TextField
        floatingLabelText="Time"
        hintText="Time"
        value={elapsedTime(this.props.time)}
        errorText={this.state.errors.time}
        style={TimerForm.styles.timeField}
        disabled
      />

      <ProjectTaskAutoComplete
        floatingLabelText='Task'
        value={this.state.projectTaskId}
        onChange={option => this.onFieldChange('projectTaskId', option.id)}
        style={TimerForm.styles.taskField}
      />

      <TextField
        floatingLabelText="Comment"
        hintText="Comment"
        value={this.state.comment}
        onChange={e => this.onFieldChange('comment', e.target.value)}
        errorText={this.state.errors.comment}
        style={TimerForm.styles.commentField}
      />
      <div style={TimerForm.styles.actions}>

        <IconButton
          onClick={this.props.onCancel}
          style={{float: 'right', padding: 10}}
        >
          <Close />
        </IconButton>
        <RaisedButton label='Save' onClick={this.submit} />
      </div>

    </div>

}


export default connect(state => ({...state.session, projectTasks: state.projectTasks.timerProjectTasks}))(TimerForm)

