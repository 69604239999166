import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ProjectManagement } from '.'
import { UserRestrictedView } from '../'
import { OAuthActions, ProjectActions } from '../../actionsets'
import { CustomerActions } from '../../actionsets'
import { connect } from "react-redux";

export class ProjectManagementWrapper extends Component{

  constructor(props){
    super(props)
    OAuthActions.bindActions(this)
    ProjectActions.bindActions(this)
    CustomerActions.bindActions(this)
  }

  componentWillMount(){
    this.actions.loadProject(this.projectId)
    this.actions.loadCustomers()
    this.actions.getServices()
  }

  onProjectUpdate() {
    this.actions.loadProject(this.projectId)
  }

  static propTypes = {
    project: PropTypes.object,
    requestingProjects: PropTypes.bool,
    customers: PropTypes.array,
    requestingCustomers: PropTypes.bool,
    user: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
  }

  get projectId() {
    return this.props.match.params.editId
  }

  get loaded(){
    return this.props.user.id && this.props.customers.length && !this.props.requestingCustomers && this.props.project && !this.props.requestingProjects
  }

  render = () =>
    <div>
      { this.loaded && <UserRestrictedView component={ProjectManagement} {...this.props} onProjectUpdate={() => this.onProjectUpdate()} /> }
    </div>
}

export default connect(({projects, customers, session}) => ({ ...projects, ...customers, ...session }))(ProjectManagementWrapper)