import RequestService from './RequestService'
import LocalStore from './LocalStore'

const OAuthStore = new LocalStore('oath')
const TimerStore = new LocalStore('timer')

export {
  RequestService,
  LocalStore,
  OAuthStore,
  TimerStore
}
