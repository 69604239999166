import React, { Component } from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash'
import AutoComplete from 'material-ui/AutoComplete'
import MenuItem from 'material-ui/MenuItem'
import TextField from 'material-ui/TextField'
import { autoCompleteOrientation } from '../utils'


export class TimeInput extends Component {

  static ORIENTATION_OFFSET = 200 // if less space is available, the dropdown will render above the input

  static propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    increments: PropTypes.array.isRequired,
    errorText: PropTypes.string,
    style: PropTypes.object,
    floatingLabelText: PropTypes.string,
    hintText: PropTypes.string,
    noMatchesError: PropTypes.string,
    maxSearchResults: PropTypes.number,
    disabled: PropTypes.bool,
  }

  static styles = {
    textField: {
      width: '100%',
    },
    menuItem: {
    }
  }

  constructor(props){
    super(props)
    this.state = {
      errorText: props.errorText || '',
      orientation: {}
    }
  }


  componentWillReceiveProps = nextProps => {
    if (nextProps.errorText !== this.props.errorText) {
      this.setState({errorText: nextProps.errorText})
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const props = ['value', 'disabled']
    return (JSON.stringify(nextState) !== JSON.stringify(this.state)) || (JSON.stringify(_.pick(nextProps, props)) !== JSON.stringify(_.pick(this.props, props)))
  }

  get noMatchesErrorText(){ return this.props.noMatchesError || 'Not a valid option' }

  filterFunction = (text, option) => {
    return option.includes(text) || parseFloat(option) === parseFloat(text)
  }

  findMatchesFor = (input, dataSource) => dataSource.filter(optn => this.filterFunction(input.trim(), optn.text))

  floatToTime = input => {
    let index = input.indexOf('.')
    let hours = input
    let minutes = ''
    if (index > -1) {
      hours = input.substring(0, index)
      minutes = parseFloat(input.substring(index)) * 60
    }
    return `${hours && hours + 'h'} ${minutes && minutes + 'min'}`
  }

  dataSourceFor = (input) => {
    if (!input || (isNaN(input) && input !=='.')) {
      return []
    } else {
      let index = input.indexOf('.')
      let value = (index > -1) ? input.substring(0, index) : input
      let options = this.props.increments.map(incr => `${value}${incr}`)

      return options.map(optn => {
        return {
          value: optn,
          text: optn,
          display: <MenuItem
                    primaryText={optn}
                    secondaryText={this.floatToTime(optn)}
                    style={TimeInput.styles.menuItem}
                  />
        }
      })
    }
  }

  useDefaultMatch = (input) => {
    let defaultMatch = this.findMatchesFor(input, this.dataSourceFor(input))[0]
    if (defaultMatch) {
      if (defaultMatch !== input) this.props.onChange(defaultMatch.value)
    } else {
      if (input) this.setState({errorText: this.noMatchesErrorText})
    }
  }

  onUpdateInput = input => {
    let matches = this.findMatchesFor(input, this.dataSourceFor(input))
    let errorText = (input && matches.length === 0) ? this.noMatchesErrorText : ''
    this.setState({errorText})
    this.props.onChange(input)
  }

  onSelectInput = input => {
    if (typeof input === 'object') {
      this.props.onChange(input.value)
    } else {
      this.useDefaultMatch(input)
    }
  }

  onBlur = () => this.useDefaultMatch(this.props.value)

  onFocus = () => this.setState({orientation: autoCompleteOrientation(TimeInput.ORIENTATION_OFFSET)})

  render = () => {
    const { value, style, floatingLabelText, maxSearchResults, disabled} = this.props
    if (disabled) {
      return (
        <TextField
          value={value}
          errorText={this.state.errorText}
          disabled
          {...{style, floatingLabelText}}
        />
      )
    } else {
      return (
        <AutoComplete
          filter={(searchText, key) => this.filterFunction(searchText.trim(), key)}
          dataSourceConfig={{text: 'text', value: 'display'}}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          searchText={value}
          dataSource={this.dataSourceFor(value)}
          openOnFocus={!!value}
          onNewRequest={this.onSelectInput}
          onUpdateInput={this.onUpdateInput}
          errorText={this.state.errorText}
          textFieldStyle={TimeInput.styles.textField}
          {...this.state.orientation}
          {...{style, maxSearchResults, floatingLabelText}}
        />
      )
    }
  }
}

export default TimeInput
