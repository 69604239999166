import React, { Component, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import Dialog from 'material-ui/Dialog'
import { dateKey } from '../../../utils'
import DatePicker from 'material-ui/DatePicker'
import {Toolbar, ToolbarGroup} from 'material-ui/Toolbar'
import FontAwesome from 'react-fontawesome'
import { TimesheetEntryActions } from '../../../actionsets'
import moment from 'moment'

export default class TimesheetCalendarToolbar extends Component {
  state = {}
  constructor(props){
    super(props)
    TimesheetEntryActions.bindActions(this)
  }

  static propTypes = {
    dates: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    navLeftBy: PropTypes.object.isRequired,
    navRightBy: PropTypes.object.isRequired,
    selectedDate: PropTypes.object,
    highlightedDate: PropTypes.object
  }

  static styles = {

    toolbar: {
      height: 'auto',
      flexWrap: 'wrap-reverse',
      padding: 0,
      margin: 0,
      backgroundColor: '#EEEEEE',
    },

    dateButtonGroup: {
      marginLeft: 10,
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    },

    datePickerGroup: {
      justifyContent: 'flex-end',
      flexGrow: 1,
    },

    datePicker: {
      padding: 0,
      margin: 0,
      height: '100%',
      backgroundColor: '#3949ab',
      paddingRight: 20,
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
      borderRadius: '2px',
    },

    datePickerLabel: {
      width: 100,
      top: 5,
      paddingLeft: 20,
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontSize: 14,
    },

    dateButton: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.6)',
    },

    navButton: {
      minWidth: 30,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.6)',
    },

    navButtonLabel: {
      padding: 0,
      width: 12,
    },

    dateButtonLabel: {
      padding: 0,
    },

    dateSelected: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#3F51B5',
      color: '#fff'
    },

    dateHighlighted: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#9FA8DA',
      color: '#fff',
    },

    dateMissingTime: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#FF6347',
      color: '#fff',
    },

    dateMissingTimeSelected: {
      flexShrink: 1,
      width: 100,
      margin: '10px 10px 10px 0px',
      backgroundColor: '#F00',
      color: '#fff',
    },
  }

  handleDateButtonDrag = (copyFromDate) => (e) => {
    e.dataTransfer.setData("copyFromDate", copyFromDate);
  }

  handleDateButtonDrop = (copyToDate) => (e) => {
    const copyFromDate = e.dataTransfer.getData("copyFromDate")
    this.setState({ copyDialogOpen: true, copyFromDate, copyToDate })
  }

  renderDateButton = date => {
    const key = dateKey(date)
    const { selectedDate, missingTimeByDate, highlightedDate } = this.props
    let style = date.isSame(highlightedDate, 'day') ? TimesheetCalendarToolbar.styles.dateHighlighted : TimesheetCalendarToolbar.styles.dateButton
    const dateSelected = date.isSame(selectedDate, 'day')
    if (missingTimeByDate[key]) {
      style = dateSelected ? TimesheetCalendarToolbar.styles.dateMissingTimeSelected : TimesheetCalendarToolbar.styles.dateMissingTime
    }
    else if (dateSelected) {
      style = TimesheetCalendarToolbar.styles.dateSelected
    }
    return  <FlatButton
              key={key}
              label={date.format('ddd Do')}
              onClick={() => this.props.onChange(date)}
              style={style}
              labelStyle={TimesheetCalendarToolbar.styles.dateButtonLabel}
              draggable="true"
              date={date}
              onDragStart={this.handleDateButtonDrag(date.format('YYYY-MM-DD'))}
              onDrop={this.handleDateButtonDrop(date.format('YYYY-MM-DD'))}
              onDragOver={e => e.preventDefault()}
            />
  }

  renderNavButton = (icon, onChange) => {
    return  <FlatButton
              icon={<FontAwesome title="navigate" name={icon} fixedWidth style={TimesheetCalendarToolbar.styles.navButtonLabel} />}
              onClick={onChange}
              style={TimesheetCalendarToolbar.styles.navButton}
            />
  }

  handleCopyTimesheetEntriesConfirm = (fromDate, toDate) => {
    this.setState({ copyDialogOpen: false, copyFromDate: undefined, copyToDate: undefined })
    this.props.copyTimesheetEntries(moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD'))
  }

  render = () => {

    const { dates, navLeftBy, navRightBy } = this.props

    return (
      <React.Fragment>
        <Toolbar style={TimesheetCalendarToolbar.styles.toolbar}>

          <ToolbarGroup firstChild={true} style={TimesheetCalendarToolbar.styles.dateButtonGroup}>

            { this.renderNavButton('chevron-left', () => this.props.onChange(moment(dates[0]).subtract(navLeftBy.offset, navLeftBy.factor))) }

            { dates.map(this.renderDateButton) }

            { this.renderNavButton('chevron-right', () => this.props.onChange(moment(dates[dates.length-1]).add(navRightBy.offset, navRightBy.factor))) }

          </ToolbarGroup>

          <ToolbarGroup style={TimesheetCalendarToolbar.styles.datePickerGroup}>
            <DatePicker
              hintText="Select Date"
              value={null}
              onChange={(event, date) => this.props.onChange(moment(date))}
              autoOk={true}
              mode='landscape'
              container='inline'
              style={TimesheetCalendarToolbar.styles.datePicker}
              textFieldStyle={TimesheetCalendarToolbar.styles.datePickerLabel}
              formatDate={date => moment(date).format('LL')}
              className='datepicker'
            />
          </ToolbarGroup>

        </Toolbar>
        <CopyTimesheetEntriesDialog
          open={this.state.copyDialogOpen}
          copyFromDate={this.state.copyFromDate}
          copyToDate={this.state.copyToDate}
          onClose={() => this.setState({ copyDialogOpen: false, copyFromDate: undefined, copyToDate: undefined })}
          onConfirm={this.handleCopyTimesheetEntriesConfirm}
          />
      </React.Fragment>
    )
  }

}

const CopyTimesheetEntriesDialog = ({ copyFromDate, copyToDate, open, onClose, onConfirm }) => {
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()

  const prevOpenRef = useRef();
  useEffect(() => {
    if (open && !prevOpenRef.current && copyFromDate && copyToDate) {
      setFromDate(new Date(copyFromDate))
      setToDate(new Date(copyToDate))
    }
    prevOpenRef.current = open
  }, [copyFromDate, copyToDate, open])

  return (
  <Dialog open={!!open} title="Copy Timesheet Entries" contentStyle={{maxWidth: '300px'}}>
    <div>
      <div>
        <DatePicker hintText="From Date" floatingLabelText="From Date" value={fromDate} onChange={(e, date) => setFromDate(date)}/>
        <DatePicker hintText="To Date" floatingLabelText="To Date" value={toDate} onChange={(e, date) => setToDate(date)}/>
      </div>
    </div>
    <div style={{float: 'right', marginTop: '1em'}}>
      <FlatButton label="Cancel" onClick={() => (onClose && onClose()) } />
      <RaisedButton label="Confirm" primary={true} onClick={() => { onConfirm && onConfirm(fromDate, toDate) }} />
    </div>
  </Dialog>
  )
}