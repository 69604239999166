import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ListView, ListViewColumn, FAButton } from '../../components'
import RaisedButton from 'material-ui/RaisedButton'
import { ItemRenderers, ItemSorters } from '../../components/List'
import { ProjectUserActions, UserActions } from '../../actionsets'
import Dialog from '../Dialog'
import { ProjectUserForm } from '.'
import FlatButton from 'material-ui/FlatButton'
import FontAwesome from 'react-fontawesome'
import { connect } from "react-redux"


export class ProjectUsers extends Component{

  static propTypes = {
    dispatch: PropTypes.func,
    projectUsers: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    projectId: PropTypes.string.isRequired,
  }

  constructor(props){
    super(props)
    ProjectUserActions.bindActions(this)
    UserActions.bindActions(this)

    this.state = {selected: null}
  }

  componentWillMount = () => {
    this.loadProjectUsers()
    this.actions.loadUsers()
  }

  loadProjectUsers = () => {
    this.actions.searchProjectUsers({projectId: this.props.projectId})
  }

  projectUserSelected = (selected) => {
    this.setState({selected})
  }

  onFormSubmitted = success => {
    if (success) this.loadProjectUsers()
    this.setState({selected: null})
  }

  onProjectUserDelete = async projectUser => {
    await this.actions.deleteProjectUser(projectUser.id)
    this.loadProjectUsers()
  }

  // users that are not yet associated with the project
  userOptionsForSelected = selected => {
    let users = this.props.users.filter(usr => !this.props.projectUsers.find(prj => prj.attributes.userId+'' === usr.id+''))
    if (selected.attributes) {
      let current = this.props.users.find(usr => selected.attributes.userId+'' === usr.id+'')
      users.push(current)
    }

    return users
  }

  renderDeleteAction = projectUser => (
    <FlatButton
      backgroundColor="#3F51B5"
      hoverColor="#7986CB"
      icon={<FontAwesome title="delete" name='trash-o' fixedWidth={true} style={{color: '#fff'}} />}
      onClick={event => { event.stopPropagation(); this.onProjectUserDelete(projectUser) }}
      style={{minWidth: 60}}
    />
  )

  renderManagerStatus = projectUser => (
    <FAButton
      tooltip='Manager'
      name={projectUser.attributes.manager ? 'check' : 'times'}
    />
  )

  render = () =>
    <div>
      <br/>
      <RaisedButton style={{float: 'right'}} label='New' primary={true} onClick={() => this.projectUserSelected({})} />

       <Dialog
        title="Assign User"
        modal={true}
        open={!!this.state.selected}
        autoScrollBodyContent={true}
      >
        <ProjectUserForm
          {...this.props}
          editId={this.state.selected && this.state.selected.id}
          users={this.state.selected && this.userOptionsForSelected(this.state.selected)}
          projectId={this.props.projectId}
          onSubmitted={() => this.onFormSubmitted(true)}
          onCancel={() => this.onFormSubmitted(false)}
        />
      </Dialog>

      <ListView
        pagination={{pageSize: 10}}
        items={this.props.projectUsers}
        sortable={true}
        defaultSortBy='projectName'
        defaultSortOrder='asc'
        filtering={true}
        onRowClick={this.projectUserSelected}
      >

        <ListViewColumn name='Project' attribute='projectName' itemSorter={ItemSorters.stringPlainItemSorter}/>
        <ListViewColumn name='User'    attribute='userName' itemSorter={ItemSorters.stringPlainItemSorter}/>
        <ListViewColumn name='Role'    attribute='role' itemRenderer={ItemRenderers.titleizedItemRenderer} />
        <ListViewColumn name='' itemRenderer={this.renderDeleteAction} />
      </ListView>
    </div>

}

export default connect(({projectUsers, user}) => {return {...projectUsers, ...user}})(ProjectUsers)

