import moment from 'moment'
import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'
import { TimerStatus } from '../constants'
import { TimerStore } from '../services'
import { getMsDiffFromNow } from '../utils'

const TIMER_KEY = 'timer'

export class TimerActionSet extends ActionSet {

  static initialState = {
    time: 0, // total time in ms for timer display
    historicalTime: 0, // time accumulated before last stop
    startedOn: '', // moment of last timer start
    status: TimerStatus.stopped,
  }

  static timer = null

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static loadTimer(creator, reducer){

    creator(() => dispatch => {
      let timer = TimerStore.get(TIMER_KEY) || TimerActionSet.initialState
      if (timer.status === TimerStatus.started) {
        TimerActionSet.timer = setInterval(() => dispatch(this.tickTimer()), 1000)
      }
      dispatch({ type: this.LOAD_TIMER, payload: timer })
    })

    reducer({
      [this.LOAD_TIMER]: (state, timer) => ({
        ...state,
        ...timer,
        time: timer.status === TimerStatus.started ? moment().diff(moment(timer.startedOn)) + timer.historicalTime : timer.historicalTime,
      })
    })
  }

  static startTimer(creator, reducer){

    creator(() => dispatch => {
      TimerActionSet.timer = setInterval(() => dispatch(this.tickTimer()), 1000)
      dispatch({ type: this.START_TIMER })
    })

    reducer({
      [this.START_TIMER]: state => {
        let newState = {
          ...state,
          startedOn: moment(),
          status: TimerStatus.started
        }
        TimerStore.set(TIMER_KEY, newState)
        return newState
      }
    })
  }

  static stopTimer(creator, reducer){

    creator(() => {
      clearInterval(TimerActionSet.timer)
      return ({type: this.STOP_TIMER })
    })

    reducer({
      [this.STOP_TIMER]: state => {
        let newState = {
          ...state,
          historicalTime: state.time,
          startedOn: '',
          status: TimerStatus.stopped
        }
        TimerStore.set(TIMER_KEY, newState)
        return newState
      }
    })
  }

  static clearTimer(creator, reducer){

    creator(() => {
      clearInterval(TimerActionSet.timer)
      return ({type: this.CLEAR_TIMER })
    })

    reducer({
      [this.CLEAR_TIMER]: state => {
        TimerStore.set(TIMER_KEY, TimerActionSet.initialState)
        return TimerActionSet.initialState
      }
    })
  }

  static tickTimer(creator, reducer){

    creator(() => (dispatch, getState) => {
      const { historicalTime, startedOn } = getState().timer
      const startDiff = startedOn ? getMsDiffFromNow(startedOn) : 0

      dispatch({
        type: this.TICK_TIMER,
        payload: historicalTime + startDiff
      })
    })

    reducer({
      [this.TICK_TIMER]: (state, time) => ({
        ...state,
        time,
      })
    })
  }

}

export default new TimerActionSet()
