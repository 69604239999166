import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Users } from '.'
import { UserRestrictedView } from '../'
import { connect } from "react-redux"

export class UsersWrapper extends Component{

  static propTypes = {
    user: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
  }

  get loaded(){
    return this.props.user.id
  }

  render = () =>
    <div>
      { this.loaded && <UserRestrictedView component={Users} {...this.props}/>}
    </div>
}

export default connect(({session}) => session)(UsersWrapper)