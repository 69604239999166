import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import { CenteredCard } from '../components'
import { Card, CardText, CardActions } from 'material-ui/Card';
import AppBar from 'material-ui/AppBar'
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { SessionActions } from '../actionsets'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export class Login extends Component {

  static propTypes = {
    errors: PropTypes.object.isRequired
  }

  constructor(props){
    super(props)
    this.state = {
      email: '',
      password: ''
    }
    SessionActions.bindActions(this)
  }

  componentDidMount = () => this._emailInput && this._emailInput.focus()

  setEmail    = (scope, email) => this.setState({email})

  setPassword = (scope, password) => this.setState({password})

  signinHandler = () => {
    const { email, password } = this.state
    this.actions.signIn({email, password})
  }

  keyPressed = ({which}) => which === 13 && this.signinHandler()

  errorText = () => this.props.errors[SessionActions.SIGN_IN] ?
                      this.props.errors[SessionActions.SIGN_IN][0].title :
                      null

  render = () =>
    <CenteredCard>
      <Card onKeyUp={this.keyPressed}>
        <AppBar
          title="Welcome Back!"
          showMenuIconButton={false}
        />
        <CardText>
           <TextField ref={ref => this._emailInput = ref} hintText="Email" floatingLabelText="Email" onChange={this.setEmail}/>
           <br/>
           <TextField hintText="Password" floatingLabelText="Password" type="password" onChange={this.setPassword}  errorText={this.errorText()}/>
        </CardText>
        <CardActions>
          <Link to='/sessions/forgot_password'>Forgot your password?</Link>
          <RaisedButton label="Sign In" primary={true} style={{float: 'right'}} onClick={this.signinHandler}/>
          <br style={{clear: 'both'}}/>
        </CardActions>
      </Card>
    </CenteredCard>
}

export default connect(({session}) => session)(Login)
