import request from 'superagent'
import qs from 'qs'
import { ConsoleOutputGroup } from '../models'

class RequestService {

  static get(url, data, options) { return this.call(url, {type: 'get', data, ...options}) }

  static post(url, data, options) { return this.call(url, {type: 'post', data, ...options}) }

  static put(url, data, options) { return this.call(url, {type: 'put', data, ...options}) }

  static delete(url, data, options) { return this.call(url, {type: 'delete', data, ...options}) }

  static get token(){
    return window.localStorage.getItem('token')
  }

  static buildURL(url){
    return  `//${process.env.REACT_APP_API_HOST}${this.API_BASE_URL}${url}`
  }

  static buildFormData(params){
    const formData = new FormData()
    RequestService.encodeFormDataInputs(params, formData)
    return formData
  }

  static encodeFormDataInputs(data, formData, prefix=''){
    Object.keys(data).forEach(key => {
      const value = data[key]
      const nestedKey = prefix ? `${prefix}[${key}]` : key
      if(typeof value === 'object' && value.constructor !== File){
        RequestService.encodeFormDataInputs(value, formData, nestedKey)
      }else{
        formData.append(nestedKey, data[key])
      }
    })
  }

  static API_BASE_URL = '/'

  static submitForm(url, data, method){
    const downloadForm = document.createElement('form')
    downloadForm.action = RequestService.buildURL(url)
    downloadForm.target = '_blank'
    downloadForm.method = method || 'post'
    RequestService.encodeFormInputs(data, downloadForm)
    window.document.body.appendChild(downloadForm)
    downloadForm.submit();
    setTimeout(() => {
      window.document.body.removeChild(downloadForm)
    })
  }

  static encodeFormInputs(data, downloadForm, prefix=''){
    Object.keys(data).forEach(key => {
      const value = data[key]
      const nestedKey = prefix ? `${prefix}[${key}]` : key
      if(typeof value === 'object'){
        RequestService.encodeFormInputs(value, downloadForm, nestedKey)
      }else{
        let input   = document.createElement('input');
        input.value = data[key];
        input.name  = nestedKey;
        downloadForm.appendChild(input)
      }
    })
  }

  static call(path, { data=undefined, type, middleware }){

    const url = this.buildURL(path)

    const group = new ConsoleOutputGroup(`${type.toUpperCase()}: ${url}`)

    if(data)
      group.push(['%cDATA', 'font-weight: bold; color: #555599;', data])

    return new Promise((resolve, reject) => {
      data = type === 'get' ? qs.stringify(data, { arrayFormat: 'brackets' }) : data

      let req = request[type](url)[type === 'get' ? 'query' : 'send'](data)
      .set('Accept', 'application/json')
      .set('Authorization', this.token)

      if(middleware){
        req = middleware(req)
      }

      req = req.end(function(err, res){
        const body = res && (res.body || res.text)
        group.push(['%cBODY', 'font-weight: bold; color: #559191;', body])

        if (err) {
          group.finish(['%cERROR', 'font-weight: bold; color: red;', {error: err, message: err.message, res, body}])
          reject({error: err, message: err.message, res, body})
        }
        else {
          group.finish(['%cSUCCESS', 'font-weight: bold; color: green;', res, res.body])
          resolve(res)
        }
      })
      group.lines.push(['%cREQUEST', 'font-weight: bold; color: #555599;', req])
    })
  }
}

export default RequestService
