import React, {  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import _ from 'lodash'
import { roleCheck } from '../utils'

export class UserRestrictedView extends React.Component{

  static propTypes = {
    component: PropTypes.instanceOf(Function).isRequired,
    requiredRole: PropTypes.string,
    user: PropTypes.object,
    permit: PropTypes.func,
    includeProps: PropTypes.array,
  }

  get role(){
    return this.props.user.attributes.role
  }

  get allow(){
    const userLoaded = !!this.props.user.id
    const permitted = !this.props.permit || this.props.permit(this.props)
    const correctRole = !this.props.requiredRole || roleCheck(this.role, this.props.requiredRole)
    return userLoaded && permitted && correctRole
  }

  render = () => {
    const { includeProps, component: WrappedComponent } = this.props
    const allowedProps = includeProps ? _.pick(this.props, includeProps) : this.props
    return this.allow && <WrappedComponent {...allowedProps} role={this.role}/>
  }

}

export default connect(({session}) => session)(UserRestrictedView)