import ActionSet from './ActionSet'
import { TimesheetAPI } from '../api'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'


export class TimesheetActionSet extends ActionSet{

  static initialState = {
    timesheets: [],
    requestingTimesheets: false,
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]


  static searchTimesheets(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((query, order) => {
      return dispatch =>
        dispatch({
          type: this.SEARCH_TIMESHEETS,
          api: TimesheetAPI.search({...query, order})
        })
    })

    reducer({
      [this.SEARCH_TIMESHEETS_REQUEST] : (state, { request }) => {
        return {...state, requestingTimesheets: true, latestTimesheetsRequest: request, timesheets: []}
      },
      [this.SEARCH_TIMESHEETS_SUCCESS] : (state, { request, result: { body: { data: timesheets } } } ) => {
        if (state.latestTimesheetsRequest !== request) { return state } // Don't update state if we're not the latest request
        return {...state, timesheets, requestingTimesheets: false, latestTimesheetsRequest: undefined}
      },
      [this.SEARCH_TIMESHEETS_FAILURE] : (state) => {
        return {...state, requestingTimesheets: false}
      }
    })
  }

  static approveTimesheet(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((userId, startDate, endDate) => {
      return dispatch =>
        dispatch({
          type: this.APPROVE_TIMESHEET,
          api: TimesheetAPI.approve(userId, startDate, endDate)
        })
    })

    reducer({
      [this.APPROVE_TIMESHEET_SUCCESS] : (state, { result: { body: { data: model } } } ) => {
        const timesheets = [...state.timesheets]
        const index = timesheets.findIndex(e => e.id === model.id)
        if (index > -1) timesheets[index] = model
        return {...state, timesheets}
      }
    })
  }

  static unapproveTimesheet(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((userId, startDate, endDate) => {
      return dispatch =>
        dispatch({
          type: this.UNAPPROVE_TIMESHEET,
          api: TimesheetAPI.unapprove(userId, startDate, endDate)
        })
    })

    reducer({
      [this.UNAPPROVE_TIMESHEET_SUCCESS] : (state, { result: { body: { data: model } } } ) => {
        const timesheets = [...state.timesheets]
        const index = timesheets.findIndex(e => e.id === model.id)
        if (index > -1) timesheets[index] = model
        return {...state, timesheets}
      }
    })
  }

}

export default new TimesheetActionSet()
