import React, {  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { ProjectUserActions } from '../../actionsets'
import CRUDForm from '../../containers/CRUDForm'
import { ProjectUserAPI } from '../../api'
import { RequestService } from '../../services'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { push } from 'connected-react-router'
import { roleCheck } from '../../utils'
import Checkbox from 'material-ui/Checkbox'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'

export class ProjectUserForm extends CRUDForm{

  static propTypes = {
    dispatch: PropTypes.func,
    projects: PropTypes.array,
    projectId: PropTypes.any,
    users: PropTypes.array,
    onSubmitted: PropTypes.func,
    onCancel: PropTypes.func,
  }

  static styles = {
    fields: {
      display: 'flex',
      marginBottom: 20,
    },
    actions: {
      float: 'right'
    },
    selectField: {
      flex: 1,
    },
    checkField: {
      flex: 0,
      minWidth: 120,
      paddingTop: 35,
      paddingLeft: 20,
    }
  }

  static defaultProps = {
    projects: [],
    users: []
  }

  constructor(props){
    super(props)
    this.state = {
      errors: {},
      submissionErrors: [],
      projectId: this.props.projectId || '',
      userId: '',
      manager: false,
      projects: [],
      users: [],
    }
    ProjectUserActions.bindActions(this)
  }

  loadCreateItem = () => {
    if(this.createMode && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
    }
  }

  loadEditItem = async () => {
    if(this.userId !== this.editId && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
      return
    }
    try{

      const { body: { data: { attributes: { projectId, userId, manager }}}} = await ProjectUserAPI.show(this.editId)(RequestService)
      this.setState({
        projectId:  String(projectId), userId:  String(userId), manager
      })
    }catch(err){
      this.setState({ submissionErrors: [{ detail: `Couldn't find project user with id ${this.editId}`, source: { pointer:'' }}] })
    }
  }

  submit = async () => {
    if(!this.validate())
      return

    const { projectId, userId, manager } = {...this.state }
    const params = { projectId, userId, manager  }

    let action
    if(this.editMode){
      action = this.actions.updateProjectUser(this.editId, params)
    }else{
      action = this.actions.createProjectUser(params)
    }

    try{
      await action
      this.props.onSubmitted && this.props.onSubmitted()
    }catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['projectId', 'userId']
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  get editId(){
    return this.props.editId
  }

  get ProjectUserId(){
    return this.props.projectUser.id
  }

  get projectSelectField(){
    return (
      <SelectField
        style={ProjectUserForm.styles.selectField}
        floatingLabelText="Project"
        value={this.state.projectId}
        onChange={this.onFormValueChange('projectId', this.nthValue(2))}
        errorText={this.state.errors.projectId}
      >
        <MenuItem value={''} primaryText="" style={{minHeight: '32px'}} />
        { this.props.projects.map(project => <MenuItem key={`project-${project.id}`} value={project.id} primaryText={project.attributes.name} style={{minHeight: '32px'}} />) }
      </SelectField>
    )
  }

  get userSelectField(){
    return (
      <SelectField
        style={ProjectUserForm.styles.selectField}
        floatingLabelText="User"
        value={this.state.userId}
        onChange={this.onFormValueChange('userId', this.nthValue(2))}
        errorText={this.state.errors.userId}
      >
        <MenuItem value={''} primaryText="" style={{minHeight: '32px'}} />
        { this.props.users.map(user => <MenuItem key={`user-${user.id}`} value={user.id} primaryText={user.attributes.name} style={{minHeight: '32px'}} />) }
      </SelectField>
    )
  }

  render = () =>
    <div>
      <div style={ProjectUserForm.styles.fields}>
        {this.renderSubmissionErrors()}
        {this.props.projects.length > 0 && this.projectSelectField}
        {this.userSelectField}

        <Checkbox
          label="Manager"
          checked={this.state.manager || false}
          onCheck={() => {this.setState({manager: !this.state.manager})}}
          style={ProjectUserForm.styles.checkField}
        />
      </div>
      <div style={ProjectUserForm.styles.actions}>
        <FlatButton label="Cancel" style={{marginRight: 10}} onClick={this.props.onCancel} />
        <RaisedButton label='Save' primary={true} onClick={this.submit} />
      </div>
    </div>
}

export default connect(state => { return {...state.session} })(ProjectUserForm)
