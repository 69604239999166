import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import AppBar from 'material-ui/AppBar'
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { Card, CardText, CardActions } from 'material-ui/Card';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { CenteredCard } from '../components'
import { SessionActions } from '../actionsets'


export class SetPassword extends Component {

  static propTypes = {
    errors: PropTypes.object.isRequired
  }

  constructor(props){
    super(props)
    this.state = {
      password: '',
      passwordConfirmation: '',
    }
    SessionActions.bindActions(this)
  }

  componentDidMount = () => {
    if(this._emailInput) this._emailInput.focus()
    this.setState({token: window.location.hash.slice(1)})
  }

  setPassword = (scope, password) => this.setState({password})

  setPasswordConfirmation = (scope, passwordConfirmation) => this.setState({passwordConfirmation})

  keyPressed = ({which}) => which === 13 && this.savePassword()

  savePassword = () => {
    const { token, password, passwordConfirmation } = this.state
    this.actions.setPassword({token, password, passwordConfirmation})
  }

  setPasswordError = () =>
    this.props.errors[SessionActions.SET_PASSWORD][0].detail || this.props.errors[SessionActions.SET_PASSWORD][0].title

  errorText = () => this.props.errors[SessionActions.SET_PASSWORD] ?
                        this.setPasswordError() :
                        null

  render = () =>
    <CenteredCard>
      <Card onKeyUp={this.keyPressed}>
        <AppBar
          title="Set your password"
          showMenuIconButton={false}
        />
        <CardText>
           <TextField ref={ref => this._emailInput = ref} hintText="Password" floatingLabelText="Password" type="password" onChange={this.setPassword}/>
           <br/>
           <TextField hintText="Password Confirmation" floatingLabelText="Password again" type="password" onChange={this.setPasswordConfirmation} errorText={this.errorText()}/>
        </CardText>
        <CardActions>
          <Link to='/sessions/sign_in'>Sign In</Link>
          <RaisedButton label="Set Password" primary={true} style={{float: 'right'}} onClick={this.savePassword}/>
          <br style={{clear: 'both'}}/>
        </CardActions>
      </Card>
    </CenteredCard>
}

export default connect(({session}) => session)(SetPassword)
