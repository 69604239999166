import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Chip from 'material-ui/Chip'
import Avatar from 'material-ui/Avatar'
import Timer from 'material-ui/svg-icons/image/timer'
import { elapsedTime } from '../utils'
import { TimerStatus } from '../constants'


export class TimerDisplay extends Component {

  static propTypes = {
    time: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }

  renderIcon = style => <Avatar color="#444" icon={<Timer />} size={30} style={style} />

  render = () => {
    const { time, status, open, onClick } = this.props

    if (open || time) {
      return (
        <Chip
          style={{margin: 10, backgroundColor: status === TimerStatus.started ? '#FF7043' : '#FAFAFA'}}
          onClick={onClick}
        >
          { this.renderIcon({}) } { elapsedTime(time) }
        </Chip>
      )
    } else {
      return (
        <div style={{cursor: 'pointer'}} onClick={onClick}>
          { this.renderIcon({margin: 10}) }
        </div>
      )
    }
  }
}

export default TimerDisplay
