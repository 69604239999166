import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { TimesheetEntryActions } from '../../actionsets'
import {Toolbar, ToolbarGroup,ToolbarTitle} from 'material-ui/Toolbar';
import {Card } from 'material-ui/Card';
import Paper from 'material-ui/Paper';
import { TimesheetCalendarView } from './calendar'
import { isDirty, datesInWeekOf, dateKey, formatDate } from '../../utils'
import moment from 'moment'
import { connect } from "react-redux"
import { Prompt } from "react-router"

export class TimesheetEntry extends Component{

  static propTypes = {
    dispatch: PropTypes.func,
    timesheet: PropTypes.object.isRequired,
    timesheetEntries: PropTypes.array.isRequired,
    requestingTimesheetEntries: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  }

  constructor(props){
    super(props)
    TimesheetEntryActions.bindActions(this)
    this.state = {
      selectedDate: moment(),
      dates: datesInWeekOf(moment())
    }
  }

  componentDidMount = () => {
    this.loadTimesheetEntriesForRange(this.state.dates)
    this.loadTimesheetStatusForDate(this.state.selectedDate)
    this.actions.loadRecentTimesheetEntries()
  }

  componentDidUpdate() {
    if (this.numberOfUnsavedEntries !== 0) {
      onbeforeunload = _ => true
    } else {
      onbeforeunload = null
    }
  }

  componentWillUnmount() {
    onbeforeunload = null
  }

  get numberOfUnsavedEntries() {
    if (!this.props.form.entries) {
      return 0
    }

    return this.props.form.entries.filter(entry => isDirty(entry, this.props.timesheetEntries)).length
  }

  unsavedEntriesWarningText = numberOfUnsavedEntries => {
    return `You have ${numberOfUnsavedEntries} unsaved ${numberOfUnsavedEntries > 1 ? 'entries' : 'entry'}. Are you sure?`
  }

  pendingLoad = null
  loadTimesheetEntriesForRange = dates => {
    const loadEntries = () => {
      this.actions.setPendingTimesheetEntriesLoad(false)
      this.actions.searchTimesheetEntries({
        userId: this.props.user.id,
        startDate: formatDate(dates[0]),
        endDate: formatDate(dates[dates.length - 1]),
      }, {createdAt: 'ASC'})
    }
    if (!this.pendingLoad) {
      this.pendingLoad = setTimeout(() => this.pendingLoad = null, 500)
      loadEntries()
    } else {
      clearTimeout(this.pendingLoad)
      this.actions.setPendingTimesheetEntriesLoad(true)
      this.pendingLoad = setTimeout(() => {
        loadEntries()
        this.pendingLoad = null
      }, 500)
    }
  }

  loadTimesheetStatusForDate = date => {
    this.actions.statusForTimesheet(this.props.user.id, formatDate(moment(date).startOf('month')), formatDate(moment(date).endOf('month')))
  }

  setSelectedDate = (newDate, { reloadRequired = false} = {}) => {
    const { dates } = this.state
    const { startDate } = this.props.timesheet.attributes
    const dirtyModels = this.numberOfUnsavedEntries

    if (!reloadRequired && dirtyModels !== 0 && !window.confirm(this.unsavedEntriesWarningText(dirtyModels)))
      return

    if (!moment(startDate).isSame(newDate, 'month')) {
      this.loadTimesheetStatusForDate(newDate)
    }

    if (!reloadRequired && dates.find(d => dateKey(d) === dateKey(newDate))) {
      this.setState({selectedDate: newDate})
    } else {
      const newDates = datesInWeekOf(newDate)
      this.loadTimesheetEntriesForRange(newDates)
      this.setState({selectedDate: newDate, dates: newDates})
    }

  }

  render = () => {
    const numberOfUnsavedEntries = this.numberOfUnsavedEntries
    const shouldPreventPageLeave = numberOfUnsavedEntries !== 0

    // Workaround for react-router bug.
    // `message` must be a function.
    // For more information, see: https://github.com/ReactTraining/react-router/issues/5405#issuecomment-651904037
    const routerPromptMessage = (_location, _action) => {
      if (shouldPreventPageLeave) {
        return this.unsavedEntriesWarningText(numberOfUnsavedEntries)
      }
      return true
    }

    return (
      <Paper style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
        <Toolbar style={{minHeight: 50}}>
          <ToolbarGroup>
            <ToolbarTitle text="Timesheet Entry"/>
          </ToolbarGroup>
        </Toolbar>
        <Card style={{padding: 10}}>
          <Prompt when={shouldPreventPageLeave} message={routerPromptMessage} />
          <TimesheetCalendarView
              dates={this.state.dates}
              selectedDate={this.state.selectedDate}
              status={this.props.timesheet.attributes.status}
              timesheetEntries={this.props.timesheetEntries}
              requestingTimesheetEntries={this.props.requestingTimesheetEntries}
              highlightedDate={moment()}
              onDateChange={this.setSelectedDate}
          />

        </Card>
      </Paper>
    )
  }
}

export default connect(({session, timesheetEntry}) => { return {...timesheetEntry, ...session}})(TimesheetEntry)

