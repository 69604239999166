import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import FloatingActionButton from 'material-ui/FloatingActionButton'
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right'
import ChevronLeft from 'material-ui/svg-icons/navigation/chevron-left'
import { primary2Color } from '../../styles/theme'
import Responsive from '../Responsive'

const styles = {
  desktop: {
    carousel: {
      display: 'flex',           /* establish flex container */
      flexDirection: 'row',  /* make main axis vertical */
      flexBasis: 'content',
      flexWrap: 'nowrap',
      height: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      flex: '1 1 100%',
      overflow: 'hidden',
      margin: -50,
      padding: 50
    }
  },
  mobile: {
    carousel: {
      flex: '1 0 100%',
      display: 'flex',
      flexDirection: 'row'
    }
  },
  buttonWrapper: {
    margin: 'auto 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 56px'
  },
  cardMargin: 40,
  cardWidth: 370
}

export class Carousel extends Component {

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    index: PropTypes.number,
    onNextItem: PropTypes.func,
    onPreviousItem: PropTypes.func,
    fullScreen: PropTypes.bool,
    styles: PropTypes.object,
    dimensions: PropTypes.object
  }

  static defaultProps = {
    children: [],
    styles: {},
    renderButtons: true,
    dimensions: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      index: 0
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.dimensions.narrow !== this.props.dimensions.narrow){
      setTimeout(()=> this.forceUpdate, 3000)
    }
  }

  get childItemsToRender() {
    if(this.props.dimensions.narrow){
      return this.numberOfChildCards
    }
    if(this.props.fullScreen){
      return 1
    }
    const wrapperWidth = this.wrapper ? this.wrapper.offsetWidth : 0
    const buttonWidth = 2 * 56
    const padding = 2 * 30
    return parseInt((wrapperWidth - (buttonWidth + padding)) / (styles.cardWidth + styles.cardMargin / 2), 10)
  }

  get disableLeftButton() {
    return this.index === 0
  }

  get disableRightButton() {
    return this.index >= (this.numberOfChildCards - 1)
  }

  get requiredCarouselWidth() {
    return `${(styles.cardMargin * 2) + (styles.cardWith * this.childItemsToRender)}px` // floatitng action buttons + width of each card
  }

  get controlledByProps() {
    return !!this.props.onNextItem && !!this.props.onPreviousItem && this.props.index !== undefined
  }

  get index() {
    return this.controlledByProps ? this.props.index : this.state.index
  }

  get renderFromIndex(){
    return Math.max(0, Math.min(this.numberOfChildCards - this.childItemsToRender, this.index))
  }

  get numberOfChildCards(){
    return this.props.children.filter(x => typeof x.type === 'function').length
  }

  onNextItem = () => {
    return this.controlledByProps ? this.props.onNextItem(this.renderedIndices()[0] + 1) : this.setState({index: this.index + 1})
  }

  onPreviousItem = () => {
    return this.controlledByProps ? this.props.onPreviousItem(this.renderedIndices()[0] - 1) : this.setState({index: this.index - 1})
  }

  renderChildren = () => {
    const [left, right] = this.renderedIndices()
    const toRender = this.props.children.filter(x => typeof x.type === 'function').slice(left, right)
    return this.props.children.filter(child => typeof child.type !== 'function' || toRender.indexOf(child) > -1)
  }

  renderedIndices = () => {
    return [this.renderFromIndex, this.renderFromIndex + this.childItemsToRender]
  }

  renderLeftButton = () =>
    <div style={styles.buttonWrapper}>
      <FloatingActionButton disabled={this.disableLeftButton}  backgroundColor={primary2Color} onClick={this.onPreviousItem}>
        <ChevronLeft />
      </FloatingActionButton>
    </div>

  renderRightButton = () =>
    <div style={styles.buttonWrapper}>
      <FloatingActionButton disabled={this.disableRightButton} backgroundColor={primary2Color} onClick={this.onNextItem}>
        <ChevronRight />
      </FloatingActionButton>
    </div>

  onWrapperInitialize = wrapper => {
    this.wrapper = wrapper
  }

  get carouselStyle(){
    return this.props.dimensions.narrow ?
      styles.mobile.carousel :
      styles.desktop.carousel
  }


  render = () =>
    <div
      style={{
        ...this.carouselStyle,
        ...this.props.styles
      }}
      ref={this.onWrapperInitialize}
      >
        { !this.props.dimensions.narrow && this.renderLeftButton() }
        <div  style={{flex: 1, display: 'flex' }}>
          { this.renderChildren() }
          { !this.props.dimensions.narrow && this.renderRightButton() }
        </div>
    </div>
}

export default Responsive(Carousel)
