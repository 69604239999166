import { Component } from 'react'
import PropTypes from 'prop-types';
import * as ItemRenderers from './ItemRenderers'
import * as ItemSorters from './ItemSorters'

export class ListViewColumn extends Component{

  static propTypes = {
    itemRenderer: PropTypes.func.isRequired,
    attribute: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
    name: PropTypes.string.isRequired,
    itemSorter: PropTypes.func,
    sortable: PropTypes.bool.isRequired,
    style: PropTypes.object
  }

  static defaultProps = {
    itemRenderer: ItemRenderers.JSONAPIAttributeRenderer,
    itemSorter: ItemSorters.JSONAPIAttributeSorter,
    sortable: true,
    style: {}
  }

  render = () => false
}

export default ListViewColumn