import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ListItem, List } from 'material-ui/List'
import { CardText } from 'material-ui/Card'
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right'
import { Themed } from '../../containers/VitalstatistixThemeProvider.js'

export class AccordionTab extends Component{

  static propTypes = {
    data: PropTypes.object,
    active: PropTypes.bool,
    primaryColor: PropTypes.string,
    onTabClick: PropTypes.func
  }

  static styles = {
    outer: {
      active: { flexShrink: '1', flexGrow: '1', padding: '0px', height: '100px' }, // no idea why height fixes problems
      inactive: { padding: '0px' }
    },
    inner: {
      display: 'flex',
      flexFlow: 'column nowrap',
      height: '100%'
    },
    tabHeader: {
      color: '#FFFFFF',
      flexGrow: 0,
      flexShrink: 0,
      fontSize: '20px'
    }
  }

  renderListItem = (item, index) =>
    <ListItem key={index} onClick={() => this.props.data.onItemClick(item)} >
      <div style={{display: 'flex'}}>
        <span style={{flexGrow: '1', flexShrink: '1'}} dangerouslySetInnerHTML={{__html: item.attributes.name }}/>
        <span style={{marginTop: 'auto', marginBottom: 'auto'}}>
          <ChevronRight/>
        </span>
      </div>
    </ListItem>

  renderList = () =>
    <List style={{padding: '0px', paddingTop: '0px', paddingBottom: '0px', overflowY: 'scroll', flexGrow: 1, flexShrink: 1}}>
      { this.props.data.items.map(this.renderListItem) }
    </List>


  render = () =>
    <CardText style={this.props.active ? AccordionTab.styles.outer.active : AccordionTab.styles.outer.inactive} >
      <div style={AccordionTab.styles.inner}>
        <ListItem primaryText={this.props.data.name} style={{backgroundColor: this.props.primaryColor, ...AccordionTab.styles.tabHeader}} onClick={this.props.onTabClick}/>
        { this.props.active && this.renderList() }
      </div>
    </CardText>

}

export default Themed(AccordionTab)