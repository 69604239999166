import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { debounce } from '../utils'

const resizeListeners = []

export class ResponsiveWrapper extends Component{

  static propTypes = {
    component: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
  }

  static widths = {
    wide: 1200,
    medium: 975,
    narrow: 0
  }

  static dimensions = {
    windowWidth: 0,
    windowHeight: 0,
    windowSize: 'wide'
  }

  state = {
    dimensions: ResponsiveWrapper.dimensions
  }

  componentDidMount = () => {
    this.mounted = true
    resizeListeners.push(this.onResize)
    onWindowResize()
  }

  componentWillUnmount = () => {
    this.mounted = false
    resizeListeners.splice(resizeListeners.indexOf(this.onResize), 1)
  }

  onResize = (dimensions) => {
    if(this.mounted) this.setState({ dimensions })
  }

  render = () =>{
    const { component: ChildComponent, children, ...componentProps } = this.props
    return (
      <ChildComponent dimensions={this.state.dimensions} {...componentProps}>
        {children}
      </ChildComponent>
    )
  }

}

const Responsive = component => {
  const WrappedResponsive = props => <ResponsiveWrapper {...props} component={component}>{props.children}</ResponsiveWrapper>
  WrappedResponsive.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  }
  return WrappedResponsive
}

export default Responsive

function onWindowResize(){
  const windowWidth = window.document.body.offsetWidth
  const windowHeight = window.document.body.offsetHeight
  let windowSize = 'wide'
  try{
    windowSize = Object.entries(ResponsiveWrapper.widths)
      .filter((entry) => entry[1] < windowWidth)
      .sort((entryA, entryB) => entryB[1] - entryA[1])[0][0]
  }catch(err){
  }
  const dimensions = {
    size: windowSize,
    width: windowWidth,
    height: windowHeight
  }

  Object.keys(ResponsiveWrapper.widths).forEach(label =>
    dimensions[label] = windowSize === label
  )
  ResponsiveWrapper.dimensions = dimensions
  const updateAll = () => resizeListeners.forEach(listener => listener(dimensions))
  updateAll()
  setTimeout(updateAll, 500)
}

window.addEventListener('resize', debounce(onWindowResize))

