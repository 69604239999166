import React, {  } from 'react'
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField'
import { connect } from 'react-redux'
import { CustomerActions } from '../../actionsets'
import CRUDForm from '../../containers/CRUDForm'
import { CustomerAPI } from '../../api'
import { RequestService } from '../../services'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { push } from 'connected-react-router'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import { roleCheck } from '../../utils'
import { ModelStatus } from '../../constants'


export class CustomerForm extends CRUDForm{

  static propTypes = {
    dispatch: PropTypes.func,
    match: PropTypes.object.isRequired,
    onSubmitted: PropTypes.func,
    onCancel: PropTypes.func,
  }

  static styles = {
    fields: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column',
    },
    actions: {
      float: 'right'
    }
  }

  constructor(props){
    super(props)
    this.state = {
      errors: {},
      submissionErrors: [],
      businessName: '',
      businessAddress: '',
      postalAddress: '',
      email: '',
      phone: '',
      additionalNotes: '',
      status: ModelStatus.default,
    }
    CustomerActions.bindActions(this)
  }


  loadCreateItem = () => {
    if(this.createMode && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
    }
  }

  loadEditItem = async () => {
    if(this.userId !== this.editId && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
      return
    }
    try{
      const { body: { data: { attributes: { businessName, businessAddress, postalAddress, email, phone, additionalNotes, status }}}} = await CustomerAPI.show(this.editId)(RequestService)
      this.setState({
        businessName, businessAddress, postalAddress, email, phone, additionalNotes, status
      })
    }catch(err){
      this.setState({ submissionErrors: [{ detail: `Couldn't find customer with id ${this.editId}`, source: { pointer:'' }}] })
    }
  }

  submit = async () => {
    if(!this.validate())
      return
    const { businessName, businessAddress, postalAddress, email, phone, additionalNotes, status } = {...this.state }

    const params = { businessName, businessAddress, postalAddress, email, phone, additionalNotes, status }


    let action
    if(this.editMode){
      action = this.actions.updateCustomer(this.editId, params)
    }else{
      action = this.actions.createCustomer(params)
    }

    try{
      await action
      this.props.onSubmitted && this.props.onSubmitted()
    }catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['businessName', 'businessAddress', 'status', 'postalAddress', 'email']
    if(this.createMode){
      requiredFields = requiredFields.concat(['phone'])
    }
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  get editId(){
    return this.props.editId || this.props.match.params.editId
  }

  get userId(){
    return this.props.user.id
  }

  get customerId(){
    return this.props.customer.id
  }

  render = () =>
    <div>
      <div style={CustomerForm.styles.fields}>
        {this.renderSubmissionErrors()}


        <TextField
          style={{width: '100%'}}
          floatingLabelText="Business Name"
          hintText="Business Name"
          value={this.state.businessName}
          onChange={this.onFormValueChange('businessName', this.nthValue(1))}
          errorText={this.state.errors.businessName}
        />

        <div style={{display: 'flex'}}>

          <TextField
            style={{flex: 1, marginRight: 10}}
            floatingLabelText="Business Address"
            hintText="Business Address"
            value={this.state.businessAddress}
            onChange={this.onFormValueChange('businessAddress', this.nthValue(1))}
            errorText={this.state.errors.businessAddress}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

          <TextField
            style={{flex: 1, marginLeft: 10}}
            floatingLabelText="Postal Address"
            hintText="Postal Address"
            value={this.state.postalAddress}
            onChange={this.onFormValueChange('postalAddress', this.nthValue(1))}
            errorText={this.state.errors.postalAddress}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

        </div>

        <div style={{display: 'flex'}}>

          <TextField
            style={{flex: 1, marginRight: 10}}
            floatingLabelText="Email"
            hintText="Email"
            value={this.state.email}
            onChange={this.onFormValueChange('email', this.nthValue(1))}
            errorText={this.state.errors.email}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

          <TextField
            style={{flex: 1, marginLeft: 10}}
            floatingLabelText="Phone"
            hintText="Phone"
            value={this.state.phone}
            onChange={this.onFormValueChange('phone', this.nthValue(1))}
            errorText={this.state.errors.phone}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

        </div>

        <div style={{display: 'flex'}}>

          <TextField
            style={{flex: 1, marginRight: 10}}
            floatingLabelText="Additional Notes"
            hintText="Additional Notes"
            value={this.state.additionalNotes || ''}
            onChange={this.onFormValueChange('additionalNotes', this.nthValue(1))}
            errorText={this.state.errors.additionalNotes}
            disabled={!roleCheck(this.props.role, 'admin')}
          />

          <SelectField
           style={{flex: 1, marginLeft: 10}}
           floatingLabelText="Status"
           value={this.state.status}
           onChange={this.onFormValueChange('status', this.nthValue(2))}
           errorText={this.state.errors.status}
           disabled={!roleCheck(this.props.role, 'admin')}
          >
            <MenuItem value='active' primaryText='Active' />
            <MenuItem value='archived' primaryText='Archived' />
          </SelectField>

        </div>

      </div>
      <div style={CustomerForm.styles.actions}>
        <FlatButton label="Cancel" style={{marginRight: 10}} onClick={this.props.onCancel} />
        <RaisedButton label='Save' primary={true} onClick={this.submit} />
      </div>
    </div>
}

export default connect(state => { return {...state.session} })(CustomerForm)
