import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color'

const styles = {
  color: {
    width: '100%',
    height: '100%',
    borderRadius: 2
  },
  swatch: {
    padding: 5,
    background: '#fff',
    borderRadius: 1,
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
    height: 20,
    width: 50
  },
  cover: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}

export class ColorPicker extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    color: PropTypes.object,
    style: PropTypes.object,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    popoverPosition: PropTypes.object
  }

  state = {
    displayColorPicker: false,
  }

  handleClick = () => {
    this.setState({displayColorPicker: !this.state.displayColorPicker})
  }

  handleClose = () => {
    this.setState({displayColorPicker: false})
  }

  get RGBAColor() {
    let color = this.props.color
    return color ? `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })` : 'rgba(255,255,255,1)'
  }

  render() {
    const {color, style, label, labelStyle, onChange, popoverPosition} = this.props
    return (
      <div>
        <div style={{...styles.swatch, ...style}} onClick={this.handleClick}>
          <div style={{...styles.color, background: this.RGBAColor, textAlign: 'center', lineHeight: '30px'}} >
            {label && <span style={labelStyle}> {label} </span>}
          </div>
        </div>
        { this.state.displayColorPicker ?
          <div style={ {position: 'absolute', zIndex: 2, ...popoverPosition} }>
            <div style={ styles.cover } onClick={ this.handleClose }/>
            <SketchPicker color={ color } onChange={ onChange } />
          </div>
            :
          null
        }
      </div>
    )
  }
}

export default ColorPicker