import ActionSet from './ActionSet'
import { ProjectUserAPI } from '../api'
import { SnackBarActions } from '.'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'

export class ProjectUserActionSet extends ActionSet{

  static initialState = {
    projectUsers: [],
    requestingProjectUsers: false
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static loadProjectUsers(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => {
      return dispatch =>
        dispatch({
          type: this.LOAD_PROJECT_USERS,
          api: ProjectUserAPI.index()
        })
    })

    reducer({
      [this.LOAD_PROJECT_USERS_REQUEST]: (state) => {
        return {...state, requestingProjectUsers: true}
      },
      [this.LOAD_PROJECT_USERS_FAILURE]: (state) => {
        return {...state, requestingProjectUsers: false}
      },
      [this.LOAD_PROJECT_USERS_SUCCESS] : (state, { result: { body: { data: projectUsers } } } ) => {
        return {...state, projectUsers, requestingProjectUsers: false}
      }
    })
  }

  static createProjectUser(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(attributes => {
      return async dispatch => {
        await dispatch({
          type: this.CREATE_PROJECT_USER,
          api: ProjectUserAPI.create(attributes)
        })
        await dispatch(SnackBarActions.alert('User assigned to project'))
      }
    })

    reducer({
      [this.CREATE_PROJECT_USER]: (state) => {
        return state
      }
    })
  }

  static updateProjectUser(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((id, attributes) => {
      return async dispatch => {
        await dispatch({
          type: this.UPDATE_PROJECT_USER,
          api: ProjectUserAPI.update(id, attributes)
        })
        await dispatch(SnackBarActions.alert('Assignment updated'))
      }
    })

    reducer({
      [this.UPDATE_PROJECT_USER]: (state) => {
        return state
      }
    })
  }

  static deleteProjectUser(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(id => {
      return {
        type: this.DELETE_PROJECT_USER,
        api: ProjectUserAPI.delete(id)
      }
    })

    reducer({
      [this.DELETE_PROJECT_USER]: (state) => {
        return state
      }
    })
  }

  static searchProjectUsers(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(query => {
      return dispatch =>
        dispatch({
          type: this.SEARCH_PROJECT_USERS,
          api: ProjectUserAPI.search(query)
        })
    })

    reducer({
      [this.SEARCH_PROJECT_USERS_REQUEST]: (state) => {
        return {...state, requestingProjectUsers: true}
      },
      [this.SEARCH_PROJECT_USERS_FAILURE]: (state) => {
        return {...state, requestingProjectUsers: false}
      },
      [this.SEARCH_PROJECT_USERS_SUCCESS]: (state, { result: { body: { data: projectUsers } } } ) => {
        return {...state, projectUsers, requestingProjectUsers: false}
      },
    })
  }
}

export default new ProjectUserActionSet()
