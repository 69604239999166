import React, { Component } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'
import {Toolbar, ToolbarGroup,ToolbarTitle} from 'material-ui/Toolbar'
import {Card } from 'material-ui/Card'
import Paper from 'material-ui/Paper'
import { TimesheetEntryActions, ProjectActions, CustomerActions, UserActions, SnackBarActions } from '../../actionsets'
import { TimesheetFilter, InvoiceForm } from '.'
import RaisedButton from 'material-ui/RaisedButton'
import Dialog from '../Dialog'
import { ModelStatus } from '../../constants'
import { RequestService } from '../../services'
import { downloadContent } from '../../utils'
import { TimesheetEntryAPI } from '../../api'
import { ListView, ListViewColumn } from '../../components'
import { ItemRenderers, ItemSorters } from '../../components/List'
import { connect } from "react-redux"


export class TimesheetInvoicing extends Component{

  static propTypes = {
    dispatch: PropTypes.func,
    route: PropTypes.object,
    timesheetEntries: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
  }

  constructor(props){
    super(props)

    TimesheetEntryActions.bindActions(this)
    ProjectActions.bindActions(this)
    CustomerActions.bindActions(this)
    UserActions.bindActions(this)
    SnackBarActions.bindActions(this)

    this.state = {
      selected: [],
      dialog: false
    }

  }

  componentWillMount = () => {
    this.actions.searchUsers({status: ModelStatus.active})
    this.actions.searchProjects({status: ModelStatus.active})
    this.actions.searchCustomers({status: ModelStatus.active})
  }

  loadTimesheetEntries = filterParams => {
    this.actions.searchTimesheetEntries(filterParams)
  }

  downloadTimesheetEntriesCSV = async filterParams => {
    try {
      this.actions.alert('Downloading as CSV..')
      let csv = await TimesheetEntryAPI.csv(filterParams)(RequestService)
      let filename = `timesheet_entries_${moment(filterParams.startDate).format('MMMM_DD')}_${moment(filterParams.endDate).format('MMMM_DD_YYYY')}.csv`
      downloadContent(csv.text, filename)
    }

    catch(e) {
      this.actions.alert('The file could not be downloaded')
    }
  }

  onRowClick = entry => {
    let entries = this.state.selected
    if (!entries.includes(entry)) {
      entries.push(entry)
    }
    this.setState({selected: entries, dialog: true})
  }

  onSelectionChange = change => {
    switch(change) {
    case 'all':
      this.setState({selected: this.props.timesheetEntries})
      break
    case 'none':
      this.setState({selected: []})
      break
    default:
      this.setState({selected: change})
    }
  }


  render = () =>
    <Paper style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Toolbar style={{minHeight: 50}}>
        <ToolbarGroup>
          <ToolbarTitle text="Invoicing" />
        </ToolbarGroup>
      </Toolbar>

      <Card style={{padding: 10}}>

        <TimesheetFilter
          onChange={this.loadTimesheetEntries}
          onDownload={this.downloadTimesheetEntriesCSV}
          users={this.props.users}
          projects={this.props.projects}
          customers={this.props.customers}
          mode='invoicing'
        />

        <Dialog
          title={`Update Invoice Number of ${this.state.selected.length} selected`}
          open={this.state.dialog}
          modal
          autoScrollBodyContent
        >

          <InvoiceForm
            entries={this.state.selected}
            onSubmitted={() => this.setState({dialog: false})}
            onCancel={() => this.setState({dialog: false})}
          />

        </Dialog>

        <Card style={{marginTop: 20, paddingTop: 10}}>
          <RaisedButton style={{float: 'right', marginLeft: 20, marginRight: 10}} label='Update Selected' primary disabled={this.state.selected.length === 0} onClick={() => this.setState({dialog: true})} />

          <ListView
            pagination={{pageSize: 20}}
            items={this.props.timesheetEntries}
            isRowSelectedTest={item => this.state.selected.includes(item)}
            defaultSortBy='date'
            defaultSortOrder='asc'
            onRowClick={this.onRowClick}
            onSelectionChanged={this.onSelectionChange}
            sortable
            filtering
            enableSelectAll
          >

            <ListViewColumn name='Date' attribute='date' itemSorter={ItemSorters.plainItemRenderer}/>
            <ListViewColumn name='Project' attribute='projectName' itemRenderer={ItemRenderers.titleizedItemRenderer} />
            <ListViewColumn name='User' attribute='userName' itemRenderer={ItemRenderers.titleizedItemRenderer} />
            <ListViewColumn name='Task' attribute='projectTaskName' itemRenderer={ItemRenderers.titleizedItemRenderer} />
            <ListViewColumn name='Time' attribute='time' itemSorter={ItemSorters.plainItemRenderer}/>
            <ListViewColumn name='Invoice No' attribute='invoiceNo' itemRenderer={ItemRenderers.stringPlainItemSorter} />

          </ListView>
        </Card>
      </Card>
    </Paper>
}

export default connect(({session, timesheetEntry, user, projects, customers}) => { return {...timesheetEntry, ...user, ...projects, ...customers, ...session}})(TimesheetInvoicing)

