import React, { Component } from 'react'
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField'
import DatePicker from 'material-ui/DatePicker'
import MenuItem from 'material-ui/MenuItem'
import Checkbox from 'material-ui/Checkbox'
import FontAwesome from 'react-fontawesome'
import {Toolbar, ToolbarGroup,ToolbarTitle} from 'material-ui/Toolbar'
import moment from 'moment'
import { MultiSelect } from '../../components'
import { titleize } from '../../utils'

export class TimesheetFilter extends Component{

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    users: PropTypes.array,
    customers: PropTypes.array,
    projects: PropTypes.array,
    user_id: PropTypes.array,
    customer_id: PropTypes.array,
    project_id: PropTypes.array,
  }

  static styles = {
    selectField: {
      marginRight: 10,
      flex: 1,
    },
    dateField: {
      marginRight: 10,
      flex: 1,
    },
    datePickerLabel: {
      width: '100%',
    },
    groupField: {
      width: 140,
      padding: 10,
      backgroundColor: '#3949ab',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
      borderRadius: '2px',
    },
    toolbarSelectContainer: {
      flex: '1 1 0'
    },
    download: {
      marginTop: 20,
      marginRight: 10,
      float: 'right',
      cursor: 'pointer',
      right: 10,
    },
    whiteFont: {
      color: '#fff'
    }
  }

  constructor(props){
    super(props)
    this.state = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      groupBy: 'user',
      user_id: props.user_id || '*',
      project_id: props.user_id || '*',
      customer_id: props.user_id || '*',
      summary: false
    }
  }

  componentWillMount = () => {
    this.props.onChange(this.filterToQueryParams(this.state))
  }

  toQueryParam = item => {
    if (moment.isMoment(item)) {
      return item.format('YYYY-MM-DD')
    }
    if (item === '*') {
      return undefined
    }
    if (item instanceof Array && item.length === 0) {
      return ['']
    }
    return item
  }

  filterToQueryParams = params => {
    let queryParams = {}
    Object.keys(params).forEach(key => queryParams[key] = this.toQueryParam(params[key]))
    return queryParams
  }

  onFilterChange = (params) => {
    let filterParams = {...this.state, ...params}
    this.setState(filterParams)
    this.props.onChange(this.filterToQueryParams(filterParams))
  }

  get selectedMonth() {
    const { startDate, endDate } = this.state
    return startDate.month() === endDate.month() ? startDate.month() : ''
  }

  get filterToText() {
    const { startDate, endDate } = this.state
    let dateText = ''
    if (moment(startDate).startOf('month').isSame(startDate, 'day') && moment(startDate).endOf('month').isSame(endDate, 'day')) {
      dateText = `${startDate.format('MMMM YYYY')}`
    } else {
      dateText = `${startDate.format('MMMM Do')} to ${endDate.format('MMMM Do')}`
    }
    return `Time for ${dateText}`
  }

  renderMultiSelect = (model, att) => {
    let options = this.props[`${model}s`].sort((a, b) => (a.attributes[att] > b.attributes[att]) ? 1 : -1)
    let fieldName = `${model}_id`
    let label = titleize(model) + 's'

    return (
      <MultiSelect
        style={TimesheetFilter.styles.selectField}
        floatingLabelText={label}
        hintText={label}
        value='*'
        onChange={val => this.onFilterChange({[fieldName]: val})}
        options={options}
        renderOptionText={optn => optn.attributes[att]}
      />
    )
  }

  renderMonthSelect = (fontColor) => (
    <SelectField
      style={TimesheetFilter.styles.toolbarSelectContainer}
      labelStyle={fontColor}
      floatingLabelStyle={fontColor}
      floatingLabelText="Month"
      value={this.selectedMonth}
      onChange={(event, index, val) => this.onFilterChange({startDate: moment().month(val).startOf('month'), endDate: moment().month(val).endOf('month')})}
    >
      <MenuItem value='' primaryText=''/>
      {moment.months().map((month, index) => <MenuItem key={index} value={index} primaryText={month} />)}
    </SelectField>
  )

  renderGroupSelect = () => (
    <SelectField
      style={TimesheetFilter.toolbarSelectContainer}
      labelStyle={TimesheetFilter.styles.whiteFont}
      floatingLabelStyle={TimesheetFilter.styles.whiteFont}
      floatingLabelText='Group By'
      value={this.state.groupBy}
      onChange={(event, index, val) => this.onFilterChange({groupBy: val})}
    >
      <MenuItem value='user' primaryText='User'/>
      <MenuItem value='project' primaryText='Project'/>
      <MenuItem value='week' primaryText='Week'/>
      <MenuItem value='task' primaryText='Task'/>
    </SelectField>
  )

  renderToolBar = mode => (
    <Toolbar style={{height: 80}}>
      <ToolbarGroup style={{paddingLeft: 20}} firstChild>
        <ToolbarTitle text={this.filterToText} />
      </ToolbarGroup>
      {['reporting', 'approval'].includes(mode) &&
      <ToolbarGroup style={TimesheetFilter.styles.groupField} lastChild>
        {mode === 'reporting' && this.renderGroupSelect() }
        {mode === 'approval' && this.renderMonthSelect(TimesheetFilter.styles.whiteFont) }
      </ToolbarGroup> }
    </Toolbar>
  )

  render = () => {
    const { startDate, endDate } = this.state
    const { users, projects, customers, mode, onDownload } = this.props

    return (
      <div>

      { ['reporting', 'invoicing'].includes(mode) &&
        <div style={{padding: 10}}>

          <div style={{display: 'flex'}}>
            <div style={{width: '100%'}}>
              <span style={{...TimesheetFilter.styles.download, marginTop: '0px', color: '#337ab7'}} onClick={() => onDownload(this.filterToQueryParams(this.state))}>
                Download as CSV
                <FontAwesome title="download" name='cloud-download' fixedWidth style={{fontSize: 25, marginLeft: 5}} />
              </span>
              {!['week'].includes(this.state.groupBy) && <Checkbox
                label={'Summary'}
                checked={this.state.summary}
                onCheck={(event, checked) => this.setState({summary: checked})}
                style={{float: 'right', width: 'auto', whiteSpace: 'nowrap', marginTop: '10px', marginRight: '15px', marginBottom: '-20px', clear: 'both'}}
              />}
            </div>
          </div>

          <div style={{display: 'flex'}}>

            {this.renderMonthSelect()}

            <DatePicker
              style={TimesheetFilter.styles.dateField}
              floatingLabelText='From'
              hintText='From'
              value={startDate.toDate()}
              onChange={(event, date) => this.onFilterChange({startDate: moment(date)})}
              autoOk
              mode='landscape'
              container='inline'
              textFieldStyle={TimesheetFilter.styles.datePickerLabel}
              formatDate={date => moment(date).format('LL')}
            />

            <DatePicker
              style={TimesheetFilter.styles.dateField}
              floatingLabelText='To'
              hintText='To'
              value={endDate.toDate()}
              onChange={(event, date) => this.onFilterChange({endDate: moment(date)})}
              autoOk
              mode='landscape'
              container='inline'
              textFieldStyle={TimesheetFilter.styles.datePickerLabel}
              formatDate={date => moment(date).format('LL')}
            />

          </div>

          <div style={{display: 'flex'}}>

            {users && this.renderMultiSelect('user', 'name')}
            {projects && this.renderMultiSelect('project', 'name')}
            {customers && this.renderMultiSelect('customer', 'businessName')}

          </div>

        </div>
      }

      {this.renderToolBar(mode)}

      </div>
    )

  }

}

export default TimesheetFilter