class ProjectUserAPI{
  static create = attributes => authenticated => authenticated.post('api/project_users', {
    data: {
      type: 'project_users',
      attributes
    }
  })

  static update = (id, attributes) => authenticated => authenticated.put(`api/project_users/${id}`, {
    data: {
      type: 'project_users',
      attributes
    }
  })

  static search = (attributes) => authenticated => authenticated.post('api/project_users/search', {
    data: {
      type: 'project_users',
      attributes
    }
  })

  static index = () => authenticated => authenticated.get('api/project_users')
  static show = id => authenticated => authenticated.get(`api/project_users/${id}`)
  static delete = id => authenticated => authenticated.delete(`api/project_users/${id}`)
}

export default ProjectUserAPI
