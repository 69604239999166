import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog'
import { Themed } from '../../containers/VitalstatistixThemeProvider'

export class CardDialog extends Component{

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    primaryColor: PropTypes.string,
    title: PropTypes.string,
    actions: PropTypes.array,
    modal: PropTypes.bool,
    open: PropTypes.bool
  }

  static defaultProps = {
    modal: true
  }

  render = () =>
     <Dialog
      title={this.props.title}
      actions={this.props.actions}
      modal={this.props.modal}
      contentStyle={{width: 350, display: 'flex', borderRadius: 15, minHeight: 300, overflow: 'hidden', boxShadow: '0px 10px 44px -2px rgba(0,0,0,0.40)'}}
      titleStyle={{backgroundColor: this.props.primaryColor, color: 'white', padding: 10, fontSize: 20}}
      bodyStyle={{margin: '15px 0', borderRadius: '0 0 15px 15px', overflow: 'hidden', width: 350}}
      actionsContainerStyle={{position: 'absolute', bottom: 0, padding: 0, background: '#ECEFF7'}}
      style={{marginTop: 15}}
      open={this.props.open}
    >
      {this.props.children}
    </Dialog>

}

export default Themed(CardDialog)