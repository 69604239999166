import moment from 'moment'
import React from 'react'
import { titleize } from '../../utils'

export function plainItemRenderer(item, { attribute}){
  return `${item[attribute]}`
}

export function plainItemRendererNullSafe(item, { attribute }){
  return item[attribute] == null ? <span style={{ color: 'grey' }}>NA</span> : `${item[attribute]}`
}

export function titleizedItemRenderer({ attributes }, { attribute }){
  return titleize(attributes[attribute])
}

export function htmlSafeRenderer(item, { attribute }){
  return <span dangerouslySetInnerHTML={{__html: item.attributes[attribute] }}/>
}

export function JSONAPIAttributeRenderer({attributes}, { attribute}){
  return attributes[attribute]
}

export function MomentRenderer(format){
  return ({attributes}, { attribute}) => {
    return moment(attributes[attribute]).format(format)
  }
}
