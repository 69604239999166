import ActionSet from './ActionSet'
import { UserAPI } from '../api'
import { SnackBarActions } from '.'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'

export class UserActionSet extends ActionSet{

  static initialState = {
    users: [],
    requestingUsers: false
  }


  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static loadUsers(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => {
      return dispatch =>
        dispatch({
          type: this.LOAD_USERS,
          api: UserAPI.index()
        })
    })

    reducer({
      [this.LOAD_USERS_REQUEST]: (state) => {
        return {...state, requestingUsers: true}
      },
      [this.LOAD_USERS_FAILURE]: (state) => {
        return {...state, requestingUsers: false}
      },
      [this.LOAD_USERS_SUCCESS]: (state, { result: { body: { data: users } } } ) => {
        return {...state, users, requestingUsers: false }
      },
    })
  }

  static createUser(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(attributes => {
      return async dispatch => {
        await dispatch({
          type: this.CREATE_USER,
          api: UserAPI.create(attributes)
        })
        await dispatch(SnackBarActions.alert('User created'))
      }
    })

    reducer({
      [this.CREATE_USER]: (state) => {
        return state
      }
    })
  }

  static updateUser(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((id, attributes) => {
      return async dispatch => {
        await dispatch({
          type: this.UPDATE_USER,
          api: UserAPI.update(id, attributes)
        })
        await dispatch(SnackBarActions.alert('User updated'))
      }
    })

    reducer({
      [this.UPDATE_USER]: (state) => {
        return state
      }
    })
  }

  static deleteUser(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((id) => {
      return async dispatch => {
        try {
          await dispatch({
            type: this.DELETE_USER,
            api: UserAPI.delete(id)
          })
          await dispatch(this.loadUsers())
        }catch(err){
          const { body: { errors } } = err
          dispatch(SnackBarActions.alert(errors.map(({detail}) => detail).join(', ')))
        }
      }
    })

    reducer({
      [this.DELETE_USER] : (state) => {
        return state
      }
    })
  }

  static searchUsers(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((query, order) => {
      return {
        type: this.SEARCH_USERS,
        api: UserAPI.search({...query, order})
      }
    })

    reducer({
      [this.SEARCH_USERS_REQUEST]: (state) => {
        return {...state, requestingUsers: true}
      },
      [this.SEARCH_USERS_FAILURE]: (state) => {
        return {...state, requestingUsers: false}
      },
      [this.SEARCH_USERS_SUCCESS]: (state, {result: {body: {data: users}}}) => {
        return {...state, users, requestingUsers: false}
      }
    })
  }

  static resendInvitation(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(id => {
      return async dispatch => {
        await dispatch({
          type: this.RESEND_INVITATION,
          api: UserAPI.resendInvitation(id)
        })
        await dispatch(SnackBarActions.alert('Invitation resent'))
      }
    })

    reducer({
      [this.RESEND_INVITATION]: (state) => {
        return state
      }
    })
  }

}

export default new UserActionSet()