import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import Responsive from '../Responsive'

export class HorizontalCardLayout extends Component {

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    dimensions: PropTypes.object
  }

  static defaultProps = {
    dimensions: {}
  }

  static styles = {
    mobile: {
      display: 'flex',           /* establish flex container */
      flexDirection: 'row',  /* make main axis vertical */
      flex: 1
    },
    desktop: {
      flex: 1,
      display: 'flex',           /* establish flex container */
      flexDirection: 'row',  /* make main axis vertical */
      justifyContent: 'flex-start', /* center items vertically, in this case */
      alignItems: 'stretch',     /* center items horizontally, in this case */
      flexBasis: 'content',
      flexWrap: 'nowrap',
      background: '#fafafa',
      overflowY: 'scroll',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '40px',
      paddingBottom: '40px'
    }
  }

  get wrapperStyle(){
    return this.props.dimensions.narrow ?
      HorizontalCardLayout.styles.mobile :
      HorizontalCardLayout.styles.desktop
  }

  render = () =>
    <div style={this.wrapperStyle}>
      {this.props.children}
    </div>
}

export default Responsive(HorizontalCardLayout)
