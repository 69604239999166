import ActionSet from './ActionSet'

export class SnackBarActionSet extends ActionSet{

  static initialState = {
    message: '',
    loadingMessage: '',
    loading: false
  }

  static startLoad(creator, reducer){
    creator((loadingMessage='Loading') => {
      return {
        type: this.START_LOAD,
        payload: loadingMessage
      }
    })

    reducer({
      [this.START_LOAD]: (state, loadingMessage) => {
        return {...state, loadingMessage, loading: true }
      }
    })
  }

  static stopLoad(creator, reducer){
    creator(() => {
      return {
        type: this.STOP_LOAD
      }
    })

    reducer({
      [this.STOP_LOAD]: (state) => {
        return {...state, loadingMessage: '', loading: false }
      }
    })
  }

  static alert(creator, reducer){
    creator(message => {
      return {
        type: this.ALERT,
        payload: message
      }
    })

    reducer({
      [this.ALERT]: (state, message) => {
        return {...state, message }
      }
    })
  }

  static clearSnackbar(creator, reducer){
    creator(message => {
      return {
        type: this.CLEAR_SNACKBAR,
        payload: message
      }
    })

    reducer({
      [this.CLEAR_SNACKBAR]: (state) => {
        return {...state, message: '' }
      }
    })
  }
}

export default new SnackBarActionSet()