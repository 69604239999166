import { push } from 'connected-react-router'
import requestServiceConstantsMiddleware from '../middleware/requestServiceConstantsMiddleware'
import ActionSet from './ActionSet'
import { SnackBarActions } from '.'
import { OAuthAPI, ServiceAPI } from '../api'
import { OAuthStore } from '../services'

const DENIED = 'denied'

export class OAuthActionSet extends ActionSet {

  static initialState = {
    services: {},
    errors: {},
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  /*
  ** Get any OAuth services from token, store them in state
  */
  static getServices(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => async dispatch => {
      try {
        const { body: { services }} = await dispatch({
          type: this.GET_SERVICES,
          api: OAuthAPI.getServices()
        })
        return services
      }
      catch(err) {
        const { body: { errors }} = err
        dispatch(SnackBarActions.alert(errors.map(({ title }) => title).join(', ')))
      }
    })

    reducer({
      [this.GET_SERVICES_SUCCESS]: (state, { result: { body: { services } } }) => ({
        ...state,
        services
      }),
    })
  }

  /*
  ** Begin OAuth dance for service, store token and redirect to service for
  ** authorization
  */
  static authenticateService(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(service => async dispatch => {
      try {
        const { body: { authorize_url, request_token } } = await dispatch({
          type: this.AUTHENTICATE_SERVICE,
          api: OAuthAPI.authenticateService(service),
          payload: service,
        })
        OAuthStore.set(service, request_token)

        window.location = authorize_url
      }
      catch(err) {
        const { body: { errors } } = err
        dispatch(push('/oauth/services'))
        dispatch(SnackBarActions.alert(errors.map(({ title }) => title).join(',')))
      }
    })

    reducer({
      [this.AUTHENTICATE_SERVICE_REQUEST]: (state, { requestPayload: service }) => {
        return ({
          ...state,
          services: {
            ...state.services,
            [service]: {
              attributes: {
                ...state.services[service].attributes,
                requestPending: true
              }
            }
          }
        })
      },
      [this.AUTHENTICATE_SERVICE_FAILURE]: (state, { requestPayload: service }) => ({
        ...state,
        services: {
          ...state.services,
          [service]: {
            attributes: {
              ...state.services[service].attributes,
              requestPending: false
            }
          }
        }
      })
    })
  }

  /*
  ** Verify given OAuth service type
  ** Pass on OAuth params to API to get access token
  */
  static verifyService(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((service, oauthVerifier) => async dispatch => {
      try {
        if (oauthVerifier === DENIED) {
          OAuthStore.remove(service)
          await dispatch(SnackBarActions.alert(`Authentication with ${service} was denied`))
        }
        else {
          const requestToken = OAuthStore.get(service)
          const { body: { message } } = await dispatch({
            type: this.VERIFY_SERVICE,
            api: OAuthAPI.verifyService({ oauthVerifier, requestToken, service }),
            payload: service,
          })
          OAuthStore.remove(service)
          await dispatch(SnackBarActions.alert(message))
        }
      }
      catch(err) {
        const { body: { errors } } = err
        dispatch(SnackBarActions.alert(errors.map(({title}) => title).join(', ')))
      }
      finally {
        dispatch(push('/oauth/services'))
      }
    })

    reducer({
      [this.VERIFY_SERVICE_REQUEST]: (state, { requestPayload: service }) => ({
        ...state,
        services: {
          ...state.services,
          [service]: {
            attributes: {
              ...state.services[service].attributes,
              requestPending: true
            }
          }
        }
      }),
      [this.VERIFY_SERVICE_SUCCESS]: (state, { result: { body: { services } } }) => ({
        ...state,
        services
      }),
      [this.VERIFY_SERVICE_FAILURE]: (state, { error: { body: { errors }}, requestPayload: service}) => ({
        ...state,
        errors: {
          [service]: errors
        },
        services: {
          ...state.services,
          [service]: {
            attributes: {
              ...state.services[service].attributes,
              requestPending: false
            }
          }
        }
      })
    })
  }

  static deactivateService(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(service => async dispatch => {
      try {
        const { body: { message } } = await dispatch({
          type: this.DEACTIVATE_SERVICE,
          api: OAuthAPI.deactivateService(service),
          payload: service,
        })
        await dispatch(SnackBarActions.alert(message))
      }
      catch(err) {
        const { body: { errors } } = err
        dispatch(SnackBarActions.alert(errors.map(({title}) => title).join(', ')))
      }
    })

    reducer({
      [this.DEACTIVATE_SERVICE_REQUEST]: (state, { requestPayload: service }) => ({
        ...state,
        services: {
          ...state.services,
          [service]: {
            attributes: {
              ...state.services[service].attributes,
              requestPending: true
            }
          }
        }
      }),
      [this.DEACTIVATE_SERVICE_SUCCESS]: (state, { result: { body: { services }}}) => ({
        ...state,
        services
      }),
      [this.DEACTIVATE_SERVICE_FAILURE]: (state, { requestPayload: service }) => ({
        ...state,
        services: {
          ...state.services,
          [service]: {
            attributes: {
              ...state.services[service].attributes,
              requestPending: false
            }
          }
        }
      }),
    })
  }

  /*
  ** Fetches tasks from service, creates new if don't already exist.
  ** Returns state of OAuth services
  */
  static syncService(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(service => async dispatch => {
      try {
        const { body: { message } } = await dispatch({
          type: this.SYNC_SERVICE,
          api: ServiceAPI.syncService(service),
          payload: service
        })
        await dispatch(SnackBarActions.alert(message))
      }
      catch ({ body: { errors }}) {
        dispatch(SnackBarActions.alert(errors.map(({ title }) => title).join(', ')))
      }
    })
    reducer({
      [this.SYNC_SERVICE_REQUEST]: (state, { requestPayload: service }) => ({
        ...state,
        services: {
          ...state.services,
          [service]: {
            attributes: {
              ...state.services[service].attributes,
              requestPending: true
            }
          }
        }
      }),
      [this.SYNC_SERVICE_SUCCESS]: (state, { result: { body: { services }}}) => ({
        ...state,
        services
      }),
      [this.SYNC_SERVICE_FAILURE]: (state, { requestPayload: service }) => ({
        ...state,
        services: {
          ...state.services,
          [service]: {
            attributes: {
              ...state.services[service].attributes,
              requestPending: false
            }
          }
        }
      }),
    })
  }


  /*
  ** Import tasks from file, creates new if don't already exist.
  ** Returns state of OAuth services
  */
  static importService(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((service, data) => async dispatch => {
      try {
        const { body: { message } } = await dispatch({
          type: this.IMPORT_SERVICE,
          api: ServiceAPI.importService(data),
          payload: service
        })
        await dispatch(SnackBarActions.alert(message))
      }
      catch ({ body: { errors }}) {
        dispatch(SnackBarActions.alert(errors.map(({ title }) => title).join(', ')))
      }
    })
    reducer({
      [this.IMPORT_SERVICE_REQUEST]: (state, { requestPayload: service }) => ({
        ...state,
        services: {
          ...state.services,
          [service]: {
            attributes: {
              ...state.services[service].attributes,
              requestPending: true
            }
          }
        }
      }),
      [this.IMPORT_SERVICE_SUCCESS]: (state, { result: { body: { services }}}) => ({
        ...state,
        services
      }),
      [this.IMPORT_SERVICE_FAILURE]: (state, { requestPayload: service }) => ({
        ...state,
        services: {
          ...state.services,
          [service]: {
            attributes: {
              ...state.services[service].attributes,
              requestPending: false
            }
          }
        }
      }),
    })
  }

}

export default new OAuthActionSet()
