import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import AppBar from 'material-ui/AppBar'
import IconMenu from 'material-ui/IconMenu'
import IconButton from 'material-ui/IconButton'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import MenuItem from 'material-ui/MenuItem'
import { connect } from 'react-redux'
import Drawer from 'material-ui/Drawer'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import Divider from 'material-ui/Divider'
import { isManagerOrAdmin } from '../utils'
import { UserRestrictedView, TaskTimer } from '../containers'
import { MenuLink, TimerDisplay } from '../components'
import { SessionActions } from '../actionsets'
import { RequestService, TimerStore } from '../services'
import { Routes } from './Routes'
import { SessionStatus } from "../constants"
import { push } from 'connected-react-router'

const TIMER_OPEN_KEY = 'open'

export class App extends Component {

  static propTypes = {
    time: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    user: PropTypes.object,
  }

  constructor(props){
    super(props)
    SessionActions.bindActions(this)
    this.state = {
      drawerOpen: false,
      timerOpen: !!TimerStore.get(TIMER_OPEN_KEY),
    }
  }

  closeMenu = () => this.setState({ drawerOpen: false})

  toggleTimer = () => {
    TimerStore.set(TIMER_OPEN_KEY, !this.state.timerOpen)
    this.setState({ timerOpen: !this.state.timerOpen })
  }

  get adminUser(){
    try{
      return this.props.user.attributes.role === 'admin'
    }catch(err){
      return false
    }
  }

  styles = {
    logo: {
      width: "auto",
      height: "36px",
      verticalAlign: 'middle',
      marginTop: '-4px'
    },
    title: {
      marginLeft: '12px'
    }
  }

  render = () => {
    // We need to check if the token is set due to the race condition that is triggered by
    // sessionStatus in the redux state being updated before the token is set in the localStorage
    if (this.props.sessionStatus !== SessionStatus.AUTHENTICATED || !RequestService.token) {
      return <Routes />
    }

    return (
      <div className='App'>
        <AppBar
            title={<>
              <img src="/logo_white.png" alt="YouDo logo" style={this.styles.logo}/>
              <span style={this.styles.title}>Vitalstatistix time keeping</span>
            </>}
            onLeftIconButtonClick={() => this.setState({drawerOpen: true})}
            iconElementRight={
              <IconMenu iconButtonElement={<IconButton><MoreVertIcon /></IconButton>}>
                <MenuItem disabled>{this.props.user.attributes && this.props.user.attributes.email}</MenuItem>
                <MenuItem value='0'
                          primaryText='My Details'
                          onClick={() => this.props.dispatch(push(`/users/edit/${this.props.user.id}`))} />
                <MenuItem value='0'
                          primaryText='Sign out'
                          onClick={this.actions.signOut}/>
              </IconMenu>
            }
        >

          <TimerDisplay
              time={this.props.time}
              open={this.state.timerOpen}
              status={this.props.status}
              onClick={this.toggleTimer}
          />

        </AppBar>

        <Drawer docked={false} open={this.state.drawerOpen} onRequestChange={(drawerOpen) => this.setState({drawerOpen})}>
          <AppBar
              title={<span>Menu</span>}
              iconElementLeft={<IconButton onClick={this.closeMenu}><NavigationClose /></IconButton>}
          />

          <MenuLink name='Time Entry' link='/time' icon='clock-o' onMenuClick={this.closeMenu} />
          <MenuLink name='Reporting' link='/time/report' icon='pie-chart' onMenuClick={this.closeMenu} />

          <UserRestrictedView component={MenuLink} name='Approval' link='/time/approve' icon='thumbs-up' permit={p => isManagerOrAdmin(p.user)} onMenuClick={this.closeMenu}/>
          <Divider />

          <UserRestrictedView component={MenuLink} name='Users' link='/users' icon='users' requiredRole='admin' onMenuClick={this.closeMenu}/>
          <UserRestrictedView component={MenuLink} name='Customers' link='/customers' icon='address-card' requiredRole='admin' onMenuClick={this.closeMenu}/>
          <UserRestrictedView component={MenuLink} name='Projects' link='/projects' icon='briefcase' requiredRole='admin' onMenuClick={this.closeMenu}/>
          <UserRestrictedView component={MenuLink} name='Invoicing' link='/time/invoice' icon='book' requiredRole='admin' onMenuClick={this.closeMenu}/>
          <UserRestrictedView component={Divider} includeProps={[]} requiredRole='admin' />

          <MenuLink name='Authenticated' link='/oauth/services' icon='cog' onMenuClick={this.closeMenu} />

        </Drawer>

        <TaskTimer open={this.state.timerOpen} onClose={this.toggleTimer} />

        <Routes />
      </div>
      )
    }
}

export default connect(({session, timer, router: { location }}) => ({...session, ...timer, location }))(App)
