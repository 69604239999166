import React, { Component } from 'react'
import PropTypes from 'prop-types';

import RaisedButton from 'material-ui/RaisedButton';
import { FilterTypes } from '../constants'
import { connect } from 'react-redux'

export class FilterBar extends Component {

  static propTypes = {
    filter: PropTypes.oneOf(Object.keys(FilterTypes)).isRequired,
    onFilterChange: PropTypes.func.isRequired
  }

  formatFilterName = (filter) => {
    let [ head, ...tail ] = filter.toLowerCase().split('')
    return [head.toUpperCase(), ...tail].join('')
  }

  renderFilter = (filter, name) =>
    <RaisedButton label={name} key={name} primary={filter === this.props.filter} style={{float: 'right'}} onClick={this.props.onFilterChange.bind(null, filter)} />

  renderFilters = (filters) => filters.map( filter => this.renderFilter(filter, this.formatFilterName(filter)) )

  render = () =>
    <div>
      { this.renderFilters(Object.keys(FilterTypes)) }
    </div>
}

export default connect(({session}) => session)(FilterBar)
