import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { UserForm } from '.'
import { UserRestrictedView } from '../'
import {Toolbar, ToolbarGroup,ToolbarTitle} from 'material-ui/Toolbar'
import {Card } from 'material-ui/Card'
import Paper from 'material-ui/Paper'
import { connect } from "react-redux"

export class UserFormWrapper extends Component{

  static propTypes = {
    user: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
  }

  get loaded(){
    return this.props.user.id
  }

  render = () =>
    <Paper style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Toolbar style={{minHeight: 50}}>
        <ToolbarGroup>
          <ToolbarTitle text="User" />
        </ToolbarGroup>
      </Toolbar>
      <Card style={{padding: 10}}>
        { this.loaded && <UserRestrictedView component={UserForm} {...this.props}/>}
      </Card>
    </Paper>
}

export default connect(state => { return { ...state.session} })(UserFormWrapper)