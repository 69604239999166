import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import { CenteredCard } from '../components'
import { Card, CardText, CardActions } from 'material-ui/Card';
import AppBar from 'material-ui/AppBar'
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { SessionActions } from '../actionsets'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import FlatButton from 'material-ui/FlatButton';

export class ForgotPassword extends Component {

  static propTypes = {
    errors: PropTypes.object.isRequired
  }

  constructor(props){
    super(props)
    this.state = {
      email: '',
      error: ''
    }
    SessionActions.bindActions(this)
  }

  setEmail    = (scope, email) => this.setState({email, error: ''})

  sendReminder = () => {
    if(!this.state.email) return this.setState({error: 'Email is blank'})
    this.actions.requestPasswordReset(this.state.email)
  }

  keyPressed = ({which}) => which === 13 && this.sendReminder()

  errorText = () => this.props.errors[SessionActions.REQUEST_PASSWORD_RESET] ?
                      this.props.errors[SessionActions.REQUEST_PASSWORD_RESET][0].title :
                      this.state.error

  render = () =>
    <CenteredCard>
      <Card onKeyUp={this.keyPressed}>
        <AppBar
          title="Forgot your password?"
          showMenuIconButton={false}
        />
        <CardText>
           <TextField ref={(ref) => ref && ref.focus()}  hintText="Email" floatingLabelText="Email" onChange={this.setEmail} errorText={this.errorText()}/>
        </CardText>
        <CardActions>
          <RaisedButton label="Send Password Reset Email" primary={true} style={{float: 'right'}} onClick={this.sendReminder}/>
          <br style={{clear: 'both'}}/>
          <br/>
          <Link to='/sessions/sign_in'><FlatButton label="Back" secondary={true}/></Link>
        </CardActions>
      </Card>
    </CenteredCard>
}

export default connect(({session}) => session)(ForgotPassword)
