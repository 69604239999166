import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Customers } from '.'
import { UserRestrictedView } from '../'
import { connect } from "react-redux";

export class CustomersWrapper extends Component{

  static propTypes = {
    customer: PropTypes.object,
    user: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
  }

  get loaded(){
    return this.props.user.id
  }

  render = () =>
    <div>
      { this.loaded && <UserRestrictedView component={Customers} {...this.props}/>}
    </div>
}

export default connect(({session}) => session)(CustomersWrapper)