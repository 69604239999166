import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn}
  from 'material-ui/Table';
import { Pagination } from '../'
import TextField from 'material-ui/TextField';
import ActionSearch from 'material-ui/svg-icons/action/search';
import * as ItemMatchStringProviders from './ItemMatchStringProviders'
import { CustomPropTypes } from '../../utils'
import ListViewColumn from './ListViewColumn'

export class ListView extends Component{

  static propTypes = {
    items: PropTypes.array.isRequired,
    children: CustomPropTypes.arrayOfElementType(ListViewColumn).isRequired,
    enableSelectAll: PropTypes.bool.isRequired,
    sortable: PropTypes.bool.isRequired,
    showRowHover: PropTypes.bool.isRequired,
    allRowsSelected: PropTypes.bool,
    onRowClick: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    isRowSelectedTest: PropTypes.func,
    defaultSortBy: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
    defaultSortOrder: PropTypes.string,
    height: PropTypes.string,
    pagination: PropTypes.oneOfType([
      PropTypes.shape({
        currentPage: PropTypes.number,
        totalPages: PropTypes.number,
        onPageSelected: PropTypes.func
      }),
      PropTypes.shape({
        pageSize: PropTypes.number
      })
    ]),
    sorting: PropTypes.shape({
      sortBy: PropTypes.string,
      sortDirection: PropTypes.oneOf(['asc', 'desc']),
      onSortChange: PropTypes.func
    }),
    filtering: PropTypes.oneOfType([
      PropTypes.shape({
        filterMatchStringProvider: PropTypes.func
      }),
      PropTypes.bool,
      PropTypes.shape({
        onFilterChange: PropTypes.func,
        filterText: PropTypes.string
      })
    ]),
    style: PropTypes.object.isRequired,
    paginationStyle: PropTypes.object.isRequired,
    filterStyle: PropTypes.object.isRequired
  }

  static defaultProps = {
    style: {},
    paginationStyle: { float: 'right' },
    filterStyle: { float: 'left', margin: '5px 0 0 20px'},
    items: [],
    enableSelectAll: false,
    sortable: false,
    showRowHover: true,
    isRowSelectedTest: () => false
  }

  constructor(props){
    super(props)
    this.state = {
      currentPage: 1,
      sortBy: props.defaultSortBy || this.columns[0].attribute,
      sortDirection: props.defaultSortOrder || 'asc'
    }
  }

  get columns(){
    return React.Children.map(this.props.children, ({props}) => props)
  }

  renderHeaderRow = () =>
    <TableRow onCellClick={this.onHeaderClick}>
      {this.columns.map(this.renderHeaderCell)}
    </TableRow>

  renderHeaderCell = (column, index) => {
    return <TableHeaderColumn key={index} style={{...column.style, cursor: 'pointer'}}>{this.renderSortArrow(column)}{column.name}</TableHeaderColumn>
  }

  get isSortingControlled(){
    return !!(this.props.sorting)
  }

  get sortBy(){
    return (this.props.sorting && this.props.sorting.sortBy) || this.state.sortBy
  }

  get sortDirection(){
    return (this.props.sorting && this.props.sorting.sortDirection) || this.state.sortDirection
  }

  renderSortArrow = (column) => {
    if(!this.props.sortable)
      return false
    const sortDirection = this.getSortDirection(column)
    switch(sortDirection){
    case 'asc': { return '▲' }
    case 'desc': { return '▼' }
    default: { return false }
    }
  }

  getSortDirection = (column) => {
    if(this.sortBy === column.attribute){
      return this.sortDirection
    }
  }

  renderBodyRow = (item, index) => {
    return <TableRow selected={this.props.isRowSelectedTest(item)} onCellClick={this.onBodyCellClick} key={index}>
      {this.columns.map(this.renderItemCell(item))}
    </TableRow>
  }

  onRowClick = (row, column) => {
    if(column === -1){
      this.makingSelection = true
    }else{
      this.makingSelection = false
      return this.props.onRowClick && this.props.onRowClick(this.paginatedItems[row], row)
    }
  }

  renderItemCell = (item) => (column, index) => {
    return <TableRowColumn style={{...column.style, cursor: 'pointer'}} key={index}>{column.itemRenderer(item, column)}</TableRowColumn>
  }

  onHeaderClick = (Proxy, row, column) =>{
    const isSelectAllColumn = (column === 0 && this.props.enableSelectAll)
    if(isSelectAllColumn){
      this.makingSelection = true
    }else{
      return this.setSortBy(this.columns[column - 1])
    }
  }

  setSortBy = (column) => {
    if(!(this.props.sortable && column.sortable && column.itemSorter))
      return
    const currentSortDirection = this.getSortDirection(column)
    const nextSortDirection = currentSortDirection === 'asc' ? 'desc' : 'asc'
    if(this.isSortingControlled){
      this.props.sorting.onSortChange(column.attribute, nextSortDirection)
    }else{
      this.setState({ sortBy: column.attribute, sortDirection: nextSortDirection})
    }
  }

  renderPagination = () =>
    <Pagination
      key='pagination'
      style={this.props.paginationStyle}
      page={this.currentPage}
      totalPages={this.totalPages}
      onPageSelected={this.onPageSelected}
    />

  renderItemCount = () => {
    return (
      <div style={{float: 'left', padding: '15px'}}>
        {this.itemCountText}
      </div>
    )
  }

  get itemCountText() {
    if (this.itemCountForCurrentPage === 1)
      return `Item ${this.paginationRange[0] + 1} of ${this.items.length}`
    else
      return `Items ${this.paginationRange[0] + 1} to ${Math.min(this.paginationRange[1], this.items.length)} of ${this.items.length}`
  }

  renderTextFilter = () =>
    <TextField style={this.props.filterStyle} key='filter' defaultValue={this.filterText} onChange={this.filterTextChanged} hintText={<ActionSearch color='silver' style={{width: 30, height: 30}} viewBox='0 -5 25 25'/>}/>

  get filterText(){
    return this.isFilteringControlled ? this.props.filtering.filterText : this.state.filterText
  }

  get isFilteringControlled(){
    return !(
      this.props.filtering === true ||
      (this.props.filtering && this.props.filtering.filterMatchStringProvider)
    )
  }

  extractFilterString = (item) => {
    if(this.props.filtering && this.props.filtering.filterMatchStringProvider){
      return this.props.filtering.filterMatchStringProvider(item, this.columns)
    }else{
      return ItemMatchStringProviders.JSONAPIMatchStringProvider(this.columns, item)
    }
  }

  filterTextChanged = (_proxy, filterText) =>
    this.isFilteringControlled ? this.props.filtering.onFilterChange(filterText) : this.setState({filterText})

  onPageSelected = (currentPage) => {
    return this.isPaginationControlled ?
      this.props.pagination.onPageSelected(currentPage) :
      this.setState({ currentPage })
  }

  onSelectionChanged = (selection) => {
    if(!this.props.onSelectionChanged || !this.makingSelection) return
    this.makingSelection = false
    const items = this.paginatedItems
    return Array.isArray(selection) ?
      this.props.onSelectionChanged(selection.map(idx => items[idx])) :
      this.props.onSelectionChanged(selection)
  }

  get isPaginated(){
    return !!(this.props.pagination)
  }

  get willShowPagination(){
    return this.isPaginated && (this.items.length > this.props.pagination.pageSize)
  }

  get controlledPaginationParams(){
    return [
      this.props.pagination.currentPage,
      this.props.pagination.onPageSelected,
      this.props.pagination.totalPages
    ]
  }
  get isPaginationControlled(){
    return this.controlledPaginationParams.filter(x => x !== undefined).length === 3
  }

  get currentPage(){
    return this.props.pagination.currentPage || this.state.currentPage
  }

  get totalPages(){
    return this.props.pagination.totalPages || Math.ceil(this.items.length / this.props.pagination.pageSize)
  }

  get paginationRange(){
    const beginning = (Math.min(this.totalPages, this.currentPage) - 1) * this.pageSize
    const end       = (Math.min(this.totalPages, this.currentPage)) * this.pageSize
    return [beginning, end]
  }

  get itemCountForCurrentPage(){
    return this.items.length - this.paginationRange[0]
  }

  get pageSize(){
    return this.props.pagination.pageSize
  }

  get paginatedItems(){
    if(this.isPaginated && !this.isPaginationControlled){
      const [beginning, end] = this.paginationRange
      return this.items.slice(beginning, end)
    }else{
      return this.items
    }
  }

  get sorter(){
    return (a, b) => {
      return this.sortColumn.itemSorter(a, b, this.sortColumn)
    }
  }

  get sortColumn(){
    return this.columns.find(c => c.attribute === this.sortBy)
  }

  get items(){
    let items;
    if(!this.props.sortable || this.isSortingControlled || !this.sortColumn.sortable){
      items = this.props.items
    }else{
      const sorted = this.props.items.slice().sort(this.sorter)
      items = this.sortDirection === 'desc' ? sorted.reverse() : sorted
    }
    if(this.state.filterText){
      items = items.filter(item => this.extractFilterString(item).toLowerCase().match(this.state.filterText.toLowerCase()))
    }
    return items
  }

  get showTextFilter(){
    return !!this.props.filtering
  }

  render = () =>
    <div style={this.props.style}>
      {this.showTextFilter && this.renderTextFilter()}
      <br style={{clear: 'both'}}/>
      {
        this.isPaginated && this.props.topPagination && this.willShowPagination &&
          <div style={{overflow: 'hidden', borderBottom: 'solid 1px #e0e0e0'}}>
            {this.isPaginated && this.renderPagination()}
          </div>
      }
      <Table
        height={this.props.height}
        ref={(tableRef) => this.tableRef = tableRef}
        multiSelectable={this.props.enableSelectAll}
        enableSelectAll={this.props.enableSelectAll}
        selectable={this.props.enableSelectAll}
        onCellClick={this.onRowClick}
        onRowSelection={this.onSelectionChanged}
        allRowsSelected={this.props.allRowsSelected}
      >
        <TableHeader displaySelectAll={this.props.enableSelectAll} adjustForCheckbox={this.props.enableSelectAll}>
          {this.renderHeaderRow()}
        </TableHeader>
        <TableBody deselectOnClickaway={false} displayRowCheckbox={this.props.enableSelectAll} showRowHover={this.props.showRowHover}>
          {this.paginatedItems.map(this.renderBodyRow)}
        </TableBody>
      </Table>
      <div style={{'paddingTop': '15px'}}>
        {this.isPaginated && this.renderPagination()}
        {this.willShowPagination && this.renderItemCount()}
      </div>
    </div>
}

export default ListView