import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Loader } from '.'

export class LoadingWrapper extends Component{

  static propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    style: PropTypes.object.isRequired
  }

  static defaultProps = {
    style: { display: 'flex', height: '100%'}
  }

  render = () =>
    <div style={this.props.style}>
      {this.props.loading ? <Loader/> : this.props.children}
    </div>

}

export default LoadingWrapper