import React, { Component } from 'react'
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton'
import Dialog from '../Dialog'
import { FilterTypes } from '../../constants'
import { ProjectTaskActions } from '../../actionsets'
import { ListView, ListViewColumn } from '../../components'
import { ItemRenderers, ItemSorters } from '../../components/List'
import { FilterBar } from '../FilterBar'
import { ProjectTaskForm } from '.'
import { connect } from "react-redux"


export class ProjectTasks extends Component{

  static propTypes = {
    dispatch: PropTypes.func,
    projectTasks: PropTypes.array.isRequired,
    projectId: PropTypes.string.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      selected: null,
      filterState: FilterTypes.ACTIVE
    }
    ProjectTaskActions.bindActions(this)
  }

  componentWillMount = () => {
    this.loadProjectTasks()
  }

  loadProjectTasks = (query=this.filterToStatus(this.state.filterState)) => {
    this.actions.searchProjectTasks({projectId: this.props.projectId, ...query})
  }

  projectTaskSelected = (selected) => {
    this.setState({selected})
  }

  onFormSubmitted = success => {
    if (success) this.loadProjectTasks()
    this.setState({selected: null})
  }

  onProjectTaskDelete = async projectTask => {
    await this.actions.deleteProjectTask(projectTask.id)
    this.loadProjectTasks()
  }

  filterToStatus = filter => filter !== FilterTypes.ALL ? {status: filter.toLowerCase()} : {}

  onFilterChange = filter => {
    this.setState({filterState: filter})
    this.loadProjectTasks(this.filterToStatus(filter))
  }

  render = () =>
    <div>
      <br/>
      <RaisedButton style={{float: 'right', marginLeft: 10}} label='New' primary={true} onClick={() => this.projectTaskSelected({})} />

      <FilterBar filter={ this.state.filterState } onFilterChange={ this.onFilterChange} />

      <Dialog
        title={this.state.selected && this.state.selected.attributes ? 'Edit Task' : 'New Task'}
        modal={true}
        open={!!this.state.selected}
        autoScrollBodyContent={true}
      >
        <ProjectTaskForm
          {...this.props}
          editId={this.state.selected && this.state.selected.id}
          projectId={this.props.projectId}
          onSubmitted={() => this.onFormSubmitted(true)}
          onCancel={() => this.onFormSubmitted(false)}
        />
      </Dialog>

      <ListView
        pagination={{pageSize: 10}}
        items={this.props.projectTasks}
        sortable={true}
        defaultSortBy='name'
        defaultSortOrder='asc'
        filtering={true}
        onRowClick={this.projectTaskSelected}
      >

        <ListViewColumn name='Name' attribute='name' itemSorter={ItemSorters.stringPlainItemSorter}/>
        <ListViewColumn name='Code' attribute='code' itemSorter={ItemSorters.stringPlainItemSorter}/>
        <ListViewColumn name='Billable' attribute='billable' itemRenderer={ItemRenderers.htmlSafeRenderer} itemSorter={ItemSorters.stringPlainItemSorter}/>
        <ListViewColumn name='Start Date' attribute='startDate' itemRenderer={ItemRenderers.stringPlainItemSorter} />
        <ListViewColumn name='End Date' attribute='endDate' itemRenderer={ItemRenderers.stringPlainItemSorter} />
        <ListViewColumn name='Status' attribute='derivedStatus' itemRenderer={ItemRenderers.titleizedItemRenderer} />
      </ListView>
    </div>
}

export default connect(({projectTasks}) => projectTasks)(ProjectTasks)
