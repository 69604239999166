import React, { Component } from 'react'
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import _ from 'lodash'

export class MultiSelect extends Component{

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    renderOptionText: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    floatingLabelText: PropTypes.string,
    hintText: PropTypes.string,
    style: PropTypes.object,
  }

  constructor(props){
    super(props)
    this.state = {
      selected: this.valueToSelected(props.value, props.options)
    }
  }

  componentWillReceiveProps = ({value, options}) => {
    if (!_.isEqual(this.props.value, value) || !_.isEqual(this.props.options, options)) {
      this.setState({selected: this.valueToSelected(value, options)})
    }
  }

  valueToSelected = (value, options) => (value === '*' ? options.map(o => o.id) : value)

  selectionRenderer = (options, values, renderOptionText) => {
    switch (values.length) {
    case 0:
      return ''
    case options.length:
      return 'All'
    case 1:
      return renderOptionText(options.find(o => o.id === values[0]))
    default:
      return `${values.length} selected`
    }
  }

  onMultiSelectChange = (values, options) => {
    let selected = values
    if (selected.includes('*')) {
      if (values.length === options.length+1) {
        selected = []
      } else {
        selected = options.map(o => o.id)
      }
    }

    this.setState({selected})
    this.props.onChange(selected.length === options.length ? '*' : selected)
  }


  render = () => {
    const {options, floatingLabelText, hintText, renderOptionText} = this.props
    const {selected} = this.state

    return (
      <SelectField
        style={this.props.style}
        floatingLabelText={floatingLabelText}
        hintText={hintText}
        multiple={true}
        value={selected}
        onChange={(event, index, values) => this.onMultiSelectChange(values, options)}
        selectionRenderer={values => this.selectionRenderer(options, values, renderOptionText)}
      >
        <MenuItem value='*' primaryText={`All ${hintText}`} insetChildren={true} checked={selected.length === options.length} />
        {options.map(optn => <MenuItem key={optn.id} value={optn.id} insetChildren={true} checked={selected.includes(optn.id)} primaryText={renderOptionText(optn)} />)}
      </SelectField>
    )

  }

}

export default MultiSelect
