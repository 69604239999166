import React, { Component } from 'react'
import { ListView, ListViewColumn } from '../../components'
import { ItemRenderers, ItemSorters } from '../../components/List'


export default class TimesheetTaskSummary extends Component {

  render = () => {
    const {timesheet: { id, attributes: {summary: {items}}}} = this.props
    return (
      <div key={id}>
        <ListView
          pagination={{pageSize: 10}}
          items={items}
          sortable={true}
          defaultSortBy={0}
          defaultSortOrder='desc'
          filtering={false}
          topPagination={true}
        >
          <ListViewColumn name='Task Code' attribute={0} itemRenderer={ItemRenderers.plainItemRendererNullSafe} itemSorter={ItemSorters.taskCodeSorter} />
          <ListViewColumn name='Task' attribute={1} itemRenderer={ItemRenderers.plainItemRendererNullSafe} itemSorter={ItemSorters.plainItemSorter} />
          <ListViewColumn name='Project Code' attribute={2} itemRenderer={ItemRenderers.plainItemRendererNullSafe} itemSorter={ItemSorters.plainItemSorter} />
          <ListViewColumn name='Total Hours' attribute={3} itemRenderer={ItemRenderers.plainItemRendererNullSafe} itemSorter={ItemSorters.numericPlainItemSorter} />
        </ListView>
      </div>
    )
  }
}
