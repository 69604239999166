import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome'
import IconButton from 'material-ui/IconButton';

const styles = {
  FA: { cursor: 'pointer' },
  normalSize: {
    materialIconDiv: {
      display: 'inline-block',
      marginTop: -32,
      marginLeft: -14,
      position: 'absolute'
    }
  },
  largerSize: {
    FADiv: { width: '100%', textAlign: 'center', overflow: 'visible', height: 0 },
    materialIconDiv: { display: 'inline-block' }
  }
}

export class FAButton extends Component {

  static propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    style: PropTypes.object,
    tooltip: PropTypes.string,
    tooltipPosition: PropTypes.string,
    disabled: PropTypes.bool,
    disabledTooltop: PropTypes.string
  }

  static defaultProps = {
    style: {},
    tooltipPosition: 'top-center',
    disabled: false
  }

  // Need different styling depending on the size of the FA
  get styles() {
    return !!this.props.size ? styles.largerSize : styles.normalSize
  }

  get tooltip() {
    return this.props.disabled ? this.props.disabledTooltop : this.props.tooltip
  }

  render = () =>
    <div style={{display: 'inline-block', marginLeft: -10, ...this.props.style}}>
      <div style={this.styles.FADiv}>
        <FontAwesome title="helo" name={this.props.name} size={this.props.size} fixedWidth={true} style={styles.FA} />
      </div>
      <div style={this.styles.materialIconDiv}>
        <IconButton tooltip={this.tooltip} onClick={this.props.onClick} tooltipPosition={this.props.tooltipPosition} disabled={this.props.disabled}/>
      </div>
    </div>

}

export default FAButton