import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {Card, CardActions, CardText, CardHeader} from 'material-ui/Card'
import {List, ListItem} from 'material-ui/List'
import RaisedButton from 'material-ui/RaisedButton'
import Avatar from 'material-ui/Avatar'
import Timelapse from 'material-ui/svg-icons/image/timelapse'
import AccountCircle from 'material-ui/svg-icons/action/account-circle'
import Work from 'material-ui/svg-icons/action/work'
import Chip from 'material-ui/Chip'
import {titleize, pluralize, translateRGBAColor} from '../../utils'
import { TimesheetStatus } from '../../constants'


export default class TimesheetSummary extends Component {

  static propTypes = {
    timesheet: PropTypes.object.isRequired,
    approver: PropTypes.object.isRequired,
    onApprove: PropTypes.func.isRequired,
    onUnapprove: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    disableActions: PropTypes.bool
  }

  static styles = {
    card: {
      marginBottom: 20,
    },
    listIcon: {
      fontSize: 10,
    },
    listItem: {
      padding: 0,
      lineHeight: '16px',
    },
    approved: '#4CAF50',
    unapproved: '#FF5722',
    pending: '#FFC107',
  }

  renderAction = () => {
    const { timesheet: {attributes: {summary: {items}}}, onApprove, onUnapprove, approver } = this.props
    // approved all projects from the timesheet?
    let approved = items.every(p => p.approval.users.some(u => u.id+'' === approver.id+'' && u.status === TimesheetStatus.approved))

    return (
      <RaisedButton
        label={approved ? 'Unapprove' : 'Approve'}
        primary={true}
        onClick={() => approved ? onUnapprove() : onApprove()}
      />
    )
  }

  renderTimeIcon = (time, status) => (
    <Avatar
      color='white'
      backgroundColor={TimesheetSummary.styles[status]}
      size={35}
      style={TimesheetSummary.styles.listIcon}
    >
      {time}h
    </Avatar>
  )

  renderProjectStatus = project => (
    <span>
      <b> {pluralize('hour', project.time, true)} </b>
      {project.approval.users.map(u => `${titleize(u.status)} by ${u.name}`).join(', ')}
    </span>
  )

  renderProjectCode = project => (
    <div style={{width: 60, display: 'inline-block'}}>
      <Chip
        backgroundColor={translateRGBAColor(project.color)}
        labelColor='#fff'
        labelStyle={{fontSize: 10, lineHeight: '24px', paddingLeft: 8, paddingRight: 8}}
      >
        {project.code}
      </Chip>
    </div>
  )

  renderProjectItem = project => (
    <ListItem
      key={project.id}
      primaryText={<span>{project.code && this.renderProjectCode(project)} {project.name}</span>}
      secondaryText={this.renderProjectStatus(project)}
      leftAvatar={this.renderTimeIcon(project.time, project.approval.status)}
      primaryTogglesNestedList
      nestedItems={project.projectTasks.map(task => this.renderProjectTaskItem(task))}
      style={TimesheetSummary.styles.listItem}
    />
  )

  renderProjectTaskItem = (task, status) => (
    <ListItem
      key={task.id}
      primaryText={(task.code ? `[${task.code}] ` : '') + task.name}
      secondaryText={<b>{pluralize('hour', task.time, true)}</b>}
      leftAvatar={this.renderTimeIcon(task.time, status)}
      primaryTogglesNestedList
      nestedItems={task.timesheetEntries.map(entry => this.renderTimesheetEntryItem(entry))}
      style={TimesheetSummary.styles.listItem}
    />
  )

  renderTimesheetEntryItem = entry => (
    <ListItem
      key={entry.id}
      primaryText={`${entry.date}: ${entry.code ? `[${entry.code}]` : ''} ${entry.comment}`}
      secondaryText={<b>{pluralize('hour', entry.time, true)}</b>}
      leftIcon={<Timelapse/>}
      style={TimesheetSummary.styles.listItem}
    />
  )

  render = () => {
    const {timesheet: {attributes: {summary: {items, time, internalTime, externalTime, groupBy}, user, project, status}}, disableActions} = this.props
    const internalExternal = (groupBy !== 'user' ? ` (${internalTime} internal, ${externalTime} external)` : '')
    return (
      <Card style={TimesheetSummary.styles.card}>
        <CardHeader
          title={groupBy === 'user' ? <span>{this.renderProjectCode(project)}{project.name}</span> : user.name }
          titleStyle={{fontSize: 18}}
          subtitle={<span style={{paddingLeft: groupBy === 'user' && 60}}><b>{pluralize('hour', time, true)}</b> {status}{internalExternal}</span>}
          avatar={<Avatar backgroundColor='transparent'> { groupBy === 'user' ? <Work color='#ccc' /> : <AccountCircle color='#ccc' /> } </Avatar>}
        />

        <CardText>
          <List>
            { items.map(this.renderProjectItem) }
          </List>
        </CardText>

        {!disableActions &&
          <CardActions style={{height: 40}}>
            <div className='pull-right'>
              { this.renderAction() }
            </div>
          </CardActions>
        }
      </Card>
    )
  }
}
