import { bindActionCreators } from 'redux'
import { createReducer } from '../utils'


export default class ActionSet{

  constructor(){
    this.initialState = this.constructor.initialState
    this.reducerSets = []

    this.actionNames.forEach(actionName => {
      try{
        if(actionName !== '__proto__'){
          this.createAction(actionName)
        }
      }catch(err){
        throw new Error(`Error while creating action ${actionName} ${err}`)
      }
    })
    this.actions = {}
    this.actionNames.forEach(name => {
      this.actions[name] = this[name]
    })

    this.combineReducers()
  }

  combineReducers(){
    let reducers = {}
    this.reducerSets.forEach(set => reducers = {...reducers, ...set})
    if(reducers[undefined]){
      throw new Error('Reducer with undefined type defined. Did you forget to define a constant?')
    }
    this.reducerNames = Object.keys(reducers)
    this.reducers = createReducer(this.initialState, reducers)
  }

  extractReducers(defaultName, reducers){
    if(typeof(reducers) === 'function'){
      return { [defaultName]: reducers }
    }else{
      return reducers
    }
  }

  applyConstantsMiddleware(constants){
    this.constructor.constantsMiddleware.forEach(mw => constants = mw(constants))
    return constants
  }

  createAction(actionName){
    const actionCreator   = this.constructor[actionName]
    const actionConstant  = this.constantize(actionName)
    const setName         = this.constantize(this.constructor.name)

    this.defineConstant(actionConstant)

    const dependencies = { creator: null, reducer: null}

    let actionConstants = (...suffixes) => suffixes.forEach(suffix => this.defineConstant(`${actionConstant}_${suffix}`, `${setName}.${actionConstant}.${suffix}`))
    if(this.constructor.constantsMiddleware)
      actionConstants = this.applyConstantsMiddleware(actionConstants)

    actionCreator.bind(this)(
      creatorFunc => dependencies.creator = creatorFunc,
      reducers    => dependencies.reducer = this.extractReducers(actionConstant, reducers),
      actionConstants
    )

    if(!dependencies.creator){
      throw new Error(`Action ${actionName} does not define a creator`)
    }
    if(!dependencies.reducer){
      throw new Error(`Action ${actionName} does not define a reducer`)
    }

    this[actionName] = dependencies.creator
    this.reducerSets.push(dependencies.reducer)
  }

  defineConstant(key, value){
    const asConst = this.constantize(key)
    const asConstValue = `${this.constantize(this.constructor.name)}.${asConst}`
    this[asConst] = value || asConstValue
  }

  constantize(value){
    return `${value}`.replace(/[a-z][A-Z]/g, (str) => `${str[0].toUpperCase()}_${str[1].toUpperCase()}`).toUpperCase()
  }

  get actionNames(){
    const propertyNames = Object.getOwnPropertyNames(this.constructor)
    return propertyNames.filter(propName => {
      try{
        const value = this.constructor[propName]
        return typeof value === 'function'
      }catch(err){}
      return false
    })
  }

  bindActions(context){
    context.actions = context.actions || {}
    Object.assign(context.actions, bindActionCreators(this.actions, context.props.dispatch))
  }
}