class TimesheetAPI{

  static approve = (userId, startDate, endDate) => authenticated => authenticated.post('api/timesheets/approve', {
    data: {
      type: 'timesheets',
      attributes: {userId, startDate, endDate}
    }
  })

  static unapprove = (userId, startDate, endDate) => authenticated => authenticated.post('api/timesheets/unapprove', {
    data: {
      type: 'timesheets',
      attributes: {userId, startDate, endDate}
    }
  })

  static status = (userId, startDate, endDate) => authenticated => authenticated.post('api/timesheets/status', {
    data: {
      type: 'timesheets',
      attributes: {userId, startDate, endDate}
    }
  })

  static search = (attributes) => authenticated => authenticated.post('api/timesheets/search', {
    data: {
      type: 'timesheets',
      attributes
    }
  })

}

export default TimesheetAPI
