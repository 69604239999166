import React, { Component } from 'react'
import { ListView, ListViewColumn } from '../../components'
import { ItemRenderers, ItemSorters } from '../../components/List'


export default class TimesheetWeeklySummary extends Component {

  render = () => {
    const {timesheet: { id, attributes: {summary: {items, dates}}}} = this.props
    return (
      <div key={id}>
        <ListView
          pagination={{pageSize: 10}}
          items={items}
          sortable={true}
          defaultSortBy={1}
          defaultSortOrder='asc'
          filtering={false}
          topPagination={true}
        >
          <ListViewColumn name='User' attribute={0} itemRenderer={ItemRenderers.plainItemRendererNullSafe} itemSorter={ItemSorters.plainItemSorter} />
          <ListViewColumn name='Project Code' attribute={1} itemRenderer={ItemRenderers.plainItemRendererNullSafe} itemSorter={ItemSorters.plainItemSorter} />
          {dates.map((date, i) =>
            <ListViewColumn key={date} name={date} attribute={i+2} itemRenderer={ItemRenderers.plainItemRendererNullSafe} itemSorter={ItemSorters.numericPlainItemSorter} />
          )}
        </ListView>
      </div>
    )
  }
}
