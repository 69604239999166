import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from './containers';
import './styles/index.css';
import './styles/App.css'

ReactDOM.render(
  <AppContainer />,
  document.getElementById('root')
);
