import React, {  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import CRUDForm from '../../containers/CRUDForm'
import { TimesheetEntryActions } from '../../actionsets'
import { ListView, ListViewColumn } from '../../components'
import { ItemRenderers, ItemSorters } from '../../components/List'


export class InvoiceForm extends CRUDForm{

  static propTypes = {
    entries: PropTypes.array.isRequired,
  }

  static styles = {
    fields: {
      marginBottom: 20,
    },
    actions: {
      float: 'right'
    }
  }

  constructor(props){
    super(props)
    this.state = {
      errors: {},
      submissionErrors: [],
      invoiceNo: this.initialInvoiceNo(props.entries) || '',
    }
    TimesheetEntryActions.bindActions(this)
  }

  componentDidMount = () => {} // disable default CRUDForm behavior

  submit = async () => {
    const {entries} = this.props
    if (!this.validate()) return

    try {
      await this.actions.invoiceTimesheetEntries(entries.map(e => e.id), this.state.invoiceNo)
      this.props.onSubmitted && this.props.onSubmitted()
    } catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['invoiceNo']
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  initialInvoiceNo = entries => {
    const val = entries.length > 0 ? entries[0].attributes.invoiceNo : ''
    return entries.reduce((acc, entry) => acc === entry.attributes.invoiceNo ? acc : '', val)
  }

  render = () =>
    <div>

      <ListView
        items={this.props.entries}
        defaultSortBy='projectName'
        defaultSortOrder='asc'
        height={this.props.entries.length > 5 ? '250px' : undefined}
      >

        <ListViewColumn name='Date' attribute='date' itemSorter={ItemSorters.plainItemRenderer}/>
        <ListViewColumn name='Project' attribute='projectName' itemRenderer={ItemRenderers.titleizedItemRenderer} />
        <ListViewColumn name='User' attribute='userName' itemRenderer={ItemRenderers.titleizedItemRenderer} />
        <ListViewColumn name='Task' attribute='projectTaskName' itemRenderer={ItemRenderers.titleizedItemRenderer} />
        <ListViewColumn name='Time' attribute='time' itemSorter={ItemSorters.plainItemRenderer}/>
        <ListViewColumn name='Invoice No' attribute='invoiceNo' itemRenderer={ItemRenderers.stringPlainItemSorter} />

      </ListView>

      <div style={InvoiceForm.styles.fields}>
        <TextField
          floatingLabelText="Invoice Number"
          hintText="Invoice Number"
          value={this.state.invoiceNo}
          onChange={this.onFormValueChange('invoiceNo', this.nthValue(1))}
          errorText={this.state.errors.invoiceNo}
          style={{marginLeft: 10, marginTop: 20}}
        />
      </div>

      <div style={InvoiceForm.styles.actions}>
        <FlatButton label="Cancel" style={{marginRight: 10}} onClick={this.props.onCancel} />
        <RaisedButton label='Save' primary onClick={this.submit} />
      </div>

    </div>

}

export default connect(state => { return {...state.session} })(InvoiceForm)