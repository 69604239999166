import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import { Card } from 'material-ui/Card'
import { DangerousCardHeader } from './DangerousCardHeader'
import { Loader } from '../'
import Responsive from '../Responsive'
import { Themed } from '../../containers/VitalstatistixThemeProvider'

const styles = {
  mobile: {
    wrapperStyle: {
      background: '#fafafa',
      borderRadius: 15,
      overflow: 'hidden',
      boxShadow: '0px 10px 44px -2px rgba(0,0,0,0.40)',
      margin: '5px 10px',
      flex: '0 0 calc(100vw - 20px)',
      display: 'flex',
      flexDirection: 'column'
    },
  },
  desktop: {
    wrapperStyle: {
      display: 'flex',           /* establish flex container */
      justifyContent: 'center',  /* center items vertically, in this case */
      alignItems: 'flex-start',  /* center items horizontally, in this case */
      flexWrap: 'wrap',
      background: '#fafafa',
      marginLeft: '10px',
      marginRight: '10px',
      height: '100%',
      maxWidth: '350px',
      minWidth: '350px',
      borderRadius: 15,
      overflow: 'hidden',
      boxShadow: '0px 10px 44px -2px rgba(0,0,0,0.40)',
      flex: 1
    }
  },
  containerStyle: {
    display: 'flex',               /* establish flex container */
    flexDirection: 'column',       /* make main axis vertical */
    alignContent: 'space-between',
    background: '#fafafa',
    maxHeight: '100%',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    flex: 1,
    overflow: 'hidden'
  },
  fullScreenWrapperStyle: {
    display: 'flex',           /* establish flex container */
    justifyContent: 'center',  /* center items vertically, in this case */
    alignItems: 'flex-start',  /* center items horizontally, in this case */
    flexWrap: 'wrap',
    background: '#fafafa',
    borderRadius: 15,
    overflow: 'hidden',
    boxShadow: '0px 10px 44px -2px rgba(0,0,0,0.40)',
    padding: 0,
    height: '100%',
    width: '100%'
  }
}

export class GeneralCard extends Component {

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    title: PropTypes.string,
    primaryColor: PropTypes.string,
    loaded: PropTypes.bool,
    toggleFullScreen: PropTypes.func,
    fullScreenable: PropTypes.bool,
    fullScreen: PropTypes.bool,
    wrapperStyle: PropTypes.object,
    dimensions: PropTypes.object,
    chipText: PropTypes.string,
    chipColor: PropTypes.string,
    headerBackgroundColor: PropTypes.string
  }

  static defaultProps = {
    loaded: true,
    fullScreenable: false,
    fullScreen: false,
    chipText: '',
    wrapperStyle: {},
    dimensions: {}
  }

  get loaded(){
    return this.props.loaded
  }

  get wrapperStyle(){
    if(this.props.fullScreen)
      return styles.fullScreenWrapperStyle
    else if (this.props.fullScreen)
      return {display: 'none' }
    else if (this.props.dimensions.narrow)
      return styles.mobile.wrapperStyle
    else
      return styles.desktop.wrapperStyle
  }

  render = () =>
    <Card className='general-card' style={{...this.wrapperStyle}} containerStyle={styles.containerStyle}>
      {this.loaded ?
        [
          <DangerousCardHeader
            key={'dangerHeader'}
            title={this.props.title}
            fullScreen={this.props.fullScreen}
            toggleFullScreen={this.props.toggleFullScreen}
            fullScreenable={this.props.fullScreenable}
            chipText={this.props.chipText}
            chipColor={this.props.chipColor}
            headerBackgroundColor={this.props.headerBackgroundColor}
            style={{ backgroundColor: this.props.primaryColor, minHeight: '64px'}}/>,
          this.props.children
        ]
        :
        <Loader size={100}/>
      }
    </Card>
}

export default Themed(Responsive(GeneralCard))