import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {Toolbar, ToolbarGroup,ToolbarTitle} from 'material-ui/Toolbar'
import {Card } from 'material-ui/Card'
import Paper from 'material-ui/Paper'
import {Tabs, Tab} from 'material-ui/Tabs'
import { ProjectTaskActions } from '../../actionsets'
import {ProjectForm} from '.'
import {ProjectUsers, ProjectTasks} from '../'
import { connect } from "react-redux";

export class ProjectManagement extends Component{

  constructor(props){
    super(props)
    ProjectTaskActions.bindActions(this)
  }

  static propTypes = {
    customers: PropTypes.array.isRequired,
    dispatch: PropTypes.func,
    project: PropTypes.object,
    onProjectUpdate: PropTypes.func
  }

  onProjectSubmitted = () => {
    // refresh status of tasks
    if (this.props.project)
      this.props.onProjectUpdate()
    this.actions.searchProjectTasks({projectId: this.projectId})
  }

  get projectId() {
    return this.props.project.Id
  }

  render = () => (
    <Paper style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Toolbar style={{minHeight: 50}}>
        <ToolbarGroup>
          <ToolbarTitle text={'Update Project - ' + this.props.project.attributes.name } />
        </ToolbarGroup>
      </Toolbar>
      <Card style={{padding: 10}}>

        <Tabs>
          <Tab label="Details">
            <div style={{padding: 15}}>
              <ProjectForm {...this.props} project={ this.props.project } onSubmitted={this.onProjectSubmitted} onCancel={this.props.history.goBack} />
            </div>
          </Tab>

          <Tab label="Assigned Users">
            <ProjectUsers {...this.props} projectId={ this.props.project.id } />
          </Tab>

          <Tab label="Tasks">
            <ProjectTasks {...this.props} projectId={ this.props.project.id } />
          </Tab>
        </Tabs>

      </Card>
    </Paper>
  )

}

export default connect(state => { return {...state.customers, ...state.session} })(ProjectManagement)
