import React, {  } from 'react'
import PropTypes from 'prop-types';
import Subheader from 'material-ui/Subheader'
import { List } from 'material-ui/List'

export default function SectionWrapper ({ children, title }) {
  const noContentHeader = <Subheader>No {title} items</Subheader>

  return (
    <List>
      { children.length
        ? [<Subheader key={`${title}-subheader`}>{title}</Subheader>, ...children]
        : noContentHeader
      }
    </List>
  )
}

SectionWrapper.propTypes = {
  children: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}
