import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { CardText } from 'material-ui/Card'
import { List, ListItem } from 'material-ui/List'
import TextField from 'material-ui/TextField';
import ActionSearch from 'material-ui/svg-icons/action/search';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import CardListItem from './CardListItem'
import CardListItemDropZone from './CardListItemDropZone'
import Responsive from '../Responsive'

export class CardList extends Component{

  static propTypes = {
    sortedItems: PropTypes.array,
    onItemSelected: PropTypes.func,
    isActiveTest: PropTypes.func,
    filterOn: PropTypes.string,
    onReorder: PropTypes.func,
    afterReorder: PropTypes.func,
    connectDragSource: PropTypes.func,
    contentRenderer: PropTypes.func,
    dimensions: PropTypes.object
  }

  state = {
    filterText: ''
  }

  static defaultProps = {
    isActiveTest: () => false,
    contentRenderer: (item) => item.attributes.name
  }

  get filtered(){
    return !!this.props.filterOn;
  }

  get items(){
    if(this.state.filterText){
      return this.props.sortedItems.filter(({ attributes }) => {
        const attribute = attributes[this.props.filterOn]
        return attribute.toLowerCase().indexOf(this.state.filterText.toLowerCase()) !== -1
      })
    }else{
      return this.props.sortedItems
    }
  }

  onClick = (item) => {
    this.props.onItemSelected(item)
  }

  get reorderable(){
    return (this.props.onReorder || this.props.afterReorder) && this.items.length > 1
  }

  handleReorder = (index, item) => {
    if(this.props.onReorder){
      this.props.onReorder(index, item)
    }else{
      const sorted = this.props.sortedItems.slice()
      const currentIndex = this.props.sortedItems.indexOf(item)
      if (currentIndex === -1) return false
      const insertAt = currentIndex < index ? index - 1 : index

      sorted.splice(currentIndex, 1)
      sorted.splice(insertAt, 0, item)
      this.props.afterReorder(sorted, index, item)
    }
    if(!this.props.dimensions.narrow){
      setTimeout(() => this.onClick(item))
    }
  }

  renderListItem = (item, index) =>{
    return [
      <ListItem
        key={index}
        id={item.id}
        onClick={this.onClick.bind(this, item)}
        hoverColor={'#ceeaed'}
        style={{...(this.props.isActiveTest(item) ? {backgroundColor: '#ceeaed'} : {})}}
        >
        <CardListItem draggable={this.reorderable} contents={this.props.contentRenderer(item)} item={item}/>
      </ListItem>,
      this.reorderable &&
        <CardListItemDropZone onReorder={this.handleReorder} key={`${index}-drop-zone`} insertAt={index + 1}/>
    ]
  }

  render = () =>
    <CardText style={{flexShrink: '1', flexGrow: '1', padding: '0px', overflowY: 'auto'}} >
      {
        this.filtered && <TextField
          hintText={
            <ActionSearch color='silver' style={{width: 30, height: 30}
          }
          viewBox='0 -5 25 25'/>}
          style={{width: '90%', margin: '0 5%'}}
          onChange={(p, value) => this.setState({filterText: value})}
        />
      }
      <List style={{padding: '0px', paddingTop: '0px', paddingBottom: '0px'}}>
        {this.reorderable && <CardListItemDropZone onReorder={this.handleReorder} insertAt={0} />}
        { this.items.map(this.renderListItem) }
      </List>
    </CardText>

}

export default Responsive(DragDropContext(HTML5Backend)(CardList))