class CustomerAPI{
  static create = attributes => authenticated => authenticated.post('api/customers', {
    data: {
      type: 'customers',
      attributes
    }
  })

  static update = (id, attributes) => authenticated => authenticated.put(`api/customers/${id}`, {
    data: {
      type: 'customers',
      attributes
    }
  })

  static search = (attributes) => authenticated => authenticated.post('api/customers/search', {
    data: {
      type: 'customers',
      attributes
    }
  })

  static index = () => authenticated => authenticated.get('api/customers')
  static show = id => authenticated => authenticated.get(`api/customers/${id}`)
}

export default CustomerAPI
