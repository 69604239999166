class TokensAPI {

  static check = () => (_, unauthenticated) => unauthenticated.get('api/tokens/status')

  static get = credentials => (_, unauthenticated) => unauthenticated.post('api/tokens', credentials)

  static refresh = () => (_, unauthenticated) => unauthenticated.post('api/tokens/refresh')

  static requestReset = (email) => (_, unauthenticated) => unauthenticated.post('api/password/reset', {
    data: {
      type: 'passwordReset',
      attributes: {
        email: email
      }
    }
  })

  static resetPassword = ({token, password, passwordConfirmation}) => (_, unauthenticated) =>
    unauthenticated.put('api/password/reset', {
      data: {
        type: 'passwordReset',
        attributes: {
          token, password, passwordConfirmation
        }
      }
    })

  static setPassword = ({token, password, passwordConfirmation}) => (_, unauthenticated) =>
    unauthenticated.put('api/password/invitation', {
      data: {
        type: 'passwordInvitation',
        attributes: {
          token, password, passwordConfirmation
        }
      }
    })
}

export default TokensAPI
