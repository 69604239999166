const OAUTH_VERIFY_ROUTE = '/oauth/services/verify'

class OAuthAPI {

  static authenticateService = service => authenticated => authenticated.post('api/oauth/tokens', {
    service,
    callbackUrl: `${window.location.origin}${OAUTH_VERIFY_ROUTE}?service=${service}`
  })

  static verifyService = ({ oauthVerifier, requestToken, service }) => authenticated => authenticated.post('api/oauth/verify', {
    oauthVerifier,
    requestToken,
    service
  })

  static deactivateService = service => authenticated => authenticated.post('api/oauth/deactivate', {
    service
  })

  static getServices = () => authenticated => authenticated.get('api/oauth/services')

}

export default OAuthAPI
