import React, { Component } from 'react'
import PropTypes from 'prop-types';
import IconMenu from 'material-ui/IconMenu'
import IconButton from 'material-ui/IconButton'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import MenuItem from './MenuItem'
import SectionWrapper from './SectionWrapper'

export default class IconDropdownMenu extends Component {
  static styles = {
    mainIcon: {
      flex: 0,
      margin: '12px 0px'
    }
  }
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        logo: PropTypes.string,
        subItems: PropTypes.array
      })
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      isShowing: false
    }
  }

  handleShow = () => {
    this.setState({ isShowing: true })
  }

  handleHide = () => {
    this.setState({ isShowing: false })
  }

  handleSelect = item => () => {
    this.handleHide()
    this.props.onSelect(item)
  }

  render = () => {
    const { items } = this.props

    return items.length ? (
      <IconMenu
        style={IconDropdownMenu.styles.mainIcon}
        iconButtonElement={
          <IconButton onClick={this.handleShow}>
            <MoreVertIcon />
          </IconButton>
        }
        open={this.state.isShowing}
        onRequestChange={this.handleHide}
      >
        { items.map(item =>
          <SectionWrapper key={item.title} title={item.title}>
            {item.subItems.map(subItem =>
              <MenuItem
                key={`${item.title}-${subItem.name}`}
                logoSrc={item.logoSrc}
                onSelect={this.handleSelect(subItem)}
                source={item.title}
                title={subItem.name}
                url={subItem.url}
              />
            )}
          </SectionWrapper>
        )}
      </IconMenu>
    ) : null
  }
}
