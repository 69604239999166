class TimesheetEntryAPI{
  static create = attributes => authenticated => authenticated.post('api/timesheet_entries', {
    data: {
      type: 'timesheet_entries',
      attributes,
    }
  })

  static show = id => authenticated => authenticated.get(`api/timesheet_entries/${id}`)

  static update = (id, attributes) => authenticated => authenticated.put(`api/timesheet_entries/${id}`, {
    data: {
      type: 'timesheet_entries',
      attributes
    }
  })

  static delete = id => authenticated => authenticated.delete(`api/timesheet_entries/${id}`)

  static index = () => authenticated => authenticated.get('api/timesheet_entries')

  static search = (attributes) => authenticated => authenticated.post('api/timesheet_entries/search', {
    data: {
      type: 'timesheet_entries',
      attributes
    }
  })

  static copy = (attributes) => authenticated => authenticated.post('api/timesheet_entries/copy', {
    data: {
      type: 'timesheet_entries',
      attributes
    }
  })

  static csv = (attributes) => authenticated => authenticated.post('api/timesheet_entries/csv', {
    data: {
      type: 'timesheet_entries',
      attributes
    }
  })

  static invoice = (attributes) => authenticated => authenticated.post('api/timesheet_entries/invoice', {
    data: {
      type: 'timesheet_entries',
      attributes
    }
  })

}

export default TimesheetEntryAPI
