export default class ConsoleOutputGroup {
  constructor(title, color = 'orange'){
    this.title = [`%c${title}`, `color: ${color};`];
    this.lines = []
  }

  push = (line) => this.lines.push(line)

  finish = (line) => {
    if(process.env.NODE_ENV !== 'development') return
    if(line) this.lines.push(line)
    if(console.groupCollapsed)
      console.groupCollapsed(...this.title)
    this.lines.forEach(line => console.log(...line))
    if(console.groupEnd)
      console.groupEnd()
  }
}
