import React, { Component } from 'react'
import PropTypes from 'prop-types';
import AutoComplete from 'material-ui/AutoComplete'
import TextField from 'material-ui/TextField'
import MenuItem from 'material-ui/MenuItem'
import _ from 'lodash'

import { RequestService } from '../../services'
import { autoCompleteOrientation } from '../../utils'
import { debounce, chopString } from '../../utils'

import { ProjectTaskAPI } from '../../api'

export class ProjectTaskAutoComplete extends Component{
  static ORIENTATION_OFFSET = 300; // if less space is available, the dropdown will render above the input

  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    floatingLabelText: PropTypes.string,
    includeClosedTasks: PropTypes.bool
  }

  static MINIMUM_SEARCH_TERM_LENGTH = 2

  static styles = {
    textField: {
      width: '100%',
    },
    listStyle: {
      minWidth: 300,
      maxHeight: 400,
      overflowY: 'auto'
    },
    menuItem: {
      fontSize: 14,
    },
    oldEntry: {
      backgroundColor: '#9B9B9B',
      color: '#FFFFFF'
    }
  }

  constructor(props){
    super(props)
    this.state = {
      projectTasks: [],
      orientation: {},
      searchText: '',
      updatedText: undefined,
      noMatches: false
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    // Wim's solution to semmingly too many re-renders
    const props = ['value', 'errorText', 'disabled']
    return (JSON.stringify(nextState) !== JSON.stringify(this.state)) || (JSON.stringify(_.pick(nextProps, props)) !== JSON.stringify(_.pick(this.props, props)))
  }

  get code() {
    return this.props.value.attributes.projectTaskCode || this.props.value.attributes.projectCode
  }

  get text() {
    if(this.state.focus)
      return this.state.searchText
    else if (!this.editing && this.noMatches)
      return this.state.searchText
    else if (this.editing && !this.state.updatedText)
      return`${this.code} ${this.props.value.attributes.projectTaskName}`
    else if (this.state.updatedText)
      return this.state.updatedText
    else
      return ''
  }

  get editing() {
    return this.props.value.id && this.props.value.attributes
  }

  get noMatches() {
    return this.state.noMatches && this.state.searchText.length > 1
  }

  handleKeyDown = (e) => {
    if (e.key === "Tab") {
      this.tabPressed = true
    }
  }

  handleClose = () => {
    // Start: This is because Wouter wanted tab to select this first thing in the list

    if(!this.editing && this.tabPressed && this.state.focus && this.state.projectTasks.length > 0 && !!this.state.searchText) {
      const selection = this.formatProjectTask(this.state.projectTasks[0])
      this.props.onChange(selection)
      this.setState({
        updatedText: selection.text,
        searchText: ''
      })
      this.tabPressed = false
    }

    // end

    this.setState ({ focus: false })
  }

  setRecentTasks = (recentTasks) => {
    let newState = { recentTasks }
    if (this.state.focus && !this.state.searchText && (this.state.projectTasks || []).length === 0) {
      newState = { ...newState, projectTasks: recentTasks }
    }
    this.setState(newState)
  }

  componentDidMount = () => {
    this.setRecentTasks(this.props.recentTimesheetEntries)
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.recentTimesheetEntries !== this.props.recentTimesheetEntries){
      this.setRecentTasks(this.props.recentTimesheetEntries)
    }
  }

  handleFocus = () => {
    this.setState({
      orientation: autoCompleteOrientation(ProjectTaskAutoComplete.ORIENTATION_OFFSET),
      focus: true,
      noMatches: false,
      projectTasks: (this.state?.recentTasks || []),
      searchText: ''
    })
  }

  handleUpdateInput = (searchText, _, { source: changeType}) => {
    if(changeType !== 'change') {
      return
    }

    this.setState({
      searchText: searchText,
    })

    if(searchText.length >= ProjectTaskAutoComplete.MINIMUM_SEARCH_TERM_LENGTH) {
      this.search(searchText)
    } else {
      this.setState({
        noMatches: false,
        projectTasks: []
      })
    }
  }

  handleItemSelected = (selected) => {
    this.props.onChange(selected)
    this.setState({
      updatedText: selected.text,
      searchText: '',
      noMatches: false,
      focus: false
    })
  }

  formatProjectTask = (projectTask) => {
    const name = projectTask.attributes.projectTaskName || projectTask.attributes.name
    const code = projectTask.attributes.code || projectTask.attributes.projectTaskCode || projectTask.attributes.projectCode
    let styles = ProjectTaskAutoComplete.styles.menuItem

    if (this.editing && this.props.value.id === projectTask.id) {
      styles = {...styles, ...ProjectTaskAutoComplete.styles.oldEntry}
    }

    return ({
      id: projectTask.id,
      text: `${code} ${name}`,
      projectTask,
      display: <MenuItem
                primaryText={chopString(name, 40)}
                secondaryText={code}
                style={styles}
              />
    })
  }

  search = debounce(async term => {
    let { body: {data: projectTasks}} = await ProjectTaskAPI.autocomplete({term: term, includeClosedTasks: this.props.includeClosedTasks})(RequestService)

    this.setState({ noMatches: projectTasks.length === 0 })

    if(this.editing) {
      projectTasks.unshift(this.props.value)
    }
    this.setState({
      projectTasks: projectTasks,
      requesting: false
    })
  })

  renderTextField = () => <TextField
      value={this.text}
      disabled
      floatingLabelText={this.props.floatingLabelText}
      style={this.props.style}
    />

  renderAutoComplete = () => <AutoComplete
      textFieldStyle={ProjectTaskAutoComplete.styles.textField}
      listStyle={ProjectTaskAutoComplete.styles.listStyle}
      dataSourceConfig={{
        text: 'text',
        value: 'display',
      }}
      searchText={this.text}
      onFocus={this.handleFocus}
      onClose={this.handleClose}
      onNewRequest={this.handleItemSelected}
      onUpdateInput={this.handleUpdateInput}
      onKeyDown={this.handleKeyDown}
      filter={AutoComplete.noFilter}
      dataSource={this.state.projectTasks.map(this.formatProjectTask)}
      openOnFocus={true}
      errorText={this.noMatches && 'No tasks match'}
      fullWidth
      floatingLabelText={this.props.floatingLabelText}
      style={this.props.style}
      {...this.state.orientation}
    />

  render = () => {
    return this.props.disabled ? this.renderTextField() : this.renderAutoComplete()
  }
}

export default ProjectTaskAutoComplete