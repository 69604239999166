import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import {Card, CardActions, CardHeader, CardText} from 'material-ui/Card'
import FlatButton from 'material-ui/FlatButton'
import FloatingActionButton from 'material-ui/FloatingActionButton'
import ContentAdd from 'material-ui/svg-icons/content/add'
import Avatar from 'material-ui/Avatar'

import { TimesheetEntryActions, ProjectTaskActions, SnackBarActions } from '../../../actionsets'
import { TimeInput } from '../../../components'
import { ModelStatus } from '../../../constants'
import { isDirty, formatDate, assignIndexOf, partition, pipe } from '../../../utils'
import { ProjectTaskAutoComplete } from '../'

export class TimesheetEntryForm extends Component{

  static propTypes = {
    entries: PropTypes.array.isRequired, // current timesheet entries edited in form state
    timesheetEntries: PropTypes.array.isRequired, // original saved timesheet entries retrieved from API
    projectTasks: PropTypes.array.isRequired,
    errors: PropTypes.array.isRequired,
    submissionErrors: PropTypes.array.isRequired,
    requestingTimesheetEntries: PropTypes.bool.isRequired,
    selectedDate: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    weekTime: PropTypes.number.isRequired,
    dispatch: PropTypes.func,
  }

  static styles = {

    error: {
      color: '#e23838',
      background: 'pink',
      padding: '15px 5px',
      borderLeft: '2px solid red'
    },

    entry: {
      display: 'flex',
    },

    fields: {
      flex: 1,
      display: 'flex',
    },

    actions: {
      flex: 0,
      minWidth: 100,
      display: 'flex',
      alignItems: 'flex-end',
      paddingBottom: 10,
      marginLeft: 20,
      fontSize: 18,
    },

    timeField: {
      flex: 0,
      minWidth: 80,
      marginRight: 10,
    },

    taskField: {
      flex: 1,
      marginRight: 10,
      width: 0,
    },

    commentField: {
      flex: 1,
      width: 0,
    }

  }

  constructor(props){
    super(props)
    TimesheetEntryActions.bindActions(this)
    ProjectTaskActions.bindActions(this)
    SnackBarActions.bindActions(this)
    this.state = {
      includeClosedTasks: false
    }
  }

  componentWillMount(){
    this.actions.setForm(this.props.timesheetEntries)
    this.actions.searchProjectTasks({userId: this.userId, status: ModelStatus.active}, {createdAt: 'DESC', projectId: 'DESC'})
  }

  componentWillReceiveProps = nextProps => {
    if ((this.props.requestingTimesheetEntries && !nextProps.requestingTimesheetEntries) || !nextProps.selectedDate.isSame(this.props.selectedDate)) {
      this.actions.setForm(nextProps.timesheetEntries)
    }
  }

  prepareChangedEntries = entries => entries.filter(this.isEntryDirty).map(assignIndexOf(entries))

  submit = () => {
    const { entries } = this.props

    if (!this.validate()) return

    const getEntriesForSubmit = pipe(this.prepareChangedEntries, partition(e => e.id))
    const [ updates, creates ] = getEntriesForSubmit(entries)

    this.actions.updateTimesheetEntries(updates, false)
    this.actions.createTimesheetEntries(creates)
    this.actions.loadRecentTimesheetEntries()
  }

  validate = () => {
    let requiredFields = ['comment', 'projectTaskId']
    let errors = []

    const addError = (att, index, error) => {
      if (!errors[index]) errors[index] = {}
      if (!errors[index][att]) errors[index][att] = []
      errors[index][att].push(error)
    }

    this.props.entries.forEach((entry, index) => {

      requiredFields.forEach(fieldName => {
        if(!entry.attributes[fieldName]) addError(fieldName, index, 'is required')
      })

      if (isNaN(entry.attributes.time) || entry.attributes.time === '') {
        addError('time', index, 'is not a number')
      }

      if (entry.attributes.time.toString().includes('.') && !['.25', '.5', '.75'].find(incr => entry.attributes.time.toString().includes(incr))) {
        addError('time', index, 'is not a quarter hour')
      }

    })

    this.actions.setErrorsForm(errors)
    return errors.length === 0
  }

  deleteTimesheetEntry = async (entry, index) => {
    if (entry.id) {
      await this.actions.deleteTimesheetEntry(entry.id, index)
      await this.actions.deleteEntryForm(index)
    }
    else {
      await this.actions.deleteEntryForm(index)
    }
  }

  addTimesheetEntry = () => this.actions.addEntryForm(this.newTimesheetEntry)

  errorForField = (fieldName, index) => (this.props.errors[index] && this.props.errors[index][fieldName] ? this.props.errors[index][fieldName].join(', ') : null)

  isEntryDirty = entry => isDirty(entry, this.props.timesheetEntries)

  renderSubmissionErrors = () => {
    if(!this.props.submissionErrors.length) {
      return
    }

    return this.props.submissionErrors.map(({ detail,  source: { pointer }}, index) => {
      return (
        <p key={index} style={TimesheetEntryForm.styles.error}>
          {`${pointer} ${detail}`.replace(/.*\//, '')}
        </p>
      )
    })
  }

  get isFormDirty(){
    return this.props.entries.some(this.isEntryDirty)
  }

  get userId(){
    return this.props.user.id
  }

  get totalTime(){
    return this.props.entries.reduce((total, entry) => {
      let time = entry.attributes.time
      if (!time || isNaN(time)) time = 0
      return total + parseFloat(time)
    }, 0)
  }

  get newTimesheetEntry(){
    return {
      id: null,
      attributes: {
        userId: this.userId,
        date: formatDate(this.props.selectedDate),
        time: '',
        projectTaskId: '',
        projectTaskCode: '',
        projectTaskName: '',
        comment: ''
      }
    }
  }

  get taskSearchStatusText() {
    return this.state.includeClosedTasks ? 'All Tasks' : 'Active tasks'
  }

  onLastFieldKeyDown = (e, index) => {
    if (e.nativeEvent.key === 'Tab' && !e.nativeEvent.shiftKey && index === this.props.entries.length-1) {
      this.addTimesheetEntry()
    }
  }

  renderLockedStatus = () => (
    <Avatar
      backgroundColor='#FF5722'
      size={45}
    >
      <FontAwesome title='locked' name='lock' fixedWidth style={{color: '#fff'}} />
    </Avatar>
  )

  handleProjectTaskChange = (index) => (option) => {
    this.actions.changeForm('projectTaskId', index, option.id)
    if (option?.projectTask?.attributes?.defaultComment) {
      this.actions.changeForm('comment', index, option.projectTask.attributes.defaultComment)
    }
  }

  renderTimesheetEntry = (entry, index) => (
    <div key={index} style={TimesheetEntryForm.styles.entry}>
      <div style={TimesheetEntryForm.styles.fields}>
        <TimeInput
          key={`time_${entry.id}`}
          increments={['', '.25', '.5', '.75']}
          floatingLabelText='Time'
          value={entry.attributes.time.toString()}
          onChange={option => this.actions.changeForm('time', index, option)}
          errorText={this.errorForField('time', index)}
          noMatchesError='is not a quarter hour'
          style={TimesheetEntryForm.styles.timeField}
          disabled={this.props.disabled}
        />

        <ProjectTaskAutoComplete
          key={`task_${entry.id}`}
          floatingLabelText='Task'
          value={entry}
          onChange={this.handleProjectTaskChange(index)}
          style={TimesheetEntryForm.styles.taskField}
          disabled={this.props.disabled}
          includeClosedTasks={this.state.includeClosedTasks}
          recentTimesheetEntries={this.props.recentTimesheetEntries}
        />

        <TextField
          floatingLabelText='Comment'
          hintText='Comment'
          value={entry.attributes.comment}
          onChange={e => this.actions.changeForm('comment', index, e.target.value)}
          errorText={this.errorForField('comment', index)}
          style={TimesheetEntryForm.styles.commentField}
          disabled={this.props.disabled}
          onKeyDown={e => this.onLastFieldKeyDown(e, index)}
        />
      </div>

      {!this.props.disabled &&
        <div style={TimesheetEntryForm.styles.actions}>
          <FlatButton
            backgroundColor='#3F51B5'
            hoverColor='#7986CB'
            icon={<FontAwesome title='delete' name='trash-o' fixedWidth style={{color: '#fff'}} />}
            onClick={() => this.deleteTimesheetEntry(entry, index) }
            style={{minWidth: 60}}
            tabIndex={-1}
          />
        </div>
      }
    </div>
  )

  render = () => {
    const { entries, selectedDate, requestingTimesheetEntries, pendingTimesheetEntriesLoad, disabled, weekTime } = this.props

    return (
      <div>
        <Card style={{padding: 10}}>
          <CardHeader
            title={selectedDate.format('LL') + ((requestingTimesheetEntries || pendingTimesheetEntriesLoad) ? ' Loading..' : '')}
            titleStyle={{fontSize: 18, color: 'rgba(0, 0, 0, 0.6)'}}
            subtitle={`${this.totalTime} hours, ${this.totalTime + weekTime} this week ${disabled ? ', Approved' : ''}`}
            subtitleStyle={{paddingTop: 5, fontSize: 16}}
            avatar={disabled ? this.renderLockedStatus() : undefined}
          >
            <RaisedButton style={{float: 'right'}} label={this.taskSearchStatusText} onClick={() => this.setState({includeClosedTasks: !this.state.includeClosedTasks})} />
          </CardHeader>

          <CardText>
            {this.renderSubmissionErrors()}
            {entries.map(this.renderTimesheetEntry)}

          </CardText>

          {!disabled &&
            <CardActions>
              <FloatingActionButton mini style={{right: 100}} onClick={this.addTimesheetEntry}>
                <ContentAdd />
              </FloatingActionButton>
            </CardActions>
          }

        </Card>

        {!disabled &&
          <div style={{float: 'right', paddingTop: 20}}>
            <RaisedButton label='Save' primary onClick={this.submit} disabled={!this.isFormDirty} />
          </div>
        }
      </div>
    )
  }
}


export default connect(state => ({
  ...state.session,
  ...state.timesheetEntry.form,
  pendingTimesheetEntriesLoad: state.timesheetEntry.pendingTimesheetEntriesLoad,
  recentTimesheetEntries: state.timesheetEntry.recentTimesheetEntries,
  projectTasks: state.projectTasks.projectTasks,
}))(TimesheetEntryForm)

