class ProjectAPI{
  static create = attributes => authenticated => authenticated.post('api/projects', {
    data: {
      type: 'projects',
      attributes
    }
  })

  static update = (id, attributes) => authenticated => authenticated.put(`api/projects/${id}`, {
    data: {
      type: 'projects',
      attributes
    }
  })

  static search = (attributes) => authenticated => authenticated.post('api/projects/search', {
    data: {
      type: 'projects',
      attributes
    }
  })

  static index = () => authenticated => authenticated.get('api/projects')
  static show = id => authenticated => authenticated.get(`api/projects/${id}`)
}

export default ProjectAPI
