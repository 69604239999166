import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import RaisedButton from 'material-ui/RaisedButton'
import Paper from 'material-ui/Paper'
import Motorcycle from 'material-ui/svg-icons/action/motorcycle'
import Autorenew from 'material-ui/svg-icons/action/autorenew'
import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui/Toolbar'
import { Card, CardHeader } from 'material-ui/Card'
import { OAuthActions } from '../../actionsets'
import { titleize } from '../../utils'
import jiraLogo from '../../images/jira.png'

const MAX_FILE_UPLOAD_SIZE_MB = 10

export class OAuth extends Component {

  static propTypes = {
    dispatch: PropTypes.func,
    errors: PropTypes.object,
    services: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static styles = {
    paper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    toolbar: {
      minHeight: 50
    },
    serviceList: {
      flex: 1,
      padding: 10,
    },
    serviceHeader: {
      textStyle: {
        paddingRight: 0
      }
    }
  }

  constructor(props){
    super(props)
    OAuthActions.bindActions(this)
  }

  componentDidMount = () => {
    this.refreshServices()
  }

  refreshServices = async () => {
    const { location: { query: { oauth_verifier: oauthVerifier, service } } } = this.props

    await this.actions.getServices()

    if (service && oauthVerifier) {
      await this.actions.verifyService(service, oauthVerifier)
    }
  }

  handleAuthenticateService = serviceName => () => this.actions.authenticateService(serviceName)
  handleDeactivateService = serviceName => () => this.actions.deactivateService(serviceName)
  handleSyncService = serviceName => () => this.actions.syncService(serviceName)
  handleImportService = serviceName => () => {
    const file = this[`import-${serviceName}`].files[0]

    if(file.size > MAX_FILE_UPLOAD_SIZE_MB * 1024 * 1024){
      this.actions.alert(`File size should not exceed ${MAX_FILE_UPLOAD_SIZE_MB} MB`)
      return
    }

    const formData = new FormData()
    formData.append('service', serviceName)
    formData.append('file', file, file.filename)
    this.actions.importService(serviceName, formData)
  }

  renderServiceButtons = ({ active, requestPending, name }) => {
    if (requestPending) return <i className='fa fa-cog fa-2x fa-spin' />
    if (active) return [
      <RaisedButton
        key={`import-${name}`}
        label='Import XML'
        containerElement='label'
        backgroundColor='#F2F2F2'
      >
        <input ref={el => this[`import-${name}`] = el} type='file' accept='.xml' onChange={ this.handleImportService(name) } style={{ display: 'none' }} />
      </RaisedButton>,
      <RaisedButton
        key={`sync-${name}`}
        default
        label='Sync'
        icon={<Autorenew />}
        onClick={ this.handleSyncService(name) }
      />,
      <RaisedButton
        key={`deactivate-${name}`}
        secondary
        label='Deactivate'
        onClick={ this.handleDeactivateService(name) }
      />
    ]
    if (!active) return (
      <RaisedButton
        key={`activate-${name}`}
        primary
        label='Activate'
        onClick={ this.handleAuthenticateService(name) }
      />
    )
  }

  renderService = service => (
    <Card key={`service-${service.name}`}>
      <CardHeader
        title={titleize(service.name)}
        subtitle={service.active ? 'Active' : 'Inactive'}
        avatar={service.name === 'jira' ? jiraLogo : <Motorcycle />}
        textStyle={OAuth.styles.serviceHeader.textStyle}
      >
        <span className="pull-right">{ this.renderServiceButtons(service) }</span>
      </CardHeader>
    </Card>

  )

  formatServices = services => (
    Object.keys(services).map(service => ({
      name: service,
      ...services[service].attributes
    }))
  )

  render = () => (
    <Paper style={OAuth.styles.paper}>
      <Toolbar style={OAuth.styles.toolbar}>
        <ToolbarGroup>
          <ToolbarTitle text='Linked Services' />
        </ToolbarGroup>
      </Toolbar>
      <Card style={OAuth.styles.serviceList} >
        {this.formatServices(this.props.services).map(this.renderService)}
      </Card>
    </Paper>
  )
}

export const mapStateToProps = ({ oauth, router: { location } }) => ({ ...oauth, location })

export default connect(mapStateToProps)(OAuth)
