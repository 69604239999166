import React, { Component,  } from 'react'
import PropTypes from 'prop-types';
import FAButton from '../FAButton'
import Chip from 'material-ui/Chip';

const styles = {
  wrapper: {
    fontWeight: '500',
    boxSizing: 'border-box',
    position: 'relative',
    whiteSpace: 'no-wrap',
    textOverflow: 'ellipsis',
    padding: '16px',
    cursor: 'pointer',
    display: 'flex'
  },
  chip: {
    float: 'left',
    height: 32,
    width: 32
  }
}

export class DangerousCardHeader extends Component {

  static propTypes = {
    title: PropTypes.string,
    style: PropTypes.object,
    toggleFullScreen: PropTypes.func,
    fullScreenable: PropTypes.bool,
    fullScreen: PropTypes.bool,
    chipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    chipColor: PropTypes.string,
    headerBackgroundColor: PropTypes.string
  }

  static defaultProps = {
    style: {},
    fullScreenable: false,
    fullScreen: false
  }

  get style(){
    return {
      ...styles.wrapper,
      ...this.props.style,
      ...(this.props.headerBackgroundColor ? { backgroundColor: this.props.headerBackgroundColor} : {})
    }
  }
  render = () =>
    <div
      className='card-header'
      style={this.style}>
      {this.props.chipText &&
        <Chip style={styles.chip} backgroundColor={this.props.chipColor}>
          {this.props.chipText}
        </Chip>
      }
      <span dangerouslySetInnerHTML={{__html: this.props.title }} style={{ color: '#FFFFFF', fontSize: '20px', marginTop: 'auto', marginBottom: 'auto', marginLeft: 10}}/>
      <div style={{flex: 1, pointerEvents: 'none'}}/>

      { this.props.fullScreenable && <FAButton
          style={{flex: '0 1', marginTop: 'auto', marginBottom: 'auto'}}
          tooltip={this.props.fullScreen ? 'compress' : 'expand'}
          name={this.props.fullScreen ? 'compress' : 'expand'}
          onClick={this.props.toggleFullScreen}
          tooltipPosition='bottom-center'
        />
      }

    </div>
}

export default DangerousCardHeader
