import React, {  } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { push } from 'connected-react-router'
import DatePicker from 'material-ui/DatePicker'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import CRUDForm from '../../containers/CRUDForm'
import { ModelStatus } from '../../constants'
import { ProjectActions } from '../../actionsets'
import { roleCheck, randomColor, titleize } from '../../utils'
import { ColorPicker, TimeInput } from '../../components'

export class ProjectForm extends CRUDForm{

  constructor(props){
    super(props)
    this.state = {
      errors: {},
      submissionErrors: [],
      customerId: '',
      name: '',
      code: '',
      source: '',
      description: '',
      url: '',
      startDate: null,
      endDate: null,
      status: ModelStatus.default,
      customerStatus: '',
      combinedStatus: '',
      color: this.createMode ? randomColor() : undefined
    }

    ProjectActions.bindActions(this)
  }

  static propTypes = {
    dispatch: PropTypes.func,
    onSubmitted: PropTypes.func,
    onCancel: PropTypes.func,
    customers: PropTypes.array,
    externalProjectSource: PropTypes.element,
  }

  static styles = {
    form: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1
    },
    leftField: {
      flex: 1,
      marginRight: 10
    },
    rightField: {
      flex: 1,
      marginLeft: 10
    },
    actions: {
      float: 'right'
    },
    colorField: {
      height: 72,
      cursor: 'auto',
      display: 'flex'
    },
    label: {
      color: 'rgba(0, 0, 0, 0.3)'
    }
  }

  static defaultProps = {
    customers: []
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.selectedProject !== this.props.selectedProject) {
      this.setState({ ...nextProps.selectedProject })
    }
  }

  loadEditItem = async () => {
    if(this.userId !== this.editId && !roleCheck(this.props.role, 'admin')){
      this.props.dispatch(push('/'))
      return
    }
    const attributes = this.props.project.attributes
    this.setState(attributes)
    this.setState({
      url: attributes.url || '',
      color: attributes.color || randomColor(),
      customerId: String(attributes.customerId)
    })
  }

  submit = async () => {
    if(!this.validate()) return

    const { name, code, source, description, url, customerId, startDate, endDate, status, color, monthlyHoursCap } = {...this.state }
    const params = { name, code, source, description, url, customerId, startDate, endDate, status, color, monthlyHoursCap }

    let action
    if(this.editMode){
      action = this.actions.updateProject(this.editId, params)
    } else{
      action = this.actions.createProject(params)
    }

    try{
      await action
      this.props.onSubmitted && this.props.onSubmitted()
    } catch(err){
      const { body: { errors: submissionErrors }} = err
      this.setState({ submissionErrors })
    }
  }

  validate = () => {
    let requiredFields = ['name', 'code', 'description', 'customerId', 'status']
    const errors = this.requireFields(requiredFields)
    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  get userId(){
    return this.props.user.id
  }

  get editId(){
    return this.props.project && this.props.project.id
  }

  get derivedStatusError() {
    return this.state.customerStatus === ModelStatus.archived ? 'The customer has been archived' : ''
  }

  onColorChange = color => this.setState({color: color.rgb})

  renderFormFields = () => (
    <div style={ProjectForm.styles.form}>
      {this.renderSubmissionErrors()}
      <div style={ProjectForm.styles.row}>
        <TextField
          style={ProjectForm.styles.leftField}
          floatingLabelText="Name"
          hintText="Name"
          value={this.state.name}
          onChange={this.onFormValueChange('name', this.nthValue(1))}
          errorText={this.state.errors.name}
        />
        { this.props.externalProjectSource }
        <TextField
          style={ProjectForm.styles.rightField}
          floatingLabelText="Code"
          hintText="Code"
          value={this.state.code}
          onChange={this.onFormValueChange('code', this.nthValue(1))}
          errorText={this.state.errors.code}
        />
      </div>
      <div style={ProjectForm.styles.row}>
        <DatePicker
          style={ProjectForm.styles.leftField}
          textFieldStyle={{width: '100%'}}
          floatingLabelText="Start Date"
          hintText="Start Date"
          defaultDate={new Date()}
          value={this.state.startDate && new Date(this.state.startDate)}
          errorText={this.state.errors.startDate}
          onChange={this.onFormDateChange('startDate', this.nthValue(1))}
          autoOk
        />
        <DatePicker
          style={ProjectForm.styles.rightField}
          textFieldStyle={{width: '100%'}}
          floatingLabelText="End Date"
          hintText="End Date"
          defaultDate={new Date()}
          value={this.state.endDate && new Date(this.state.endDate)}
          errorText={this.state.errors.endDate}
          onChange={this.onFormDateChange('endDate', this.nthValue(1))}
          autoOk
        />
      </div>
      <div style={ProjectForm.styles.row}>
        <TextField
          style={ProjectForm.styles.leftField}
          floatingLabelText="Description"
          hintText="Description"
          value={this.state.description}
          onChange={this.onFormValueChange('description', this.nthValue(1))}
          errorText={this.state.errors.description}
        />
        <TextField
          style={ProjectForm.styles.rightField}
          floatingLabelText="URL"
          hintText="URL"
          value={this.state.url}
          onChange={this.onFormValueChange('url', this.nthValue(1))}
          errorText={this.state.errors.url}
        />
      </div>
      <div style={ProjectForm.styles.row}>
        <SelectField
          style={ProjectForm.styles.leftField}
          floatingLabelText="Customer"
          value={this.state.customerId}
          onChange={this.onFormValueChange('customerId', this.nthValue(2))}
          errorText={this.state.errors.customerId}
        >
          <MenuItem value='' primaryText="" style={{minHeight: '32px'}} />
          { this.props.customers.map(customer => <MenuItem key={'customer-' + customer.id} value={customer.id} primaryText={customer.attributes.businessName} style={{minHeight: '32px'}} />) }
        </SelectField>
        <SelectField
          style={ProjectForm.styles.rightField}
          floatingLabelText="Status"
          value={this.state.status}
          onChange={this.onFormValueChange('status', this.nthValue(2))}
          errorText={this.state.errors.status || this.derivedStatusError}
          disabled={!!this.derivedStatusError}
        >
          <MenuItem value={ModelStatus.active} primaryText='Active' />
          <MenuItem value={ModelStatus.archived} primaryText='Archived' />
        </SelectField>
      </div>
      <div style={ProjectForm.styles.row}>
        <div style={{...ProjectForm.styles.colorField, ...ProjectForm.styles.leftField}}>
          <div style={{marginTop: 36, width: 50}}>
            <label style={ProjectForm.styles.label}>Color</label>
          </div>
          <div style={{marginTop: 20, flexGrow: 1}}>
            <ColorPicker
              style={{width: 200, height: 30, float: 'right'}}
              color={this.state.color}
              onChange={this.onColorChange}
              popoverPosition={{top: 75, left: 160}}
              label={this.state.code}
              labelStyle={{color: '#fff'}}
            />
          </div>
        </div>
        <SelectField
          style={ProjectForm.styles.rightField}
          floatingLabelText="Source"
          value={this.state.source}
          onChange={this.onFormValueChange('source', this.nthValue(2))}
          errorText={this.state.errors.source}
        >
          <MenuItem value={null} primaryText={""} />
          {Object.keys({ ...this.props?.oauth?.services}).map(serviceName =>
            <MenuItem key={serviceName} value={serviceName} primaryText={titleize(serviceName)} />
          )}
        </SelectField>
      </div>
      <div style={ProjectForm.styles.row}>
        <TimeInput
          increments={['']}
          floatingLabelText='Monthly hours cap'
          value={this.state.monthlyHoursCap ? `${this.state.monthlyHoursCap}` : ''}
          onChange={this.onFormValueChange('monthlyHoursCap', this.nthValue(0))}
          errorText={this.state.errors.monthlyHoursCap}
          noMatchesError='is not a quarter hour'
          style={ProjectForm.styles.leftField}
          disabled={false}
        />
        <div style={ProjectForm.styles.rightField}/>
      </div>
    </div>
  )

  render = () => (
    <div>
      {this.renderFormFields()}
      <div style={ProjectForm.styles.actions}>
        <FlatButton label="Cancel" style={{marginRight: 10}} onClick={this.props.onCancel} />
        <RaisedButton label='Save' primary={true} onClick={this.submit} />
      </div>
    </div>
  )
}

export default connect(state => { return {...state.session, oauth: state.oauth } })(ProjectForm)
