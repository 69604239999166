import React, { Component } from 'react'
import { Switch, Redirect, withRouter } from 'react-router-dom'
import { Route } from '.'
import * as Containers from '.'
import { isManagerOrAdmin } from "../utils"

export class Routes extends Component{

  render = () =>
      <Switch>
        <Route exact path="/sessions/sign_in" component={Containers.Login}/>
        <Route exact path="/sessions/forgot_password" component={Containers.ForgotPassword}/>
        <Route exact path="/sessions/reset_password" component={Containers.ResetPassword}/>
        <Route exact path="/sessions/invitation" component={Containers.SetPassword}/>
        <Route exact path="/home" component={Containers.Home}/>
        <Route exact path="/users" restrictRole={'admin'} component={Containers.UsersWrapper}/>
        <Route exact path="/users/edit/:editId" restrictRole={'admin'} component={Containers.UserFormWrapper}/>
        <Route exact path="/customers" restrictRole={'admin'} component={Containers.CustomersWrapper}/>
        <Route exact path="/projects" restrictRole={'admin'} component={Containers.Projects}/>
        <Route exact path="/projects/edit/:editId" restrictRole={'admin'} component={Containers.ProjectManagementWrapper}/>
        <Route exact path="/time" restrictRole={'user'} component={Containers.TimesheetEntryWrapper}/>
        <Route exact path="/time/report" restrictRole={'user'} render={() => <Containers.TimesheetsWrapper mode={'reporting'} />}/>
        <Route exact path="/time/approve" restrictRole={'user'}  render={() => <Containers.TimesheetsWrapper permit={p => isManagerOrAdmin(p.user)} mode={'approval'} />}/>
        <Route exact path="/time/invoice" restrictRole={'admin'} component={Containers.TimesheetInvoicing}/>
        <Route exact path="/oauth/services" component={Containers.OAuth} />
        <Route exact path="/oauth/services/verify" component={Containers.OAuth} />
        <Route component={() => <Redirect to={"/time"}/>} />
      </Switch>
}

export default withRouter(Routes)