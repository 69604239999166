import React from 'react'
import MuiDialog from 'material-ui/Dialog'
import { Provider } from "react-redux";
import store from "../store";

export default function Dialog(props) {
  const {children, ...restOfProps} = props
  return (
    <MuiDialog {...restOfProps}>
      {/*
        We wrap Dialog content with the store Provider since react-redux needs
        store to be in context in order for connect() to work correctly,
        but material-ui Dialog renders Dialogs outside of the main component tree.
      */}
      <Provider store={store}>
        {children}
      </Provider>
    </MuiDialog>
  )
}