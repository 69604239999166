import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { TimerControl } from '../../../components'
import { TimerForm } from '.'
import { TimerActions } from '../../../actionsets'
import { TimerStatus } from '../../../constants'

export class TaskTimer extends Component {

  static propTypes = {
    dispatch: PropTypes.func,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired,
  }

  constructor(props){
    super(props)
    TimerActions.bindActions(this)
  }

  componentWillMount(){
    this.actions.loadTimer()
  }

  handleChange = status => {
    if(status === TimerStatus.started)
      this.actions.startTimer()
    else if(status === TimerStatus.stopped)
      this.actions.stopTimer()
    else if(status === TimerStatus.cleared)
      this.actions.clearTimer()
  }

  onSubmitted = () => {
    this.actions.clearTimer()
    this.props.dispatch(push('/time'))
  }

  render = () => {
    const { open, status, time, onClose } = this.props

    return (
      <div style={{display: open ? 'flex' : 'none', backgroundColor: '#ccc'}}>

        <div style={{flex: 0, backgroundColor: 'grey', display: 'inline-block', paddingTop: 30, paddingBottom: 30}}>

          <TimerControl
            status={status}
            time={time}
            onChange={this.handleChange}
            style={{width: 120}}
            iconStyle={{width: 48, height: 48}}
          />

        </div>

        <div style={{flex: 1, paddingLeft: 10}}>

          <TimerForm
            time={time}
            onSubmitted={this.onSubmitted}
            onCancel={onClose}
          />

        </div>
      </div>
    )
  }
}

export default connect(state => ({...state.timer}))(TaskTimer)
